import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Button, Divider, Modal, Row, Space, Typography, Form, Input, Col, Select, DatePicker, message } from 'antd'
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input'
import en from 'world_countries_lists/data/countries/en/world.json'
import { EditOutlined } from '@ant-design/icons'
import AuthActions from '@/redux/auth'

const BasicInfo = ({ data = null, updateBasicInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdaing, setIsUpdating] = useState(false)
  const [phoneErr, setPhoneErr] = useState('')

  const { Option } = Select

  const onSubmit = async (values) => {
    if (phoneErr) return

    try {
      setIsUpdating(true)
      await AuthActions.updateProfile(
        {
          ...data,
          ...values,
          phone_short: values.phone.short,
          phone_code: values.phone.short,
          phone_number: values.phone.phone,
          // birthday: moment(values.birthday).format('YYYY-MM-DD')
          birthday: values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : null
        },
        data.id
      )
      updateBasicInfo(values)
      setIsUpdating(false)
      setIsModalOpen(false)
      message.success('Saved successfully.')
    } catch (e) {
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  const getFlag = (short) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`)
    // for dumi
    if (typeof data === 'string') {
      return data
    }
    // for CRA
    return data.default
  }

  return (
    <ConfigProvider
      locale={en}
      areaMapper={(area) => {
        return {
          ...area,
          emoji: <img alt="flag" style={{ width: 18, height: 18, verticalAlign: 'sub' }} src={getFlag(area.short)} />
        }
      }}
    >
      <div style={{ width: '100%' }}>
        <Row justify="space-between" align={'middle'}>
          <Space direction="vertical" size={0} style={{ width: '100%' }}>
            <Space
              direction="horizantal"
              size={0}
              align="center"
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Space wrap={true} style={{ marginRight: 'auto' }}>
                <Typography.Title level={4} style={{ marginBottom: 0 }}>
                  {data?.first_name} {data?.last_name}
                </Typography.Title>
              </Space>
              <Space>
                <Button
                  icon={<EditOutlined style={{ fontSize: 30 }} />}
                  type="text"
                  onClick={() => setIsModalOpen(true)}
                />
              </Space>
            </Space>
            <Typography.Text>{data?.email}</Typography.Text>
          </Space>
        </Row>
        <Divider />

        <Modal title="Edit Info" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={700}>
          <Form
            name="basicInfoForm"
            layout="vertical"
            onFinish={onSubmit}
            style={{ padding: 15 }}
            initialValues={{
              ...data,
              birthday: data.birthday ? moment(data.birthday) : '',
              phone: { short: data.phone_code, code: data.phone_code, phone: data.phone_number }
            }}
          >
            <Row gutter={20}>
              <Col span={24} lg={12}>
                <Form.Item
                  name="first_name"
                  label={'First Name'}
                  required={false}
                  rules={[{ required: true, message: 'First name is required' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} lg={12}>
                <Form.Item
                  name="last_name"
                  label={'Last Name'}
                  required={false}
                  rules={[{ required: true, message: 'Last name is required' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="email"
              label={'Email Address'}
              required={false}
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Invalid email' }
              ]}
            >
              <Input disabled readOnly />
            </Form.Item>

            <Row>
              <Col span={24} lg={16}>
                <Form.Item
                  name="phone"
                  label={`Phone${phoneErr || ''}`}
                  rules={[{ required: !!phoneErr }]}
                  className={phoneErr ? 'ant-input-status-error' : ''}
                >
                  <CountryPhoneInput
                    inline
                    onChange={(phone) => {
                      const errMsg = phone?.phone && !phone?.code ? ' code is required' : ''
                      if (phoneErr !== errMsg) setPhoneErr(errMsg)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item name="address1" label={'Address Line 1'}>
              <Input />
            </Form.Item> */}

            {/* <Form.Item name="address2" label={'Address Line 2'}>
              <Input />
            </Form.Item> */}

            <Row gutter={40}>
              <Col span={24} lg={10}>
                <Form.Item name="country" label={'Country'}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={en.map((c) => ({ value: c.alpha2, label: c.name }))}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24} lg={10}>
                <Form.Item name="state" label={'State'}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={40}>
              <Col span={24} lg={10}>
                <Form.Item name="city" label={'City'}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24} lg={10}>
                <Form.Item name="zip_code" label={'Pin Code'}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={40}>
              <Col span={24} lg={10}>
                <Form.Item name="birthday" label={'Date of Birth'}>
                  <DatePicker style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>

            <Divider>Demografic Info</Divider>
            <Row gutter={20}>
              <Col span={24} lg={12}>
                <Form.Item name="gender" label={'Gender'}>
                  <Select placeholder="Select Gender">
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="decline">Decline to Self Identify</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} lg={12}>
                <Form.Item name="lgbtq" label={'LGBTQ+'}>
                  <Select placeholder="Select LGBTQ+ status">
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                    <Option value="decline">Decline to self-identify</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={24} lg={12}>
                <Form.Item name="disability" label={'Disability'}>
                  <Select placeholder="Select Disability status">
                    <Option value="Yes">Yes, I have a disability</Option>
                    <Option value="No">No, I do not have a disability</Option>
                    <Option value="decline">Decline to self-identify</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} lg={12}>
                <Form.Item name="veteran" label={'Veteran'}>
                  <Select placeholder="Select Veteran status">
                    <Option value="Yes">I am a Veteran</Option>
                    <Option value="No">I am not a Veteran</Option>
                    <Option value="decline">Decline to self-identify</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="race" label={'Race/Ethnicity'}>
              <Select placeholder="Select Veteran status">
                <Option value="1">Alaskan Native / American Indian / Indigenous American / Native American</Option>
                <Option value="2">Black / Of African descent</Option>
                <Option value="3">
                  East Asian (inclusive of Chinese, Japanese, Korean, Mongolian, Tibetan, and Taiwanese)
                </Option>
                <Option value="4">Latinx</Option>
                <Option value="5">Middle Eastern</Option>
                <Option value="6">Pacific Islander</Option>
                <Option value="7">
                  South Asian (inclusive of Bangladeshi, Bhutanese, Indian, Nepali, Pakistani, and Sri Lankan)
                </Option>
                <Option value="8">
                  Southeast Asian (inclusive of Burmese, Cambodian, Filipino, Hmong, Indonesian, Laotian, Malaysian,
                  Mien, Singaporean, Thai, and Vietnamese)
                </Option>
                <Option value="9">White</Option>
                <Option value="10">I prefer to self-describe</Option>
                <Option value="11">I don't wish to answer</Option>
              </Select>
            </Form.Item>

            <Row justify="space-between" style={{ marginTop: 30 }} gutter={40}>
              <Col span={12}>
                <Button htmlType="submit" type="primary" block loading={isUpdaing}>
                  Save
                </Button>
              </Col>
              <Col span={12}>
                <Button htmlType="button" type="ghost" block onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </ConfigProvider>
  )
}

export default BasicInfo
