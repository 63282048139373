import React from 'react'
import './styles/toast.less'

const Toast = ({ text, isOn = false }) => {
  return (
    <div
      className="toast-container"
      style={isOn ? { transform: 'translateY(100%)' } : { transform: 'translateY(-100%)' }}
    >
      <div className="toast">{text}</div>
    </div>
  )
}
export default Toast
