const SET_TITLE = 'SET_TITLE'
const SET_DATA = 'SET_DATA'

const initialState = {
  id: null,
  title: null,
  story: null
}
const storyReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_TITLE:
      return {
        ...state,
        id: payload.id,
        title: payload.title
      }
    case SET_DATA:
      return {
        ...state,
        id: payload.StoryID,
        title: payload.title,
        story: payload.story,
        prompt: payload.prompt
      }
    default:
      return state
  }
}

export default storyReducer
