import React from 'react'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { Redirect } from 'react-router-dom'

// Layouts
import { AppLayout, BasicLayout } from '@/layouts'

// Guards
import GuestGuard from '@/component/GuestGuard'
import AuthGuard from '@/component/AuthGuard'

// Views
import Login from '@/pages/auth/login'
import Home from '@/pages/home'
import Signup from '@/pages/auth/signup'
import Profile from '@/pages/profile'
import Prep from '@/pages/prep'
import Questions from '@/pages/questions'
import InterviewType from '@/pages/interview-type'
import { partnersUrl } from '@/constants'
import Service from '@/pages/home/Service'
import PartnersPage from '@/pages/partners'
import Story from '@/pages/story/index'
import Stories from '@/pages/story/Screens/Stories'
import Write from '@/pages/story/Screens/Write'
import ViewStories from '@/pages/story/Screens/ViewStories'
import Catalog from '@/pages/story/Screens/Catalog'
import Slider from '@/pages/story/Screens/Slider'

export default () => {
  return [
    // {
    //   path: '/home',
    //   exact: true,
    //   component: Home
    // },
    {
      path: '/login',
      guard: GuestGuard,
      exact: true,
      layout: BasicLayout,
      component: Login
    },
    {
      path: '/signup',
      guard: GuestGuard,
      exact: true,
      layout: BasicLayout,
      component: Signup
    },
    {
      path: '/details',
      exact: true,
      guard: AuthGuard,
      component: Slider
    },
    // {
    //   path: '/profile',
    //   guard: AuthGuard,
    //   exact: true,
    //   layout: AppLayout,
    //   component: Profile
    // },
    // {
    //   path: '/interview-type',
    //   guard: AuthGuard,
    //   exact: true,
    //   layout: AppLayout,
    //   component: InterviewType
    // },
    // {
    //   path: '/prep',
    //   guard: AuthGuard,
    //   exact: true,
    //   layout: AppLayout,
    //   component: Prep
    // },
    // {
    //   path: '/questions',
    //   guard: AuthGuard,
    //   exact: true,
    //   layout: AppLayout,
    //   component: Questions
    // },
    // {
    //   path: '/linkedin',
    //   exact: true,
    //   layout: BasicLayout,
    //   component: LinkedInCallback
    // },
    {
      path: '/story',
      exact: true,
      component: Story
    },
    {
      path: '/write',
      exact: true,
      guard: AuthGuard,
      component: Write
    },
    {
      path: '/library',
      exact: true,
      guard: AuthGuard,
      component: Stories
    },
    {
      path: '/catalog',
      exact: true,
      guard: AuthGuard,
      component: Catalog
    },
    {
      path: partnersUrl,
      exact: true,
      layout: BasicLayout,
      component: PartnersPage
    },
    {
      path: '/read/:id',
      exact: true,
      layout: BasicLayout,
      component: ViewStories
    },
    // {
    //   path: '/',
    //   exact: true,
    //   component: () => <Redirect to="/home" />
    // },
    {
      path: '/',
      component: () => <Redirect to="/story" />
    }
  ]
}
