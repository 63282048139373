import { TokenApi } from '@/utils/api'
const SET_TITLE = 'SET_TITLE'
const SET_DATA = 'SET_DATA'

export const fetchTitle = () => {
  return async (dispatch) => {
    try {
      const allTopics = await TokenApi.get('story-activity-essay-title/')

      const usedTopics = await TokenApi.get('story-activity-user-stories/')

      const usedTopicNames = usedTopics.map((topic) => topic.title)

      const unusedTopics = allTopics.filter((topic) => !usedTopicNames.includes(topic))

      if (unusedTopics.length === 0) {
        console.log('All topics have been used.')
        return null
      }

      const randomIndex = Math.floor(Math.random() * unusedTopics.length)
      const randomTopic = unusedTopics[randomIndex]

      const storyIdResponse = await TokenApi.post('story-activity-save-title/', { title: randomTopic })
      const storyId = storyIdResponse.StoryID

      //const getTips = await TokenApi.post('get-tips-for-story/', { title: randomTopic })

      // const getTips = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k']

      let getTips = { tips: [] };

      const data = {
        id: storyId,
        title: randomTopic,
        tips: getTips
      }

      const payload = {
        id: storyId,
        title: randomTopic
      }
      dispatch({
        type: SET_TITLE,
        payload: payload
      })

      return data
    } catch (err) {
      console.error(err)
      return null
    }
  }
}

export const fetchData = (storyId) => {
  return async (dispatch) => {
    try {

      console.log(" story session start ..")

      const response = await TokenApi.post('story-activity-session/', { story_id: storyId })
      const { StoryID, title, story } = response

      console.log("the session response : ", response)
      console.log("the title and story Id for this session is : ", title, " , ", StoryID)



      // tips will not be fetched from here 

      /*
      try {
        getTips = await TokenApi.post('get-tips-for-story/', { title: title })
        console.log("the tips are : ", getTips)
      } catch (err) {
        console.log("there was an error fetching tips : ", err)
        getTips = { tips: [] }
      }
      */

      // we will save the story and fetch tips

      // tips and prompt will be fetched from save story call ..
      let getTips = { tips: [] };
      let prompt = "";


      try {

        const response = await TokenApi.post('story-activity-save/', {
          story_id: StoryID,
          title,
          value: story,
        });

        console.log("the save story response is : ", response)
        if (response.story_exists == false) {
          // we have to set the prompt
          prompt = response.prompt;
          getTips.tips = response.tips;

        }
        else {
          console.log("story already exists ..")
        }


      } catch (err) {
        console.log("there was an error saving story : ", err)
        getTips = { tips: [] }
      }

      const data = {
        StoryID,
        title,
        story,
        prompt,
        tips: getTips
      }

      dispatch({
        type: SET_DATA,
        payload: { StoryID, title, story, prompt }
      })

      return data
    } catch (err) {
      console.log(err)
    }
  }
}
