import React, { useEffect, useState } from 'react'
import heroSectionImage from '../../../assets/icons/hero-section-image.svg'
import legendarycoachesImage from '../../../assets/icons/legendary-coaches.svg'
import SymbolTags from './SymbolTags'
import './styles/heroSection.less'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import { TokenApi } from '@/utils/api'
// import './index.less'

const HeroSection = () => {
  const [isNewUser, setIsNewUser] = useState(false)
  const auth = useSelector((state) => state.auth)

  const checkIsNew = async (email) => {
    const res = await TokenApi.get(`check/?user_id=${email}`)
    // console.log(res)
    setIsNewUser(!res?.exists)
  }

  useEffect(() => {
    if (auth.userInfo) {
      checkIsNew(auth.userInfo.data.profile.email)
    }
    // we have to check here is the user is first time logger or not by calling the api
    // and then change the state variable
  }, [auth])

  return (
    <div className="hero-section-container">
      <div className="left-container">
        <h1 style={{ fontWeight: 700, fontSize: '40px' }}> Transform Your Writing With Legendary Mentors</h1>
        <p> Want a writing coach? Join Writeary and learn from the masters of literature</p>
        <div>
          <SymbolTags symbol="🚀" text="Never run out of inspiration" />
          <SymbolTags symbol="👨‍💻" text="Immersive, gamified writing experience" />
          <SymbolTags symbol="💼" text="Learn from the best" />
        </div>

        <button>
          <Link to={isNewUser ? '/details' : '/library'}>Write a story</Link>
        </button>
      </div>

      <div className="right-container">
        <img src={legendarycoachesImage} alt="legendary-coaches image" />
      </div>
    </div>
  )
}

export default HeroSection
