import { AUTH } from '@/constants'

const initState = {
  is_authed: false,
  userInfo: null
}

const AuthReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case AUTH.SIGNED_IN:
      return {
        ...state,
        is_authed: true
      }

    case AUTH.SIGNED_OUT:
      return {
        ...initState
      }

    case AUTH.USER_INFO:
      return {
        ...state,
        userInfo: Object.assign({}, payload)
      }

    default:
      return state
  }
}

export default AuthReducer
