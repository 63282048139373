import React, { useState, useEffect } from 'react'

function Countdown({ onCountdownComplete }) {
  const [count, setCount] = useState(3)

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => {
        setCount(count - 1)
      }, 1000)

      //   return () => clearTimeout(timer)
    } else {
      onCountdownComplete()
    }
  }, [count, onCountdownComplete])

  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999
  }

  const circleStyles = {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '36px'
  }

  return (
    <div style={overlayStyles}>
      <div style={circleStyles}>
        <span style={{ fontSize: 150 }}>{count}</span>
      </div>
    </div>
  )
}

export default Countdown
