import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import LoadingScreen from '../LoadingScreen'
import AuthActions from '@/redux/auth'

function Auth({ children }) {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const initAuth = async () => {
      const accessToken = window.localStorage.getItem('accessToken')
      const refreshToken = window.localStorage.getItem('refreshToken')
      if (accessToken || refreshToken) {
        try {
          const res = await dispatch(AuthActions.getCurrentUser())
          setLoading(false)
        } catch (e) {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }

    initAuth()
  }, [dispatch])

  if (isLoading) {
    return <LoadingScreen />
  }

  return children
}

Auth.propTypes = {
  children: PropTypes.any
}

export default Auth
