import React, { useState } from 'react'
import moment from 'moment'
import AuthActions from '@/redux/auth'
import {
  Button,
  Divider,
  Modal,
  Row,
  Space,
  Typography,
  Form,
  Input,
  Col,
  message,
  DatePicker,
  Checkbox,
  Popconfirm,
  Select
} from 'antd'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

const WorkExperience = ({ data = [], updateBasicInfo, profile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCWHchecked, setIsCWHchecked] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [isUpdaing, setIsUpdating] = useState(false)
  const [selectedExp, setSelectedExp] = useState(false)
  const [form] = Form.useForm()
  const { Option } = Select

  const onSubmit = async (values) => {
    try {
      setIsUpdating(true)
      const res = await AuthActions.createOrUpdateWorkExperience(
        {
          ...values,
          start_date: moment(values.start_date).format('YYYY-MM-DD'),
          end_date: moment(values.end_date).format('YYYY-MM-DD'),
          profile: profile?.id,
          work_currently: isCWHchecked
        },
        values.id
      )
      updateBasicInfo([...data, res])
      setIsUpdating(false)
      setIsModalOpen(false)
      setSelectedExp(null)
      message.success('Saved successfully.')
      form.resetFields()
    } catch (e) {
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  const disabledDate = (current) => {
    if (startDate) return current && current <= startDate.endOf('day')

    return false
  }

  const onUpdate = async (values) => {
    try {
      setIsUpdating(true)
      const res = await AuthActions.createOrUpdateWorkExperience(
        {
          ...values,
          start_date: moment(values.start_date).format('YYYY-MM-DD'),
          end_date: moment(values.end_date).format('YYYY-MM-DD'),
          profile: profile?.id,
          work_currently: isCWHchecked
        },
        selectedExp.id
      )
      updateBasicInfo(
        data.map((d) => {
          if (d.id === selectedExp.id) return res
          else return d
        })
      )
      setIsUpdating(false)
      setIsModalOpen(false)
      setSelectedExp(null)
      message.success('Saved successfully.')
      form.resetFields()
    } catch (e) {
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  const editExperience = (d) => {
    form.setFieldsValue({ ...d, start_date: moment(d?.start_date), end_date: moment(d?.end_date) })
    setIsCWHchecked(d.work_currently)
    setSelectedExp(d)
  }

  const onDelete = async (id) => {
    try {
      await AuthActions.deleteWorkExperience(id)
      updateBasicInfo(data.filter((d) => d.id !== id))
    } catch (e) {}
  }

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" align={'middle'}>
        <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
          💼 Experience
        </Typography.Title>
        <Space>
          <Button
            icon={<PlusCircleOutlined style={{ fontSize: 30 }} />}
            type="text"
            onClick={() => setIsModalOpen(true)}
          />
        </Space>
      </Row>
      {data?.length === 0 && (
        <Row justify="space-between" align={'middle'}>
          <Typography.Text type="secondary">No experience provided</Typography.Text>
        </Row>
      )}
      <Space direction="vertical" style={{ width: '100%' }}>
        {data.map((d, key) => (
          <Row justify="space-between" align={'middle'} key={key}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <Row direction="horizantal" justify="space-between" size={0} align="middle">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {d?.company_name}
                </Typography.Title>
                <Space>
                  <Button
                    icon={<EditOutlined style={{ fontSize: 30 }} />}
                    type="text"
                    onClick={() => editExperience(d)}
                  />
                  <Popconfirm
                    title="Are you sure to delete this experience?"
                    onConfirm={() => onDelete(d.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ style: { height: 35 } }}
                    cancelButtonProps={{ style: { height: 35 } }}
                  >
                    <Button icon={<DeleteOutlined style={{ fontSize: 30 }} />} type="text" />
                  </Popconfirm>
                </Space>
              </Row>
              <Typography.Text type="secondary">{d?.designation}</Typography.Text>
              <Typography.Text type="secondary">
                {moment(d.start_date).year()} - {d.work_currently ? 'Present' : moment(d.end_date).year()}
              </Typography.Text>
            </Space>
          </Row>
        ))}
      </Space>

      <Divider />

      <Modal
        title={selectedExp ? 'Edit Work Experience' : 'Add Work Experience'}
        open={isModalOpen || selectedExp}
        onCancel={() => {
          setIsModalOpen(false)
          setSelectedExp(null)
          form.resetFields()
        }}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          name="experienceForm"
          layout="vertical"
          onFinish={selectedExp ? onUpdate : onSubmit}
          style={{ padding: 15 }}
        >
          <Form.Item
            name="company_name"
            label={'Company Name'}
            required={false}
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="website_url" label={'Company Website (optional)'}>
            <Input />
          </Form.Item>

          <Row gutter={20}>
            <Col span={24} lg={9}>
              <Form.Item
                name="designation"
                label={'Designation'}
                required={false}
                rules={[{ required: true, message: 'Designation is required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} lg={9}>
              <Form.Item name="type" label={'Type'} required={false}>
                <Select placeholder="Job Type">
                  <Option value="full">Full time</Option>
                  <Option value="part">Part time</Option>
                  <Option value="intern">Internship</Option>
                  <Option value="contract">Contract</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24} lg={9}>
              <Form.Item name="start_date" label={'Start Date'}>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(d) => {
                    setStartDate(d)
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24} lg={9}>
              <Form.Item name="end_date" label={'End Date'}>
                <DatePicker style={{ width: '100%' }} disabled={isCWHchecked} disabledDate={disabledDate} />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginLeft: 20, marginBottom: 20 }}>
            <Checkbox
              checked={isCWHchecked}
              onChange={(e) => {
                setIsCWHchecked(e.target.checked)
              }}
            >
              I currently work here
            </Checkbox>
          </Row>

          <Form.Item name="location" label={'Location'}>
            <Input.TextArea rows={2} />
          </Form.Item>

          <Form.Item name="description" label={'Description'}>
            <Input.TextArea rows={5} />
          </Form.Item>

          <Row justify="space-between" style={{ marginTop: 30 }} gutter={40}>
            <Col span={12}>
              <Button htmlType="submit" type="primary" block loading={isUpdaing}>
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button htmlType="button" type="ghost" block onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default WorkExperience
