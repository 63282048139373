import React from 'react'
import { Layout, Row, Typography } from 'antd'
import yale from '../../assets/images/yale.png'
import boston from '../../assets/images/boston.svg'
import google from '../../assets/images/google.svg'
import mckency from '../../assets/images/mckency.svg'
import wallmart from '../../assets/images/wallmart.svg'
import twitter from '../../assets/images/twitter.svg'
import harvard from '../../assets/images/harvard.svg'

const BrandsLogo = [
  {
    img: yale,
    name: 'yale'
  },
  {
    img: boston,
    name: 'boston'
  },
  {
    img: google,
    name: 'google'
  },
  {
    img: mckency,
    name: 'mckency'
  },
  {
    img: wallmart,
    name: 'wallmart'
  },
  {
    img: twitter,
    name: 'twitter'
  },
  {
    img: harvard,
    name: 'harvard'
  }
]

const Brands = () => {
  return (
    <>
      <Layout.Content>
        <div className="brands-container">
          <Typography.Title level={2} className="md-heading brand-heading">
            Built by experts from leading universities & companies
          </Typography.Title>
          <Row className="brands-logos-container">
            {BrandsLogo.map((data, i) => {
              return (
                <div className="logo-wrapper" key={i}>
                  <img src={data?.img} alt={data?.name} />
                </div>
              )
            })}
          </Row>
        </div>
      </Layout.Content>
    </>
  )
}

export default Brands
