import React, { useState, useEffect } from 'react'
import Intro from './Screens/Intro'
import Questions from './Screens/Questions'
import End from './Screens/End'
import PageStatus from '@/component/PageStatus'
import PrepActions from '@/redux/prep'
import Feedback from './Screens/Feedback'
import { Helmet } from 'react-helmet'

const Prep = () => {
  const [loading, setLoading] = useState(false)
  const [index, setIndex] = useState(1)
  const [sessionId, setSessionId] = useState(null)
  const [questions, setQuestions] = useState([])
  const [quesNum, setQuesNum] = useState(1)
  const [audio, setAudio] = useState(new Array(5).fill(null))
  const [ansTrans, setAnsTrans] = useState(new Array(5).fill(null))
  // const [fillerWords, setFillerWords] = useState(new Array(5).fill(null))
  const [feedback, setFeedback] = useState(new Array(5).fill(null))
  const [isGPTResponded, setIsGPTResponded] = useState(true)

  let session_type = "interview_flow"

  const sendJobDetails = async (jobTitle, jobDescription, pk) => {
    setLoading(true)
    const res = await PrepActions.sendJobDetails(jobTitle, jobDescription, pk)
    setSessionId(res.session_id)
    setQuestions(res.questions)
    setIndex(2)
    setLoading(false)
  }

  useEffect(() => {
    if (feedback[quesNum - 2]) {
      setIsGPTResponded(true)
    }
  }, [feedback])

  const sendAudio = async () => {
    setLoading(true)
    if (quesNum <= questions.length) {
      if (quesNum < questions.length) {
        setIsGPTResponded(false)
        setQuesNum((prev) => prev + 1)
      } else {
        setQuesNum(1)
        setIndex(3)
      }
      setLoading(false)
      const res = await PrepActions.sendAudio(sessionId, quesNum, audio[quesNum - 1])

      updateAnsTrans(quesNum, res.transcription)
      // updateFillerWords(quesNum, res.fillerWords);
      updateFeedback(quesNum, res.feedbackk)
      // setIndex(3);
    }
  }
  // const sendAudio = async () => {
  //   setLoading(true)
  //   console.log(audio)
  //   if (quesNum <= questions.length) {
  //     // setQuesNum((prev) => prev + 1);
  //     const res = await PrepActions.sendAudio(sessionId, quesNum, audio[quesNum - 1]);
  //     updateAnsTrans(quesNum, res.transcription);
  //     // updateFillerWords(quesNum, res.fillerWords);
  //     updateFeedback(quesNum, res.feedbackk);
  //     setIndex(3);
  //   }
  //   setLoading(false)
  // }

  const updateAnsTrans = (index, value) => {
    const newArray = ansTrans.slice()
    newArray[index - 1] = value
    setAnsTrans(newArray)
  }

  // const updateFillerWords = (index, value) => {
  //   const newArray = feedback.slice()
  //   newArray[index] = value
  //   setFillerWords(newArray)
  // }

  const updateFeedback = (index, value) => {
    const newArray = feedback.slice()
    newArray[index - 1] = value
    setFeedback(newArray)
  }

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])

  if (loading) return <PageStatus type="loading" />

  return (
    <div>
      <Helmet>
        <title>ApplyIn Mock Interview: Engage in Realistic Interview Scenarios with ChatGPT</title>
        <meta
          name="description"
          content="Experience a mock interview with 5 select questions from ApplyIn. Powered by ChatGPT, get a taste of real-world interview situations and refine your responses."
        />
      </Helmet>
      {index === 1 && <Intro sendJobDetails={sendJobDetails} />}
      {index === 2 && (
        <Questions
          audio={audio}
          quesNum={quesNum}
          questions={questions}
          setIndex={setIndex}
          setAudio={setAudio}
          setQuesNum={setQuesNum}
          sendAudio={sendAudio}
          isGPTResponded={isGPTResponded}
          setIsGPTResponded={setIsGPTResponded}
        />
      )}
      {index === 3 && (
        <End
          audio={audio}
          setAudio={setAudio}
          quesNum={quesNum}
          questions={questions}
          setIndex={setIndex}
          ansTrans={ansTrans}
          setQuesNum={setQuesNum}
          // fillerWords={fillerWords}
          feedback={feedback}
          isGPTResponded={isGPTResponded}
        />
      )}
      {index === 4 && <Feedback session_type={session_type} sessionId={sessionId} setLoading={setLoading} />}
    </div>
  )
}

export default Prep
