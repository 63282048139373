import React, { useState } from 'react'
import AuthActions from '@/redux/auth'
import { Button, Divider, Modal, Row, Space, Typography, Form, message, Col, Select, Tag } from 'antd'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

const Skills = ({ data, updateBasicInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdaing, setIsUpdating] = useState(false)

  const onSubmit = async (values) => {
    try {
      const titleCaseSkills = values.skills.map((skill) => skill.replace(/\b\w/g, (char) => char.toUpperCase()))

      setIsUpdating(true)

      await AuthActions.updateProfile(
        {
          ...data,
          skills: titleCaseSkills.join(', ')
        },
        data.id
      )
      updateBasicInfo({ skills: titleCaseSkills.join(', ') })
      setIsUpdating(false)
      setIsModalOpen(false)
      message.success('Saved successfully.')
    } catch (e) {
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" align={'middle'}>
        <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
          💪 Skills
        </Typography.Title>
        <Space>
          <Button icon={<EditOutlined style={{ fontSize: 30 }} />} type="text" onClick={() => setIsModalOpen(true)} />
        </Space>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        {data?.skills?.length === 0 ? (
          <Row justify="space-between" align={'middle'}>
            <Typography.Text type="secondary">No skills provided</Typography.Text>
            {/* <Button icon={<EditOutlined style={{ fontSize: 30 }} />} type="text" onClick={() => setIsModalOpen(true)} /> */}
          </Row>
        ) : (
          <Row justify="space-between" align={'middle'}>
            <Space size={10} style={{ flexWrap: 'wrap' }}>
              {data?.skills.split(',').map((d, key) => (
                <Tag key={key} size="large" color="black">
                  {d}
                </Tag>
              ))}
            </Space>
          </Row>
        )}
      </Space>

      <Modal title="Edit Skills" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={800}>
        <Form
          name="skillsform"
          layout="vertical"
          onFinish={onSubmit}
          style={{ padding: 15 }}
          initialValues={{
            skills: data?.skills?.length > 0 ? data?.skills.split(',') : []
          }}
        >
          <Form.Item name="skills" label={'Add up to 10 skills. Press enter to enter new skill'} required={false}>
            <Select mode="tags" style={{ width: '100%' }} size="" />
          </Form.Item>

          <Row justify="space-between" style={{ marginTop: 30 }} gutter={40}>
            <Col span={12}>
              <Button htmlType="submit" type="primary" block loading={isUpdaing}>
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button htmlType="button" type="ghost" block onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default Skills
