import React, { useState } from 'react'
import AuthActions from '@/redux/auth'
import { Button, Divider, Modal, Row, Space, Typography, Form, Input, Col, message, Popconfirm } from 'antd'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

const Links = ({ data, updateBasicInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isUpdaing, setIsUpdating] = useState(false)

  const onSubmit = async (values) => {
    try {
      setIsUpdating(true)
      if (data.birthday) data.birthday = moment(data.birthday).format('YYYY-MM-DD')
      await AuthActions.updateProfile(
        {
          ...data,
          ...values
        },
        data.id
      )
      updateBasicInfo(values)
      setIsUpdating(false)
      setIsModalOpen(false)
      message.success(
        Object.entries(values).some(([key, val]) => data[key] !== val) ? 'Saved successfully.' : 'No changes made'
      )
    } catch (e) {
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  const onDelete = async (link) => {
    try {
      await AuthActions.updateProfile({ ...data, [link]: '' }, data.id)
      updateBasicInfo({ ...data, [link]: '' })
    } catch (e) {}
  }

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" align={'middle'}>
        <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
          🔗 Links
        </Typography.Title>
      </Row>
      <Space direction="vertical" style={{ width: '100%' }}>
        {!data?.linkedln_url && !data?.github_url && (
          <Row justify="space-between" align={'middle'}>
            <Typography.Text type="secondary">No links provided</Typography.Text>
            <Button icon={<EditOutlined style={{ fontSize: 30 }} />} type="text" onClick={() => setIsModalOpen(true)} />
          </Row>
        )}
        {data?.linkedln_url && (
          <Row justify="space-between" align={'middle'}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <Row direction="horizantal" justify="space-between" size={0} align="middle">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Linkedln
                </Typography.Title>
                <Space>
                  <Button
                    icon={<EditOutlined style={{ fontSize: 30 }} />}
                    type="text"
                    onClick={() => setIsModalOpen(true)}
                  />
                  <Popconfirm
                    title="Are you sure to delete this link?"
                    onConfirm={() => onDelete('linkedln_url')}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ style: { height: 35 } }}
                    cancelButtonProps={{ style: { height: 35 } }}
                  >
                    <Button icon={<DeleteOutlined style={{ fontSize: 30 }} />} type="text" />
                  </Popconfirm>
                </Space>
              </Row>
              <Typography.Text type="secondary" underline>
                <a
                  href={`https://www.linkedin.com/in/${data['linkedln_url']}`}
                  target="_blank"
                >{`https://www.linkedin.com/in/${data['linkedln_url']}`}</a>
              </Typography.Text>
            </Space>
          </Row>
        )}
        {data?.github_url && (
          <Row justify="space-between" align={'middle'}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <Row direction="horizantal" justify="space-between" size={0} align="middle">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Github
                </Typography.Title>
                <Space>
                  <Button
                    icon={<EditOutlined style={{ fontSize: 30 }} />}
                    type="text"
                    onClick={() => setIsModalOpen(true)}
                  />
                  <Popconfirm
                    title="Are you sure to delete this link?"
                    onConfirm={() => onDelete('github_url')}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ style: { height: 35 } }}
                    cancelButtonProps={{ style: { height: 35 } }}
                  >
                    <Button icon={<DeleteOutlined style={{ fontSize: 30 }} />} type="text" />
                  </Popconfirm>
                </Space>
              </Row>
              <Typography.Text type="secondary" underline>
                <a
                  href={`https://github.com/${data['github_url']}`}
                  target="_blank"
                >{`https://github.com/${data['github_url']}`}</a>
              </Typography.Text>
            </Space>
          </Row>
        )}
        {data?.twitter_url && (
          <Row justify="space-between" align={'middle'}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <Row direction="horizantal" justify="space-between" size={0} align="middle">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Twitter
                </Typography.Title>
                <Space>
                  <Button
                    icon={<EditOutlined style={{ fontSize: 30 }} />}
                    type="text"
                    onClick={() => setIsModalOpen(true)}
                  />
                  <Popconfirm
                    title="Are you sure to delete this link?"
                    onConfirm={() => onDelete('twitter_url')}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ style: { height: 35 } }}
                    cancelButtonProps={{ style: { height: 35 } }}
                  >
                    <Button icon={<DeleteOutlined style={{ fontSize: 30 }} />} type="text" />
                  </Popconfirm>
                </Space>
              </Row>
              <Typography.Text type="secondary" underline>
                <a
                  href={`https://twitter.com/${data['twitter_url']}`}
                  target="_blank"
                  rel="noreferrer"
                >{`https://twitter.com/${data['twitter_url']}`}</a>
              </Typography.Text>
            </Space>
          </Row>
        )}
        {data?.website_url && (
          <Row justify="space-between" align={'middle'}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <Row direction="horizantal" justify="space-between" size={0} align="middle">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  Portfolio Website
                </Typography.Title>
                <Space>
                  <Button
                    icon={<EditOutlined style={{ fontSize: 30 }} />}
                    type="text"
                    onClick={() => setIsModalOpen(true)}
                  />
                  <Popconfirm
                    title="Are you sure to delete this link?"
                    onConfirm={() => onDelete('website_url')}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ style: { height: 35 } }}
                    cancelButtonProps={{ style: { height: 35 } }}
                  >
                    <Button icon={<DeleteOutlined style={{ fontSize: 30 }} />} type="text" />
                  </Popconfirm>
                </Space>
              </Row>
              <Typography.Text type="secondary" underline>
                <a href={`${data['website_url']}`} target="_blank" rel="noreferrer">{`${data['website_url']}`}</a>
              </Typography.Text>
            </Space>
          </Row>
        )}
      </Space>

      <Divider />

      <Modal title="Edit Links" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={800}>
        <Form name="educationForm" layout="vertical" onFinish={onSubmit} style={{ padding: 15 }} initialValues={data}>
          <Form.Item name="linkedln_url" label={'Linkedln'} required={false}>
            <Input addonBefore="https://www.linkedin.com/in/" />
          </Form.Item>

          <Form.Item name="github_url" label={'Github'} required={false}>
            <Input addonBefore="https://github.com/" />
          </Form.Item>

          <Form.Item name="twitter_url" label={'Twitter'} required={false}>
            <Input addonBefore="https://twitter.com/" />
          </Form.Item>

          <Form.Item name="website_url" label={'Portfolio Website'} required={false}>
            <Input />
          </Form.Item>

          <Row justify="space-between" style={{ marginTop: 30 }} gutter={40}>
            <Col span={12}>
              <Button htmlType="submit" type="primary" block loading={isUpdaing}>
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button htmlType="button" type="ghost" block onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default Links
