import { combineReducers } from 'redux'
import CommonReducer from './common/reducer'
import AuthReducer from './auth/reducer'
import storyReducer from './story/reducer'

const reducer = combineReducers({
  common: CommonReducer,
  auth: AuthReducer,
  story: storyReducer
})

export default reducer
