import React, { useEffect, useState } from 'react'
import InterviewQuestions from './screens/InterviewQuestions'
import Audio from './screens/Audio'
import End from './screens/End'
import Feedback from '../prep/Screens/Feedback'
import PageStatus from '@/component/PageStatus'
import PrepActions from '@/redux/prep'
import { Helmet } from 'react-helmet'

const Questions = () => {
  const [index, setIndex] = useState(1)
  const [loading, setLoading] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [questions, setQuestions] = useState([])

  const [displayMessage,setDisplayMessage] = useState(false)

  const [nextOffset, setNextOffset] = useState('')
  const [previousOffset, setPreviousOffset] = useState([])

  const [selcQues, setSelcQues] = useState(null)
  const [audio, setAudio] = useState(null)
  const [ansTrans, setAnsTrans] = useState('')
  const [feedback, setFeedback] = useState('')

  const [urlChange, setUrlChange] = useState('')
  const [updateApiCall, setUpdateApiCall] = useState(false)

  let session_type = 'questionbank_flow'

  // Determine the conditions to disable "Previous" and "Next" buttons
  const disablePrevious = previousOffset.length <= 2
  const disableNext = nextOffset === ''

  // Take value of dropdown from url
  const searchParams = new URLSearchParams(window.location.search)
  const questionType = searchParams.get('questiontype')
  const company = searchParams.get('company')
  const difficulty = searchParams.get('difficulty')
  const tags = searchParams.get('tags')
  const carreerStage = searchParams.get('carreerstage')
  const role = searchParams.get('role')


  useEffect(() => {
    fetchQuestionBank()
  }, [])

  const fetchQuestionBank = async () => {
    setLoading(true)
    let payload = {
      questionType: questionType || '',
      company: company || '',
      difficulty: difficulty || '',
      tags: tags || '',
      careerStage: carreerStage || '',
      role: role || '',
      offset: nextOffset
    }

    const res = await PrepActions.fetchQuestionBank(payload)
    // setQuestions(res)
    setQuestions(res.Questions)
    setPreviousOffset((prev) => [...prev, nextOffset]) // Move currentOffset to previousOffset
    setNextOffset(res.offset)
    setLoading(false)
  }

  const sendAudio = async () => {
    setLoading(true)
    setDisplayMessage(true)
    console.log(audio)
    if (audio) {
      const res = await PrepActions.sendQuesAudio(selcQues, audio)
      // updateAnsTrans(quesNum, res.transcription)
      // updateFeedback(quesNum, res.feedbackk)
      setSessionId(res.session_id)
      setAnsTrans(res.transcription)
      setFeedback(res.feedback)
      setIndex(3)
    }
    setLoading(false)
    setDisplayMessage(false)
  }

  const handleTryAgain = () => {
    setLoading(true)
    setSelcQues(null)
    setAudio(null)
    setAnsTrans('')
    setFeedback('')
    setIndex(1)
    setLoading(false)
  }

  // Define a callback function to handle "Next" button click
  const handleNextClick = () => {
    // setOffset(nextOffset)
    fetchQuestionBank()
  }

  // Define a callback function to handle "Previous" button click
  const handlePreviousClick = () => {
    const newOffset = previousOffset[previousOffset.length - 2]
    // setNextOffset(previousOffset)
    setNextOffset(newOffset)

    // Create a new array without the last two elements
    const newPreviousOffset = previousOffset.slice(0, -2)

    setPreviousOffset(newPreviousOffset)

    setUpdateApiCall(true)
  }

  useEffect(() => {
    fetchQuestionBank()
  }, [urlChange])

  useEffect(() => {
    if (updateApiCall) {
      fetchQuestionBank()
      setUpdateApiCall(false)
    }
  }, [nextOffset])

  if (loading) return <PageStatus type="loading" displayMessage={displayMessage}/>

  return (
    <>
      <Helmet>
        <title>ApplyIn Question Bank: Dive into Unlimited Interview Practice Questions</title>
        <meta
          name="description"
          content="Explore ApplyIn's extensive question bank. From common to niche queries, prepare for any interview scenario. Power up your skills with AI insights from ChatGPT."
        />
      </Helmet>
      {index == 1 && (
        <InterviewQuestions
          setIndex={setIndex}
          questions={questions}
          setSelcQues={setSelcQues}
          setUrlChange={setUrlChange}
          onNextClick={handleNextClick}
          onPreviousClick={handlePreviousClick}
          disablePrevious={disablePrevious} // Pass the disablePrevious condition
          disableNext={disableNext} // Pass the disableNext condition
          setNextOffset={setNextOffset} // Reset nextoffset when click on generate
          setPreviousOffset={setPreviousOffset} // Reset previous when click on generate
        />
      )}
      {index == 2 && (
        <Audio setIndex={setIndex} selcQues={selcQues} audio={audio} setAudio={setAudio} sendAudio={sendAudio} />
      )}
      {index == 3 && (
        <End
          setIndex={setIndex}
          selcQues={selcQues}
          audio={audio}
          ansTrans={ansTrans}
          feedback={feedback}
          loading={true}
          handleTryAgain={handleTryAgain}
          displayMessage={true}
        />
      )}
      {index == 4 && <Feedback session_type={session_type} sessionId={sessionId} setLoading={setLoading} />}
    </>
  )
}
export default Questions
