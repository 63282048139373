import React, { useEffect, useState } from 'react'
import { TokenApi } from '@/utils/api'
import { Row, Typography, Button } from 'antd'
import Col from 'antd/es/grid/col'
import icon from '../../../assets/icons/copy.png'
import Header from '@/layouts/header'
import { useHistory } from 'react-router-dom'
import './stories.less'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

const { Text } = Typography

const Elem = ({ name }) => {
  const history = useHistory();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatString = (str) => {
    let result = str.split(':')[0].trim()
    return result
  }

  const saveRedirect = async () => {
    console.log("saving the title : ", name)
    const storyIdResponse = await TokenApi.post('story-activity-save-title/', { title: name });
    const storyId = storyIdResponse.StoryID;
    history.push(`/write?storyId=${storyId}`);
  };

  return (
    <div id="element" className="stories-list" onClick={saveRedirect}>
      <div id="elem_left">
        <img src={icon} alt="icon" style={{ height: '60%', width: '20px', marginRight: '10px' }} />
        <span id="elem_left_title">{windowWidth >= 1024 ? name : formatString(name)}</span>
      </div>
    </div>
  );
};

const Catalog = () => {
  const [titles, setTitles] = useState([]);
  const auth = useSelector((state) => state.auth);
  const history = useHistory();

  const fetchAllTitles = async () => {
    const allTitles = await TokenApi.get('story-activity-essay-title/')
    const usedStories = await TokenApi.get('story-activity-user-stories/')
    const usedTitles = usedStories.map((story) => story.title)

    const unusedTitles = allTitles.filter((title) => !usedTitles.includes(title))

    setTitles([...unusedTitles.slice(0, 5)])
  }

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchAllTitles = async () => {
      try {
        const allTitles = await TokenApi.get('story-activity-essay-title/');
        console.log("the titles are : ", allTitles);
        const usedStories = await TokenApi.get('story-activity-user-stories/');
        const usedTitles = usedStories.map((story) => story.title);

        const unusedTitles = allTitles.filter((title) => !usedTitles.includes(title));
        console.log("unused : ", unusedTitles);

        // Only update state if the component is still mounted
        if (isMounted) {
          setTitles(unusedTitles.slice(0, 5));
        }
      } catch (error) {
        console.error('Error fetching titles:', error);
      }
    };

    fetchAllTitles();

    return () => {
      isMounted = false; // Cleanup function to mark the component as unmounted
    };
  }, []);

  const checkOnBoarding = async (email) => {
    console.log(email)
    const res = await TokenApi.get(`check/?user_id=${email}`)
    console.log("the res is : ", res);
    if (!res?.exists) {
      console.log("redirected ..")
      history.push('/details')
    }
  }

  useEffect(() => {
    if (auth.userInfo) {
      checkOnBoarding(auth.userInfo.data.profile.email)
    }
  }, [auth])

  return (
    <>
      <Helmet>
        <title>applyin.co: Catalog</title>
        <meta name="description" content={`Choose a story to write from the catalog`} />
      </Helmet>
      <div className="main" style={{ height: '100vh', width: '100vw' }}>
        <div className="header" style={{ height: '68px' }}>
          <Header />
        </div>
        <Col id="stories-container">
          <div
            className=""
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px'
            }}
          >
            <Text strong type="primary" className="welcome-name-text" style={{ fontSize: 25 }}>
              Click on a title to start writing
            </Text>
          </div>

          <div id="stories-lists" className="body">
            {!titles ? (
              <>loading...</>
            ) : (
              <>
                <div id="element" className="stories-list" onClick={
                  async () => {
                    const name = 'Untitled'
                    console.log("saving the title : ", name)
                    const storyIdResponse = await TokenApi.post('story-activity-save-title/', { title: name });
                    const storyId = storyIdResponse.StoryID;
                    // write?storyId=166&createOwnTitle=true
                    history.push(`/write?storyId=${storyId}&createOwnTitle=${true}`)
                  }

                }>
                  <div id="elem_left">
                    <img src={icon} alt="icon" style={{ height: '60%', width: '20px', marginRight: '10px' }} />
                    <span id="elem_left_title">Create your own title</span>
                  </div>
                </div>
                {titles?.map((title, index) => (
                  <Elem name={title} key={index} />
                ))}

              </>
            )}
          </div>
        </Col>
      </div>
    </>
  )
}

export default Catalog