
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './InterviewQuestions.less'
import { Space, Typography, Anchor, Button, Select } from 'antd'
import { DownSvg } from '@/icon'
import { ShareAltOutlined } from '@ant-design/icons'
import Search from 'antd/lib/transfer/search'
import PageStatus from '@/component/PageStatus'
import PrepActions from '@/redux/prep'
import ProgressBar from '@/pages/profile/sections/progressBar'
import { Row } from 'antd'


const InterviewQuestions = ({
  setIndex,
  questions,
  setSelcQues,
  setUrlChange,
  onNextClick,
  onPreviousClick,
  disablePrevious,
  disableNext,
  setNextOffset,
  setPreviousOffset
}) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState()
  const [loading, setLoading] = useState(false)

  const handelMenuOpen = () => {
    setOpen(!open)
  }
  const handelMenuClose = () => {
    setOpen(false)
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`)
  }
  const [dropdown, setDropdown] = useState(false)

  // Take value of dropdown from url
  const searchParams = new URLSearchParams(window.location.search)
  const questionType = searchParams.get('questiontype')
  const company = searchParams.get('company')
  const difficulty = searchParams.get('difficulty')
  const tags = searchParams.get('tags')
  const carreerStage = searchParams.get('carreerstage')
  const role = searchParams.get('role')

  // Function to check if the value exists in the options array
  function getValueOrDefault(value, optionsArray) {
    return optionsArray?.includes(value) ? value : null
  }

  // set form values after clicking submit button
  const [formValues, setFormValues] = useState({
    questionType: getValueOrDefault(questionType, options?.[2]?.QuestionType),
    company: getValueOrDefault(company, options?.[0]?.Company),
    difficulty: getValueOrDefault(difficulty, options?.[3]?.Difficulty),
    tags: getValueOrDefault(tags, options?.[4]?.Tags),
    carreerStage: getValueOrDefault(carreerStage, options?.[5]?.CareerStage),
    role: getValueOrDefault(role, options?.[1]?.Role)
  })

  //Handle next and previous button

  const handleNextClick = () => {
    onNextClick()
  }

  const handlePreviousClick = () => {
    onPreviousClick()
  }

  // handle submit button
  const handleSubmit = async () => {
    setNextOffset('')
    setPreviousOffset([''])
    const queryParams = {
      questiontype: formValues.questionType,
      company: formValues.company,
      difficulty: formValues.difficulty,
      tags: formValues.tags,
      carreerstage: formValues.carreerStage,
      role: formValues.role
    }

    // Filter out null or undefined values
    const filteredParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => value !== null && value !== undefined)
    )

    // Build the query string
    const queryString = new URLSearchParams(filteredParams).toString()

    // Construct the new URL
    const newUrl = `/questions/?${queryString}`

    // Update the URL with the new query string
    window.history.replaceState(null, '', newUrl)

    setUrlChange((prev) => prev + 'change')
  }

  const handleQuestionClick = (question) => {
    console.log(question)
    setSelcQues(question.question)
    setIndex(2)
    // history.push('/another-page')
  }

  // Create an array of anchor items based on categories
  // const anchorItems = questions.category
  //   .slice()
  //   .reverse()
  //   .map((category) => ({
  //     key: category.category.split(' ')[0],
  //     href: `#${category.category.split(' ')[0]}`, // Use the first word as the ID
  //     title: category.category.split(' ')[0] // Use the first word as the anchor title
  //   }))
  // console.log(anchorItems)

  useEffect(async () => {
    // console.log('This running');
    setLoading(true)
    const res = await PrepActions.fetchOptions()
    setOptions(res)

    setFormValues({
      questionType: getValueOrDefault(questionType, res[2]?.QuestionType),
      company: getValueOrDefault(company, res[0]?.Company),
      difficulty: getValueOrDefault(difficulty, res[3]?.Difficulty),
      tags: getValueOrDefault(tags, res[4]?.Tags),
      carreerStage: getValueOrDefault(carreerStage, res[5]?.CareerStage),
      role: getValueOrDefault(role, res[1]?.Role)
    })
    setLoading(false)
  }, [])

  if (loading) return <PageStatus type="loading" />

  return (
    <div className="questions-container">
      <Row justify={'center'} style={{ alignSelf: 'center', marginBottom: 30 }}>
        <ProgressBar />
      </Row>
      <h2 style={{ fontWeight: 'bold' }}>Interview Questions</h2>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Space direction="vertical" className="profile-space" style={{ marginBottom: 50 }}>
          <Typography.Text>👆 Click on a question to kickstart your practice! 🎙️</Typography.Text>
        </Space>
        <Button type="primary" className="btn-primary primary-btn">
          <a rel="noreferrer" href="https://airtable.com/appXzVQnnuJ6Lp9rx/shrgxmes3TH2GcCIi" target="_blank">
            Suggest a question
          </a>
        </Button>
      </div>


      <div className="question-header">
        <div className="inner-header-wrapper">
          <Select
            placeholder="Question Type"
            value={formValues.questionType}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                questionType: value
              })
            }
            allowClear
          >
            {options?.map((option, index) =>
              option?.QuestionType?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            )}
          </Select>

          <Select
            placeholder="Company"
            value={formValues.company}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                company: value
              })
            }
            allowClear
          >
            {options?.map((option, index) =>
              option?.Company?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            )}
          </Select>

          <Select
            placeholder="Difficulty"
            value={formValues.difficulty}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                difficulty: value
              })
            }
            allowClear
          >
            {options?.map((option, index) =>
              option?.Difficulty?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            )}
          </Select>

          <Select
            placeholder="Tags"
            value={formValues.tags}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                tags: value
              })
            }
            allowClear
          >
            {options?.map((option, index) =>
              option?.Tags?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            )}
          </Select>

          <Select
            placeholder="Carreer Stage"
            value={formValues.carreerStage}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                carreerStage: value
              })
            }
            allowClear
          >
            {options?.map((option, index) =>
              option?.CareerStage?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            )}
          </Select>

          <Select
            placeholder="Role"
            value={formValues.role}
            onChange={(value) =>
              setFormValues({
                ...formValues,
                role: value
              })
            }
            allowClear
          >
            {options?.map((option, index) =>
              option?.Role?.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))
            )}
          </Select>
        </div>
        {/* <Search placeholder="search for questions" className="search-field" /> */}
        <div className="btn-wrapper">
          <Button
            className="btn-primary primary-btn"
            type="primary"
            htmlType="submit"
            form="prompt"
            onClick={handleSubmit}
          >
            Search
          </Button>

        </div>
      </div>

      {/* <Anchor
        direction="vertical"
        items={questions.category
          .slice()
          .reverse()
          .map((category) => ({
            key: category.category.split(' ')[0],
            href: `#${category.category.split(' ')[0]}`, // Use the first word as the ID
            title: category.category.split(' ')[0] // Use the first word as the anchor title
          }))}
      /> */}
      {/* {questions.category.slice().map((category, index) => (
        <>
          <div key={index} className="questions-list">
            <Typography.Title level={2} id={category.category.split(' ')[0]}>
              {category.category}
            </Typography.Title>
          </div>
          <ul className="question-ul">
            {questions.question_bank
              .filter((question) => question.category === category.id)
              .map((question, qIndex) => (
                <li className="question-card" key={qIndex}>
                  <Typography.Text className="sm-heading">
                    <mark>Category 1</mark>
                  </Typography.Text>
                  <Typography.Title level={4} className="list-item" onClick={() => handleQuestionClick(question)}>
                    {question.question}
                  </Typography.Title>
                </li>
              ))}
          </ul>
        </>
      ))} */}
      {/* 
      <ul>
        {questions?.map((question, index) => (
          <li
            key={index}
            style={{ cursor: 'pointer' }}
            className="list-item"
            onClick={() => handleQuestionClick(question)}
          >
            {question}
          </li>
        ))}
      </ul> */}

      <ul className="question-ul">
        {questions.map((question, index) => (
          <>
            <li className="question-card" key={index}>
              {question.company.map((company, index) => (
                <Typography.Text className="sm-heading" key={index}>
                  <mark>{company}</mark>
                </Typography.Text>
              ))}

              <Typography.Title level={4} className="list-item" onClick={() => handleQuestionClick(question)}>
                {question.question}
              </Typography.Title>
            </li>
          </>
        ))}
      </ul>

      <div className="prev-next-btns">
        <Button className="generate-btn" type="primary" onClick={handlePreviousClick} disabled={disablePrevious}>
          &lt; Previous
        </Button>

        <Button className="generate-btn" type="primary" onClick={handleNextClick} disabled={disableNext}>
          Next &gt;
        </Button>
      </div>
    </div>
  )
}

export default InterviewQuestions
