import React from 'react'
import { Card, Button, Row } from 'antd'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'

const Tips = ({ string }) => {
  const [title, subtitle] = string.split(':').map((str) => str.trim())
  return (
    <>
      <Row justify={'center'} style={{ alignSelf: 'center' }}>
        <div id="question_card" className="question_card">
          <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>{title}</h5>
          <p style={{ fontSize: '15px' }}>{subtitle}</p>
        </div>
      </Row>
    </>
  )
}

Tips.propTypes = {
  string: PropTypes.string
}

export default Tips
