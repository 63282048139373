import React from 'react'
import { Card, Button, Row } from 'antd'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'

const Questions = ({ question, discard, index }) => {
  return (
    <>
      <Row justify={'center'} style={{ alignSelf: 'center' }}>
        <div id="question_card" className="question_card">
          <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>Think about</h5>
          <h5 id="question_text">{question}</h5>
          <div style={{ marginTop: '10px', display: 'flex' }}>
            <Button
              id="question_button"
              type="primary"
              style={{
                height: '35px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '15px'
              }}
              onClick={() => discard()}
            >
              Ignore
            </Button>
          </div>
        </div>
      </Row>
    </>
  )
}

Questions.propTypes = {
  question: PropTypes.string,
  discard: PropTypes.func,
  index: PropTypes.number
}

export default Questions
