import React from 'react'

import HeroSection from './components/HeroSection'
import tweetImage from '../../assets/icons/tweet-image.svg'
import writingundertree from '../../assets/icons/writing-under-tree.svg'
import writingscroll from '../../assets/icons/writing-scroll.svg'

import scottfitzgerald from '../../assets/icons/scott-fitzgerald.svg'
import rabindranathtagore from '../../assets/icons/rabindranath-tagore.svg'
import leotolstoy from '../../assets/icons/leo-tolstoy.svg'
import janeaustin from '../../assets/icons/jane-austin.svg'
import ernesthemingway from '../../assets/icons/ernest-hemingway.svg'
import colette from '../../assets/icons/colette.svg'


import howitworks1 from '../../assets/icons/how-it-works-1.svg'
import howitworks2 from '../../assets/icons/how-it-works-2.svg'
import howitworks3 from '../../assets/icons/how-it-works-3.svg'

import './index.less'
import SymbolTags from './components/SymbolTags'
import Header from '@/layouts/header'
import Footer from '@/layouts/footer'
import { Layout } from 'antd'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const StepBox = ({ stepno, text, desp, symbolSvg }) => {
  return (
    <div className="step-box-container">
      <div className="step-box-left">
        {/* <img src={heroImage} /> */}
        {symbolSvg}
      </div>

      <div className="step-box-right">
        <h2>
          <span>{stepno}</span> {text}
        </h2>
        <p>{desp}</p>
      </div>
    </div>
  )
}

const Home = () => {
  return (
    <>
      <Layout className="basic-layout">
        <Header />
        <HeroSection />

        <div className="motive-container">
          <h1 style={{ fontWeight: 700, fontSize: '32px' }}>Unlock Your Potential with Legendary Guidance</h1>
          <h2>Select from our roster of lengendary writers</h2>
        </div>
        <div className="authors-container">
          <div className="author-card">
            <div className="author-icon">
              <img src={scottfitzgerald} alt="Scott Fitzgerald" />
            </div>
            <div className="author-card-content">
              <h3>Scott Fitzgerald</h3>
              <p>Perfect for intricate storytelling and vivid character creation.</p>
            </div>
          </div>
          <div className="author-card">
            <div className="author-icon">
              <img src={rabindranathtagore} alt="Rabindranath Tagore" />
            </div>
            <div className="author-card-content">
              <h3>Rabindranath Tagore</h3>
              <p>A great choice for those who love poetic prose and lyrical beauty.</p>
            </div>
          </div>
          <div className="author-card">
            <div className="author-icon">
              <img src={leotolstoy} alt="Leo Tolstoy" />
            </div>
            <div className="author-card-content">
              <h3>Leo Tolstoy</h3>
              <p>Ideal for exploring complex narratives and deep philosophical themes.</p>
            </div>
          </div>
          <div className="author-card">
            <div className="author-icon">
              <img src={janeaustin} alt="Jane Austin" />
            </div>
            <div className="author-card-content">
              <h3>Jane Austen</h3>
              <p>Suited for those who enjoy wit, romance, and sharp social commentary.</p>
            </div>
          </div>
          <div className="author-card">
            <div className="author-icon">
              <img src={ernesthemingway} alt="Ernest Hemingway" />
            </div>
            <div className="author-card-content">
              <h3>Ernest Hemingway</h3>
              <p>Best for aspiring writers who appreciate minimalistic and impactful writing.</p>
            </div>
          </div>
          <div className="author-card">
            <div className="author-icon">
              <img src={colette} alt="Colette" />
            </div>
            <div className="author-card-content">
              <h3>Colette</h3>
              <p>Perfect for bold, expressive, and evocative writing.</p>
            </div>
          </div>
        </div>

        <div className="motive-container">
          <p>
            Turn writing into a habit with our engaging platform. Discover new ways to express yourself and develop your
            writing skills through daily practice.
          </p>
        </div>

        {/* 
        <div className="fun-effective-container">
          <div className="left-container">
            <h1>Discover the joy of writing!</h1>
            <div>
              <p>Empowering writers to create unique stories </p>
            </div>
            <div>
              <SymbolTags symbol="👨‍💻" text="Unleash inner creativity" />
              <SymbolTags symbol="💼" text="Get instant feedback " />
              <SymbolTags symbol="🚀" text="Build your critical thinking & confidence" />
            </div>
          </div>

          <div className="right-container">
            <img src={writingundertree} alt="writingundertree image" />
          </div>
        </div>
        */}

        <div className="tweet-container">
          <a href="https://twitter.com/AdamMGrant/status/1750545746066043206">
            <img src={tweetImage} alt="tweet" />
          </a>
        </div>

        <div className="motive-container">
          <h1>Discover Your Voice Through Writing</h1>
          <p>
            Writing is a journey of self-discovery, bridging the mind and the world. It reflects the human spirit’s
            capacity to create, connect, and inspire. Our goal is to help you refine your thinking and guide you in
            transforming ideas into compelling narratives.
          </p>
        </div>

        {/*}
        <div className="fun-effective-container">
          <div className="left-container">
            <h1>Fun & effective</h1>

            <div>
              <p>We make writing fun! </p>
              <p>With quick, small writing tasks, you earn points and unlock new levels while gaining real-world writing skills.  </p>

            </div>

            <div>
              <SymbolTags symbol="📝" text="Overcome writer’s block" />
              <SymbolTags symbol="📖" text="Publish a book" />
              <SymbolTags symbol="🎖️" text="Earn a certificate" />
            </div>
          </div>

          <div className="right-container">
            <img src={writingscroll} alt="writingscroll image" />
          </div>
        </div>
        */}

        <div className="how-it-works-container">
          <h1>HOW IT WORKS</h1>

          <div className="step-wrapper">
            <div className="hiw-icon">
              <img src={howitworks1} alt="how-it-works-1" className="step-image" />
            </div>
            <div className="stepbox">
              <h2 className="step-heading">
                <span className="step-count">1</span> Choose a mentor
              </h2>
              <p className="step-description">
                Choose a mentor from literary legends: Fitzgerald, Tagore, Hemingway, Tolstoy, Austen & Colette. Each
                mentor offers unique insights and guidance, helping you enhance your writing style
              </p>
            </div>
          </div>

          <div className="step-wrapper">
            <div className="hiw-icon">
              <img src={howitworks2} alt="how-it-works-2" className="step-image" />
            </div>
            <div className="stepbox">
              <h2 className="step-heading">
                <span className="step-count">2</span> Start writing
              </h2>
              <p className="step-description">
                Start your writing journey by choosing a story prompt from our diverse collection, or create your own to
                match your unique interests and style
              </p>
            </div>
          </div>

          <div className="step-wrapper">
            <div className="hiw-icon">
              <img src={howitworks3} alt="how-it-works-3" className="step-image" />
            </div>
            <div className="stepbox">
              <h2 className="step-heading">
                <span className="step-count">3</span> Publish your book
              </h2>
              <p className="step-description">
                After completing 21 stories, we will assemble them into a beautifully published book. This collection
                will be a testament to your dedication and imagination!
              </p>
            </div>
          </div>
        </div>

        <div className="get-started-container">
          <h1 style={{ fontWeight: 700, fontSize: '32px' }}>Write anytime, anywhere</h1>
          <button>
            <Link to="/library">Get Started </Link>
          </button>
        </div>
        <div className="footer" style={{ width: '100%', backgroundColor: '#0182FA' }}>
          <Footer />
        </div>
      </Layout>
    </>
  )
}

export default Home
