import { LinkedinFilled } from '@ant-design/icons'
import { Layout, Typography } from 'antd'
import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="applyin-footer">
      <Layout.Content>
        <Typography.Title className="text" style={{ color: 'white', fontWeight: '800' }}>
          Company
        </Typography.Title>
        <div className="footer-inner">
          <div className="footer-links">
            <a
              rel="noreferrer"
              href="https://writeary.notion.site/Writeary-How-We-Work-d039ba15f7774958b3338fdd76752fe6?pvs=4"
              target="_blank"
            >
              About Us
            </a>
            <a
              rel="noreferrer"
              href="https://writeary.notion.site/Writeary-com-FAQ-11531c4a32af807b927dd5d7fd398827"
              target="_blank"
            >
              FAQ
            </a>
            <a
              rel="noreferrer"
              href="https://www.notion.so/writeary/Writeary-com-FAQ-11531c4a32af807b927dd5d7fd398827?pvs=4"
              target="_blank"
            >
              Terms & Privacy
            </a>
          </div>
          <div className="applyin-linkedin">
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/getapplyin/"
              target="_blank"
              className="md-heading"
            >
              Follow us on &nbsp;&nbsp;
            </a>
            <LinkedinFilled className="linkedIn-icon" />
          </div>
        </div>
        <Typography.Text className="footer-text">
          © {new Date().getFullYear()} Wayte Technologies Pvt Ltd
        </Typography.Text>
      </Layout.Content>
    </footer>
  )
}

export default Footer
