// Redux Type Constants
export const COMMON = {
  TOP_ALERT: 'TOP_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT'
}

export const AUTH = {
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
  USER_PROFILE: 'USER_PROFILE',
  USER_INFO: 'USER_INFO',
  UPDATE_USER: 'UPDATE_USER'
}

export const partnersUrl = '/partners'
