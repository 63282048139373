import { ShareIcon as SI } from '@/icon'
import { Tooltip } from 'antd'
import clipboardCopy from 'clipboard-copy'
import React, { useState } from 'react'

const ShareToolTip = ({ URL = null }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleCopyUrl = () => {
    const currentUrl = URL ?? window.location.href
    clipboardCopy(currentUrl)
      .then(() => {
        setTooltipVisible(true)
        setTimeout(() => {
          setTooltipVisible(false)
        }, 2000)
      })
      .catch((err) => {
        console.error('Failed to copy URL to clipboard: ', err)
      })
  }
  return (
    <Tooltip
      title="Share"
      className="tooltip-icon"
      onClick={handleCopyUrl}
      overlayClassName={`${tooltipVisible ? 'show' : ''} share-tooltip`}
    >
      <SI />
      {tooltipVisible && <div className="tooltip">Link Copied</div>}
    </Tooltip>
  )
}

export default ShareToolTip
