import { Button, Layout, Typography } from 'antd'
import React from 'react'

function Apply() {
  return (
    <>
      <div className="apply-container spacing">
        <Layout.Content>
          <Typography.Title className="md-heading"> Start your interview prep with applyin</Typography.Title>
          <Typography className="text"> Master Your Interview, One Question at a Time</Typography>
          <a href="/profile">
            <Button type="primary" className="primary-btn">Start applyin &gt;</Button>
          </a>
        </Layout.Content>
      </div>
    </>
  )
}

export default Apply
