import { Api } from '@/utils/api'
import { Button, Col, Layout, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Tooltip } from 'antd'
import ClapButton from '../clap/ClapButton'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'
import Toast from '../components/Toast'
import { ShareIcon } from '@/icon'
import { Helmet } from 'react-helmet'
import './stories.less'
import './viewStory.less'

const { Title } = Typography

const ViewStories = () => {
  const [stories, setStories] = useState({})
  const [notFound, setNotFound] = useState(null)
  const [apiResponse, setApiResponse] = useState(false)

  const [isShareToastOn, setIsShareToastOn] = useState(false)
  const [isLikeToastOn, setIsLikeToastOn] = useState(false)
  const [author, setauthor] = useState('')
  const [date, setdate] = useState('')
  const { id } = useParams()
  const history = useHistory()

  const fetchList = async () => {
    const res = await Api.get(`story-activity-read/${id}/`).catch((err) => setNotFound(err.error))
    // console.log('data', res)s
    setStories(res)
    setauthor(res.author)

    const date = new Date(res.timestamp)
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    const formattedDate = date.toLocaleDateString('en-US', options)
    setdate(formattedDate)

    setApiResponse(true)
  }
  useEffect(() => {
    fetchList()
  }, [])

  const copyLink = async () => {
    const text = `${stories?.title}\n by ${author}\n Link: https://applyin.co/read/${id}`
    await navigator.clipboard.writeText(text)
    setIsShareToastOn(true)
    setTimeout(() => {
      setIsShareToastOn(false)
    }, 1500)

    if (window.innerWidth <= 640 && navigator.share)
      await navigator.share({
        title: 'Applyin.co story',
        author: author,
        text: stories?.title,
        url: `https://applyin.co/read/${id}`
      })
  }

  return (
    <>
      <Helmet>
        <title>applyin.co: {stories ? `${stories?.title}` : 'Story'}</title>
        <meta name="description" content={`Story of ${stories?.title}`} />
      </Helmet>
      <div style={{ width: '100%', backgroundColor: 'white' }}>
        <Toast text={'✅ Link Copied'} isOn={isShareToastOn} />
        <Toast text={'You cannot applaud your own story. Share your story with a friend!'} isOn={isLikeToastOn} />

        <div className={`stories-wrapper ${notFound ? 'inner-wrapper' : null}`}>
          <span
            id="back"
            style={{ position: 'absolute', cursor: 'pointer', left: '15px' }}
            onClick={() => history.push('/library')}
          >
            ←
          </span>
          <Title level={2}> {notFound}</Title>
          {!notFound && apiResponse && (
            <Row justify="center">
              <Col span={24} md={20} lg={12}>
                <Title style={{ fontSize: 42 }}>{stories?.title}</Title>
                <div style={{ margin: '30px 0px' }}>
                  {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', marginLeft: '3px', alignItems: 'center' }}>
                      <ClapButton applauseCount={stories?.applause_count} storyId={id} />
                    </div>
                    <ShareToolTip />
                    <Button
                      size="medium"
                      style={{
                        width: '90px',
                        height: '30px',
                        border: '1px solid black',
                        fontSize: '15px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                      }}
                      onClick={() => copyLink()}
                    >
                      Share
                      <ShareIcon />
                    </Button>
                  </div> */}
                </div>
                <div
                  className="story-container"
                  dangerouslySetInnerHTML={{ __html: stories?.story }}
                  style={{ margin: '50px 0px' }}
                ></div>
                <div>
                    <p style={{ fontSize: '25px', marginBottom: '-2px' }}>{author}</p>
                    <p style={{ fontSize: '17px', color: 'gray' }}>Last edited: {date}</p>
                  </div>
                <div className="story-action">
                  <ClapButton applauseCount={stories?.applause_count} storyId={id} applauseToast={setIsLikeToastOn} />
                  <Button
                    size="medium"
                    style={{
                      width: '90px',
                      height: '30px',
                      border: '1px solid black',
                      fontSize: '15px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                    onClick={() => copyLink()}
                  >
                    Share
                    <ShareIcon />
                  </Button>
                </div>
                <div style={{ margin: '30px 0px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <Link
                    to={'/'}
                    style={{
                      padding: '10px 20px',
                      backgroundColor: 'white',
                      borderRadius: '30px',
                      border: '0.5px solid gray',
                      fontSize: '15px',
                      cursor: 'pointer'
                    }}
                  >
                    ✍️ Start writing with <span style={{ fontWeight: 'bold' }}>writeary.com</span>{' '}
                    <img
                      style={{ width: '16px' }}
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUklEQVR4nO2UsQkAIAwEz83cJuto57RqY2Fl5aNiDr7NEUIenFcJI7LhGSgqSQQq0IZIIrFJItvE/pS0DSkriVxw3R3Mhx+pigAk5WPJ69phKx0vBELwTwHqLwAAAABJRU5ErkJggg=="
                    ></img>
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  )
}

export default ViewStories
