import React, { Suspense, Fragment, useMemo } from 'react'
import { Switch, Route, Router, Redirect } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'
import { createBrowserHistory } from 'history'

// Routers
import appRoutes from '@/routes'

// Basic Components
import Auth from '@/component/AuthWrap'
import ScrollReset from '@/component/ScrollReset'
import LoadingScreen from '@/component/LoadingScreen'

const history = createBrowserHistory()

const App = () => {
  const routers = useMemo(() => {
    return appRoutes()
  }, [])

  const renderRoutes = (routes) =>
    routes ? (
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment
            const Layout = route.layout || Fragment
            const Component = route.component
            const ComponentProps = route.props || {}

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => (
                  <Guard>
                    <Layout>
                      {route.children ? renderRoutes(route.children) : <Component {...props} {...ComponentProps} />}
                    </Layout>
                  </Guard>
                )}
              />
            )
          })}
          <Route
            path="/terms-and-privacy"
            component={() => {
              window.location.href =
                'https://applyin.notion.site/Terms-and-Privacy-Policy-cb65ca863e6e418cb2f1f728a852a26c'
              return null
            }}
          />
        </Switch>
      </Suspense>
    ) : null

  return (
    <Router history={history}>
      <Auth>
        <ScrollReset />
        {renderRoutes(routers)}
      </Auth>
    </Router>
  )
}

export default hot(App)
