import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons'
import { Button, Row, Input, Space, Typography, Col, Card, Divider, Tooltip } from 'antd'
import { FaRegThumbsUp, FaThumbsUp, FaRegThumbsDown, FaThumbsDown } from 'react-icons/fa'
// import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import PrepActions from '@/redux/prep'
import './Feedback.less'
import { Helmet } from 'react-helmet'

const Feedback = ({ sessionId, session_type, setLoading, history }) => {
  const [isThumbsUp, setIsThumbsUp] = useState(false)
  const [isThumbsDown, setIsThumbsDown] = useState(false)
  const [isBtn, setIsBtn] = useState(false)
  const [feedback, setFeedback] = useState('')

  // const history = useHistory()

  const handleThumbsUp = () => {
    if (isThumbsDown) setIsThumbsDown(!isThumbsDown)
    setIsThumbsUp(!isThumbsUp)
  }

  const handleThumbsDown = () => {
    if (isThumbsUp) setIsThumbsUp(!isThumbsUp)
    setIsThumbsDown(!isThumbsDown)
  }

  const handleBtn = () => {
    const words = feedback.split(' ') // Split the input into words
    if (words.length >= 10 && (isThumbsDown || isThumbsUp)) {
      setIsBtn(true)
    } else {
      setIsBtn(false) // Optionally, set it to false if there are fewer than 10 words
    }
  }

  useEffect(() => {
    // This effect runs whenever isThumbsUp or isThumbsDown or feedback changes
    handleBtn() // Update the button state when either of these variables change
  }, [isThumbsUp, isThumbsDown, feedback])

  const handleInput = (e) => {
    const inputValue = e?.target?.value || '' // Ensure inputValue is not null or undefined
    setFeedback(inputValue)
  }

  const handleSubmit = async () => {
    // setLoading(true)
    try {
      history.push('/profile')
      await PrepActions.sendFeedback(sessionId, session_type, feedback, isThumbsUp)
    } catch (error) {
      alert('Error sending feedback: ' + error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="feedback-div-container">
      <Helmet>
        <title>ApplyIn Feedback: AI-Powered Insights to Improve Your Interview Responses</title>
        <meta
          name="description"
          content="Dive into personalized feedback on your mock interview responses. Harness the power of ChatGPT to pinpoint strengths and areas for improvement in your interview skills."
        />
      </Helmet>
      {/* <div className='back-btn-cover' /> */}
      <Card title="Help us serve you better" align="middle">
        <Col align="middle">
          <Typography>How was your overall experience? Please give us a thumbs up or thumbs down.</Typography>
          <Space direction="horizontal">
            <Button
              onClick={() => handleThumbsDown()}
              type="text"
              style={{ position: 'relative', width: 50, margin: 10, padding: 10 }}
            >
              <IconContext.Provider value={{ className: 'react-icons' }}>
                {!isThumbsDown ? (
                  <div>
                    <FaRegThumbsDown />
                  </div>
                ) : (
                  <div>
                    <svg height="76" width="76" style={{ position: 'absolute', transform: 'translate(-20px, -20px)' }}>
                      <circle cx="38" cy="38" r="35" stroke="black" strokeWidth="3" fill="none" />
                    </svg>
                    <FaThumbsDown />
                  </div>
                )}
              </IconContext.Provider>
            </Button>
            <Button onClick={() => handleThumbsUp()} type="text" style={{ width: 30, margin: 10, padding: 10 }}>
              <IconContext.Provider value={{ className: 'react-icons', style: { transform: 'rotateY(180deg)' } }}>
                {!isThumbsUp ? (
                  <FaRegThumbsUp />
                ) : (
                  <div>
                    <svg height="76" width="76" style={{ position: 'absolute', transform: 'translate(-15px, -15px)' }}>
                      <circle cx="38" cy="38" r="35" stroke="black" strokeWidth="3" fill="none" />
                    </svg>
                    <FaThumbsUp />
                  </div>
                )}
              </IconContext.Provider>
            </Button>
          </Space>
        </Col>
        <Divider />
        <Col align="start">
          <Typography>Tell Us More (at least 10 words)</Typography>
          <Input.TextArea
            autoSize={{
              minRows: 4
              // maxRows: 7
            }}
            value={feedback}
            onChange={(e) => handleInput(e)}
            placeholder="Please let us know why you chose the rating you did. We value your feedback and want to improve our process."
          ></Input.TextArea>
        </Col>
        {!isBtn ? (
          <Tooltip
            title={
              isThumbsUp || isThumbsDown
                ? 'Please provide feedback with a minimum of 10 words'
                : 'Please rate us with a thumbs up or thumbs down'
            }
            placement="bottom"
          >
            <Button type="secondary" disabled={true} style={{ marginTop: '20px', paddingInline: 20, letterSpacing: 1 }}>
              SUBMIT
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            onClick={() => handleSubmit()}
            href="/profile"
            style={{ marginTop: '20px', paddingInline: 20, letterSpacing: 1 }}
          >
            SUBMIT
          </Button>
        )}
      </Card>
    </div>
  )
}

export default withRouter(Feedback)
