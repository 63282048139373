import './styles/symbolTags.less'
import React from 'react'

const SymbolTags = ({ symbol, text }) => {
  return (
    <div className="symbol-tags-container">
      <p>{symbol}</p>
      <p>{text}</p>
    </div>
  )
}

export default SymbolTags
