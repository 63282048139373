import React from 'react'
import { Col, Layout, Row, Typography } from 'antd'
import list from '@/assets/images/works-img-1.svg'
import microphone from '@/assets/images/microphone.svg'
import bag from '@/assets/images/bag.svg'
import comment from '@/assets/images/comment.svg'

const WorksMain = [
  {
    heading: '🎯 Practice a Question',
    text: 'Everything you need to get your story straight',
    workCard: [
      {
        img: list,
        alt: 'work1',
        number: 1,
        desc: 'Select a question from our question bank'
      },
      {
        img: microphone,
        alt: 'microphone',
        number: 2,
        desc: 'Record your audio responses for the question'
      },
      {
        img: comment,
        alt: 'comment',
        number: 3,
        desc: 'Get instant feedback and steps to improve your response'
      }
    ]
  },
  {
    heading: '🚀 Mock Interview',
    text: 'Experience the full interview, tailored to your desired role',
    workCard: [
      {
        img: bag,
        alt: 'bag',
        number: 1,
        desc: 'Enter the job title and job description for the role you are applying to'
      },
      {
        img: microphone,
        alt: 'microphone',
        number: 2,
        desc: 'Record your audio responses for each question'
      },
      {
        img: comment,
        alt: 'comment',
        number: 3,
        desc: 'Get instant feedback and steps to improve your response'
      }
    ]
  }
]

const Works = () => {
  return (
    <>
      <div className="works-section">
        <Layout.Content>
          <Typography.Title level={2} className="md-heading blue">
            how it works
          </Typography.Title>
          <div className="work-outer-section">
            {WorksMain.map((item, i) => {
              return (
                <div key={i} className="work-inner-wrapper">
                  <Typography.Title level={3} className="md-heading">
                    {item?.heading}
                  </Typography.Title>
                  <Typography className="text">{item?.text}</Typography>
                  <Row className="work-card-row" gutter={16}>
                    {item.workCard.map((data, i) => {
                      return (
                        <Col
                          lg={{ span: 8 }}
                          md={{ span: 12 }}
                          xs={{ span: 24 }}
                          key={i}
                          style={{ textAlign: 'center' }}
                        >
                          <div className="work-card-wrapper">
                            <div className="work-img-out">
                              <img src={data?.img} alt={data?.name} />
                            </div>
                            <Typography className="sm-text">
                              {data.number}. {data.desc}
                            </Typography>
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              )
            })}
          </div>
        </Layout.Content>
      </div>
    </>
  )
}

export default Works
