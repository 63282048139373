import React, { useState } from 'react'
import { Layout } from 'antd'
import Header from './header'
import Sidebar from './sidebar'

const AppLayout = (props) => {
  const [open, setOpen] = useState(true)

  return (
    <Layout className="app-layout">
      <Header onClick={() => setOpen(!open)} />
      <div className="app-layout-inner">
        <Sidebar {...{ setOpen, open }} />
        <Layout className={`${open ? 'open' : ''} side-layout`}>
          <Layout.Content>{props.children}</Layout.Content>
        </Layout>
      </div>
    </Layout>
  )
}

const BasicLayout = (props) => {
  return (
    <Layout className="basic-layout">
      <Header />
      {props.children}
    </Layout>
  )
}

export { AppLayout, BasicLayout }
