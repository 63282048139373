import React from 'react'

export const Expand = () => {
  return (
    <svg role="graphics-symbol" viewBox="-1 -1 9 11">
      <path
        id="path0_stroke"
        d="M 3.5 0L 3.98809 -0.569442L 3.5 -0.987808L 3.01191 -0.569442L 3.5 0ZM 3.5 9L 3.01191 9.56944L 3.5 9.98781L 3.98809 9.56944L 3.5 9ZM 0.488094 3.56944L 3.98809 0.569442L 3.01191 -0.569442L -0.488094 2.43056L 0.488094 3.56944ZM 3.01191 0.569442L 6.51191 3.56944L 7.48809 2.43056L 3.98809 -0.569442L 3.01191 0.569442ZM -0.488094 6.56944L 3.01191 9.56944L 3.98809 8.43056L 0.488094 5.43056L -0.488094 6.56944ZM 3.98809 9.56944L 7.48809 6.56944L 6.51191 5.43056L 3.01191 8.43056L 3.98809 9.56944Z"
      ></path>
    </svg>
  )
}
export const Project = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_920_65498)">
        <path
          d="M8 15.1943C11.9102 15.1943 15.1299 11.9746 15.1299 8.06445C15.1299 4.16113 11.9033 0.93457 7.99316 0.93457C4.08984 0.93457 0.870117 4.16113 0.870117 8.06445C0.870117 11.9746 4.08984 15.1943 8 15.1943ZM8 13.6289C4.91699 13.6289 2.44238 11.1475 2.44238 8.06445C2.44238 4.98145 4.91016 2.5 7.99316 2.5C11.083 2.5 13.5576 4.98145 13.5645 8.06445C13.5713 11.1475 11.083 13.6289 8 13.6289ZM8 12.3643C10.3516 12.3643 12.293 10.4092 12.293 8.06445C12.293 5.71289 10.3584 3.76465 8 3.76465C5.64844 3.76465 3.70703 5.71973 3.70703 8.06445C3.70703 10.4092 5.66211 12.3643 8 12.3643ZM8 10.9834C6.4209 10.9834 5.08105 9.64355 5.08105 8.06445C5.08105 6.47852 6.41406 5.14551 8 5.14551C9.5791 5.14551 10.9189 6.48535 10.9189 8.06445C10.9189 9.65039 9.58594 10.9834 8 10.9834ZM8.00684 9.72559C8.90918 9.72559 9.66113 8.97363 9.66113 8.06445C9.66113 7.16211 8.90918 6.41016 8.00684 6.41016C7.09082 6.41016 6.3457 7.15527 6.3457 8.06445C6.3457 8.97363 7.09766 9.72559 8.00684 9.72559Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0_920_65498">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export const Blog = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 512 512"
      className="blog-icon"
    >
      <title>browser-10-outline</title>
      <path
        className="cls-1"
        d="M512,64A64,64,0,0,0,448,0H64A64,64,0,0,0,0,64V448a64,64,0,0,0,64,64H448a64,64,0,0,0,64-64ZM486.4,448A38.44,38.44,0,0,1,448,486.4H64A38.44,38.44,0,0,1,25.6,448V140.8H486.4ZM25.6,115.2V64A38.44,38.44,0,0,1,64,25.6H448A38.44,38.44,0,0,1,486.4,64v51.2Z"
      />
      <circle className="cls-1" cx="70.4" cy="70.4" r="19.2" />
      <circle className="cls-1" cx="121.6" cy="70.4" r="19.2" />
      <circle className="cls-1" cx="172.8" cy="70.4" r="19.2" />
      <path
        className="cls-1"
        d="M96,281.6h38.4a32,32,0,0,0,32-32V211.2a32,32,0,0,0-32-32H96a32,32,0,0,0-32,32v38.4A32,32,0,0,0,96,281.6Zm-6.4-70.4a6.41,6.41,0,0,1,6.4-6.4h38.4a6.41,6.41,0,0,1,6.4,6.4v38.4a6.41,6.41,0,0,1-6.4,6.4H96a6.41,6.41,0,0,1-6.4-6.4Z"
      />
      <path className="cls-1" d="M76.8,345.6h76.8a12.8,12.8,0,0,0,0-25.6H76.8a12.8,12.8,0,1,0,0,25.6Z" />
      <path className="cls-1" d="M76.8,396.8h76.8a12.8,12.8,0,0,0,0-25.6H76.8a12.8,12.8,0,1,0,0,25.6Z" />
      <path
        className="cls-1"
        d="M236.8,281.6h38.4a32,32,0,0,0,32-32V211.2a32,32,0,0,0-32-32H236.8a32,32,0,0,0-32,32v38.4A32,32,0,0,0,236.8,281.6Zm-6.4-70.4a6.41,6.41,0,0,1,6.4-6.4h38.4a6.41,6.41,0,0,1,6.4,6.4v38.4a6.41,6.41,0,0,1-6.4,6.4H236.8a6.41,6.41,0,0,1-6.4-6.4Z"
      />
      <path
        className="cls-1"
        d="M377.6,281.6H416a32,32,0,0,0,32-32V211.2a32,32,0,0,0-32-32H377.6a32,32,0,0,0-32,32v38.4A32,32,0,0,0,377.6,281.6Zm-6.4-70.4a6.41,6.41,0,0,1,6.4-6.4H416a6.41,6.41,0,0,1,6.4,6.4v38.4A6.41,6.41,0,0,1,416,256H377.6a6.41,6.41,0,0,1-6.4-6.4Z"
      />
      <path className="cls-1" d="M76.8,448h38.4a12.8,12.8,0,1,0,0-25.6H76.8a12.8,12.8,0,1,0,0,25.6Z" />
      <path className="cls-1" d="M217.6,345.6h76.8a12.8,12.8,0,1,0,0-25.6H217.6a12.8,12.8,0,1,0,0,25.6Z" />
      <path className="cls-1" d="M217.6,396.8h76.8a12.8,12.8,0,1,0,0-25.6H217.6a12.8,12.8,0,1,0,0,25.6Z" />
      <path className="cls-1" d="M217.6,448H256a12.8,12.8,0,1,0,0-25.6H217.6a12.8,12.8,0,1,0,0,25.6Z" />
      <path className="cls-1" d="M358.4,345.6h76.8a12.8,12.8,0,1,0,0-25.6H358.4a12.8,12.8,0,0,0,0,25.6Z" />
      <path className="cls-1" d="M358.4,396.8h76.8a12.8,12.8,0,1,0,0-25.6H358.4a12.8,12.8,0,0,0,0,25.6Z" />
      <path className="cls-1" d="M358.4,448h38.4a12.8,12.8,0,1,0,0-25.6H358.4a12.8,12.8,0,0,0,0,25.6Z" />
    </svg>
  )
}
export const DownSvg = () => {
  return (
    <svg role="graphics-symbol" viewBox="0 0 12 12" className="chevronDownRoundedThick">
      <path d="M6.02734 8.80274C6.27148 8.80274 6.47168 8.71484 6.66211 8.51465L10.2803 4.82324C10.4268 4.67676 10.5 4.49609 10.5 4.28125C10.5 3.85156 10.1484 3.5 9.72363 3.5C9.50879 3.5 9.30859 3.58789 9.15234 3.74902L6.03223 6.9668L2.90722 3.74902C2.74609 3.58789 2.55078 3.5 2.33105 3.5C1.90137 3.5 1.55469 3.85156 1.55469 4.28125C1.55469 4.49609 1.62793 4.67676 1.77441 4.82324L5.39258 8.51465C5.58789 8.71973 5.78808 8.80274 6.02734 8.80274Z"></path>
    </svg>
  )
}

export const ShareIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none"  style={{ marginTop: '3px' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.22 4.93a.42.42 0 0 1-.12.13h.01a.45.45 0 0 1-.29.08.52.52 0 0 1-.3-.13L12.5 3v7.07a.5.5 0 0 1-.5.5.5.5 0 0 1-.5-.5V3.02l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.8a.42.42 0 0 1 .07.5zm-.1.14zm.88 2h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.14c.1.1.15.22.15.35a.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9V8.96c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
export const ClapIcon = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 24 24" aria-label="clap">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.37.83L12 3.28l.63-2.45h-1.26zM15.42 1.84l-1.18-.39-.34 2.5 1.52-2.1zM9.76 1.45l-1.19.4 1.53 2.1-.34-2.5zM20.25 11.84l-2.5-4.4a1.42 1.42 0 0 0-.93-.64.96.96 0 0 0-.75.18c-.25.19-.4.42-.45.7l.05.05 2.35 4.13c1.62 2.95 1.1 5.78-1.52 8.4l-.46.41c1-.13 1.93-.6 2.78-1.45 2.7-2.7 2.51-5.59 1.43-7.38zM12.07 9.01c-.13-.69.08-1.3.57-1.77l-2.06-2.07a1.12 1.12 0 0 0-1.56 0c-.15.15-.22.34-.27.53L12.07 9z"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.74 8.3a1.13 1.13 0 0 0-.73-.5.67.67 0 0 0-.53.13c-.15.12-.59.46-.2 1.3l1.18 2.5a.45.45 0 0 1-.23.76.44.44 0 0 1-.48-.25L7.6 6.11a.82.82 0 1 0-1.15 1.15l3.64 3.64a.45.45 0 1 1-.63.63L5.83 7.9 4.8 6.86a.82.82 0 0 0-1.33.9c.04.1.1.18.18.26l1.02 1.03 3.65 3.64a.44.44 0 0 1-.15.73.44.44 0 0 1-.48-.1L4.05 9.68a.82.82 0 0 0-1.4.57.81.81 0 0 0 .24.58l1.53 1.54 2.3 2.28a.45.45 0 0 1-.64.63L3.8 13a.81.81 0 0 0-1.39.57c0 .22.09.43.24.58l4.4 4.4c2.8 2.8 5.5 4.12 8.68.94 2.27-2.28 2.71-4.6 1.34-7.1l-2.32-4.08z"
      ></path>
    </svg>
  )
}
export const EditIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" aria-label="Write">
      <path
        d="M14 4a.5.5 0 0 0 0-1v1zm7 6a.5.5 0 0 0-1 0h1zm-7-7H4v1h10V3zM3 4v16h1V4H3zm1 17h16v-1H4v1zm17-1V10h-1v10h1zm-1 1a1 1 0 0 0 1-1h-1v1zM3 20a1 1 0 0 0 1 1v-1H3zM4 3a1 1 0 0 0-1 1h1V3z"
        fill="currentColor"
      ></path>
      <path
        d="M17.5 4.5l-8.46 8.46a.25.25 0 0 0-.06.1l-.82 2.47c-.07.2.12.38.31.31l2.47-.82a.25.25 0 0 0 .1-.06L19.5 6.5m-2-2l2.32-2.32c.1-.1.26-.1.36 0l1.64 1.64c.1.1.1.26 0 .36L19.5 6.5m-2-2l2 2"
        stroke="currentColor"
      ></path>
    </svg>
  )
}
export const ViewIcon = () => {
  return (
    <svg version="1.0" id="Layer_1" width="24px" height="24px" viewBox="0 0 64 64">
      <g>
        <path
          fill="#231F20"
          d="M63.934,31.645c-0.015-0.037-0.256-0.646-0.74-1.648C60.597,24.627,51.02,8.004,32,8.004   c-22.568,0-31.842,23.404-31.934,23.641c-0.089,0.231-0.089,0.487,0,0.719C0.158,32.6,9.432,56.004,32,56.004   c19.01,0,28.587-16.605,31.189-21.983c0.486-1.007,0.729-1.62,0.744-1.657C64.022,32.132,64.022,31.876,63.934,31.645z M32,54.004   c-19.686,0-28.677-19.123-29.917-22.001C3.321,29.121,12.288,10.004,32,10.004c19.686,0,28.677,19.123,29.917,22.001   C60.679,34.887,51.712,54.004,32,54.004z"
        />
        <path
          fill="#231F20"
          d="M32,16.008c-8.837,0-16,7.163-16,16s7.163,16,16,16s16-7.163,16-16S40.837,16.008,32,16.008z M32,46.008   c-7.732,0-14-6.268-14-14s6.268-14,14-14s14,6.268,14,14S39.732,46.008,32,46.008z"
        />
        <path
          fill="#231F20"
          d="M32,24.008c-4.418,0-8,3.582-8,8s3.582,8,8,8s8-3.582,8-8S36.418,24.008,32,24.008z M32,38.008   c-3.313,0-6-2.687-6-6s2.687-6,6-6s6,2.687,6,6S35.313,38.008,32,38.008z"
        />
        <path
          fill="#231F20"
          d="M32,28.004c-0.553,0-1,0.447-1,1s0.447,1,1,1c1.104,0,2,0.896,2,2c0,0.553,0.447,1,1,1s1-0.447,1-1   C36,29.795,34.209,28.004,32,28.004z"
        />
      </g>
    </svg>
  )
}
