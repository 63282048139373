import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, Col, Input, Row, Form, Typography, Checkbox, message } from 'antd'
import AuthActions from '@/redux/auth'
import { NavLink } from 'react-router-dom'
import { GoogleLogin } from '@leecheuk/react-google-login'
import { GoogleOutlined, LinkedinOutlined, LinkedinFilled } from '@ant-design/icons'
import { Api } from '@/utils/api'
import { LinkedIn } from 'react-linkedin-login-oauth2'

//const googleClientId = process.env.GOOGLE_CLIENT_ID
const googleClientId = "966161301324-brf0g8ur114q70rrh34m7t8uhkudd5vi.apps.googleusercontent.com"

const SignUp = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(null)
  const [updatesCheck, setUpdatesCheck] = useState(true)
  const [tosCheck, setTosCheck] = useState(true)

  const onFinish = async (values) => {
    setLoading('default')
    try {
      await dispatch(
        AuthActions.signup({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          password2: values.password
        })
      )
      message.success('Account has been created successfully!')

      await dispatch(
        AuthActions.login({
          username: values.email,
          password: values.password
        })
      )
      await dispatch(AuthActions.getCurrentUser())
      setLoading(null)
    } catch (err) {
      setLoading(null)
    }
  }

  const responseGoogle = (authResult) => {
    if (authResult && authResult.credential) {
      setLoading('google')
      Api.post('google/login/', {
        token: authResult.credential
      })
        .then(async (res) => {
          const { access_token, refresh_token } = res
          window.localStorage.setItem('accessToken', access_token)
          window.localStorage.setItem('refreshToken', refresh_token)
          await dispatch(AuthActions.getCurrentUser())
          setLoading(null)
        })
        .catch((err) => {
          message.error('Wrong google token')
          setLoading(null)
        })
    }
  }

  const handleSuccess = (e) => {
    if (e) {
      setLoading('linkedin')
      Api.post('linkedin/login/', {
        token: e
      })
        .then(async (res) => {
          const { access_token, refresh_token } = res
          if (access_token && refresh_token) {
            window.localStorage.setItem('accessToken', access_token)
            window.localStorage.setItem('refreshToken', refresh_token)
            await dispatch(AuthActions.getCurrentUser())
          } else {
            message.error('Something went wrong.')
          }
          setLoading(null)
        })
        .catch((err) => {
          message.error('Wrong google token')
          setLoading(null)
        })
    }
  }

  const handleFailure = (e) => {
    message.error(e)
  }

  useEffect(() => {
    setTimeout(() => {
      google.accounts.id.initialize({
        //client_id: process.env.GOOGLE_CLIENT_ID,
        client_id: "966161301324-brf0g8ur114q70rrh34m7t8uhkudd5vi.apps.googleusercontent.com",
        callback: responseGoogle,
        context: 'signup',
        use_fedcm_for_prompt: false
      })
      google.accounts.id.renderButton(document.getElementById('googleSignup'), {
        theme: 'outline',
        size: 'large',
        text: 'signup_with',
        width: 300
      })
      google.accounts.id.prompt()
    }, 500)
  }, [])

  return (
    <div className="inner-wrapper">
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24} md={20} lg={15} xl={10}>
          <storng>
            <Typography.Title level={2} align="center">
              Create your account
            </Typography.Title>
          </storng>
          <Form
            form={form}
            name="Login"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              updates_checked: true,
              tos_checked: true
            }}
          >
            {/* <Row gutter={25}>
              <Col span={24} md={12}>
                <Form.Item
                  label="First Name"
                  name="first_name"
                  required={false}
                  rules={[{ required: true, message: 'First Name is required' }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  required={false}
                  rules={[{ required: true, message: 'Last Name is required' }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Form.Item
                name="email"
                label="Email Address"
                required={false}
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Invalid email' }
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                label={
                  <div>
                    Password
                    <br />
                    <label>Your password must contain at least 8 characters and a number.</label>
                  </div>
                }
                name="password"
                required={false}
                rules={[{ required: true, message: 'Password is required' }]}
                style={{ marginBottom: 50 }}
              >
                <Input.Password size="large" />
              </Form.Item>
            </div> */}

            {/* <Row justify="center" style={{ marginTop: 100 }}>
              <Button
                htmlType="submit"
                type="primary"
                block
                loading={loading === 'default'}
                size="large"
                style={{ maxWidth: 390 }}
              >
                LET'S GO
              </Button> */}
            {/* </Row> */}

            <Row justify="center" style={{ marginTop: 50 }}>
              {/* <GoogleLogin
                clientId={googleClientId}
                render={(renderProps) => (
                  <Button
                    icon={<GoogleOutlined style={{ fontSize: '35px' }} />}
                    loading={loading === 'google'}
                    htmlType="button"
                    type="ghost"
                    block
                    onClick={renderProps.onClick}
                    disabled={!tosCheck || renderProps.disabled}
                    size="large"
                    style={{ maxWidth: 390, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    Continue with Google
                  </Button>
                )}
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              /> */}
              <div id="googleSignup"></div>
            </Row>
            {/* <Row justify={'center'} style={{ marginTop: 20 }}>
              <p>OR</p>
            </Row>
            <Row justify="center">
              <LinkedIn
                clientId={process.env.LINKEDIN_CLIENT_ID}
                redirectUri={`${window.location.origin}/linkedin`}
                onSuccess={handleSuccess}
                onFailure={handleFailure}
                scope="r_liteprofile r_emailaddress w_member_social"
                className="linkedin"
              >
                {({ linkedInLogin }) => (
                  <Button
                    loading={loading === 'linkedin'}
                    htmlType="button"
                    type="ghost"
                    block
                    size="middle"
                    onClick={linkedInLogin}
                    style={{
                      maxWidth: 300,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 44
                    }}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
                    >
                      <LinkedinFilled style={{ fontSize: '20px' }} />
                      <span style={{ flex: 1, fontSize: 15, fontWeight: 500 }}>Sign up with LinkedIn</span>
                    </div>
                  </Button>
                )}
              </LinkedIn>
            </Row> */}

            {/* <Row style={{ marginTop: 50 }} justify={'center'}>
              <Checkbox size="large" checked={updatesCheck} onChange={(e) => setUpdatesCheck(e.target.checked)}>
                I want to receive job recommendations from ApplyIn
              </Checkbox>
            </Row>
          */}

            <Row style={{ marginTop: 20 }} justify={'center'}>
              <Checkbox checked={tosCheck} onChange={(e) => setTosCheck(e.target.checked)}>
                I agree and accept Applyin.co’s{' '}
                <a
                  href="https://applyin.notion.site/Terms-and-Privacy-cb65ca863e6e418cb2f1f728a852a26c"
                  style={{ textDecoration: 'underline' }}
                >
                  terms and privacy policy
                </a>{' '}
              </Checkbox>
            </Row>

            <Row justify="center" style={{ marginTop: 30 }}>
              Already have an account? &nbsp;&nbsp;&nbsp;{' '}
              <NavLink to="/login">
                <u>Sign in</u>
              </NavLink>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default SignUp
