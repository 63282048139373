import React, { useEffect, useState } from 'react'
import { Button, Row, Input, Space, Typography, Tooltip } from 'antd'
// import { PlayCircleOutlined, AlignCenterOutlined, SaveOutlined, EditOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import './End.less'
import PageStatus from '@/component/PageStatus'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'


const { TextArea } = Input
const { Text } = Typography

const End = (props) => {
  const [isInput, setIsInput] = useState(false)
  const[loading,setLoading]=useState(false)

  // const [gptResp, setGptResp] = useState('')
  const [ansTrans, setAnsTrans] = useState('')
  // const [fillerWords, setFillerWords] = useState([])

  const history = useHistory()
  const fillerWords = [
    //Need to add more filler words
    'like',
    'Umm',
    'Hmm',
    'aaaa..',
    'okkkkk',
    'aaa',
    'I think',
    'I guess',
    'ahh',
    'you know',
    'literally',
    'I mean',
    'actually',
    'um',
    'uh',
    'umm',
    'uhh',
    'ah',
    'seriously',
    'you see',
    'you know what I mean',
    'at the end of the day',
    'or something',
    'just',
    'really',
    'only',
    'almost',
    'slightly',
    'seemed',
    'perhaps',
    'maybe',
    'simplyabsolutely',
    'sort of',
    'kind of',
    'a little',
    'very',
    'like I said',
    'by the way',
    'I just',
    'basically',
    'so basically',
    'or something like that',
    'as well',
    'as well too',
    'it’s like',
    'so',
    'First of all'
  ]
  // const questions = {
  //   1: 'Can you please tell me a bit about yourself?',
  //   2: "Imagine you've run into a challenge with an analysis and you aren't sure how to address it. What are some steps you might take to seek help?",
  //   3: 'How can a subquery help aggregate data?',
  //   4: 'It is possible to not have enough data to answer a question. Is it possible to have too much data?',
  //   5: 'Tell me about a time you made a mistake. How did you communicate that mistake?'
  // }

  useEffect(() => {
    // Check if feedback[4] is not null and quesNum is 4
    if (props.feedback[4] == null && props.quesNum === 5) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [props.feedback, props.quesNum]);
  const handleClick = () => {
    // props.setIndex(2)
    props.setQuesNum(props.quesNum + 1)
  }
    const handleClicks = () => {
      // props.setIndex(2)
      props.setQuesNum(props.quesNum - 1)
    }

    const highlightInputContent = () => {
      // Split the input text into phrases based on common punctuation marks
      const phrasesWithPunctuation = props.ansTrans[props.quesNum - 1].split(/([.,;!?]+)/)
      const fillerPhrases = fillerWords.map((phrase) => phrase.toLowerCase())

      const highlightedPhrases = phrasesWithPunctuation.map((phrase, index) => {
        const normalizedPhrase = phrase.trim().toLowerCase()
        const isPunctuation = /[.,;!?]+/.test(phrase)
        const isHighlighted = fillerPhrases.includes(normalizedPhrase)

        if (isPunctuation) {
          return <span key={index}>{phrase}</span>
        }

        const style = isHighlighted ? { color: 'red', backgroundColor: '#FAEBD7' } : {}

        return (
          <span style={style} key={index}>
            {phrase}{' '}
          </span>
        )
      })
      return highlightedPhrases
    }

    // const questionKey = `audioUrls_question_${props.quesNum}`
    // const storedAudioUrlsJSON = localStorage.getItem(questionKey)
    // const storedAudioUrls = storedAudioUrlsJSON ? JSON.parse(storedAudioUrlsJSON) : []
    // const latestAudioUrl = storedAudioUrls.length > 0 ? storedAudioUrls[storedAudioUrls.length - 1] : null

  const questionKey = `audioUrls_question_${props.quesNum}`
  const storedAudioUrlsJSON = localStorage.getItem(questionKey)
  const storedAudioUrls = storedAudioUrlsJSON ? JSON.parse(storedAudioUrlsJSON) : []
  const latestAudioUrl = storedAudioUrls.length > 0 ? storedAudioUrls[storedAudioUrls.length - 1] : null

  if (loading) {

    return <PageStatus type="loading" />
  }
  else{

    return (
      <div style={{ width: '100%' }}>
        <Row justify={'start'} style={{ alignSelf: 'center', marginBottom: 30 }}>
          <Text type="primary" className="question">
            {props.questions[props.quesNum - 1]}
          </Text>
        </Row>
        <Space direction="vertical" style={{ alignItems: 'center', width: '100%', fontSize: 28 }}>
          {/* <Button
            type="primary"
            icon={<PlayCircleOutlined />}
            size="large"
            style={{ paddingRight: 30, paddingLeft: 30, margin: 30, backgroundColor: '#F6F5F4', color: 'black' }}
          >
            Audio
          </Button> */}
          {/* <audio src={storedAudioUrl} controls></audio> */}
          {latestAudioUrl && (
            <div>
              <audio src={latestAudioUrl} controls></audio>
            </div>
          )}
        </Space>
        <Space
          direction="vertical"
          style={{ width: '100%', backgroundColor: '#F6F5F4', marginBottom: 30, marginTop: 30 }}
        >
          {isInput ? (
            <TextArea
              placeholder="Enter text..."
              onInput={(e) => setAnsTrans(e.target.value)}
              value={props.ansTrans}
              style={{
                border: '1px solid #000',
                borderRadius: 5,
                padding: '10px',
                backgroundColor: 'white',
                minHeight: '100px'
              }}
            />
          ) : (
            ''
          )}
          <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
            <div
              style={{
                border: '1px solid #000',
                borderRadius: 5,
                padding: '10px',
                backgroundColor: 'white',
                minHeight: '100px',
                width: '100%'
              }}
            >
               {highlightInputContent() }
            </div>
            {/* <Button
              icon={!isInput ? <EditOutlined /> : <SaveOutlined />}
              onClick={() => setIsInput(!isInput)}
              style={{
                backgroundColor: '#000',
                color: '#fff',
                fontWeight: 500,
                paddingLeft: 30,
                paddingRight: 30,
                marginRight: 10
              }}
            >
              {!isInput ? 'Edit' : 'Save'}
            </Button> */}
          </Row>
          <Row justify={'center'} style={{ alignSelf: 'center', marginTop: 30 }}>
            <Text type="primary" className="question">
              📈 Feedback
            </Text>
          </Row>
          <TextArea
            placeholder="<gpt_response>"
            value={props.feedback[props.quesNum - 1]}
            onChange={(e) => setGptResp(e.target.value)}
            autoSize={{
              minRows: 7
              // maxRows: 9
            }}
          />
        </Space>
        <Row justify={'space-between'} className="nav-btns">
          {props.quesNum > 1 && (
            <Button
            icon={<ArrowLeftOutlined />}
            type="primary"
            size="middle"
            style={{
              backgroundColor: '#dbdbdb',
              // marginTop: 30,
              marginRight: 5,
              padding: 10,
              color: 'black',
              borderRadius: 25,
              borderWidth: 0
            }}
              onClick={() => {
                props.quesNum >= 2 ? handleClicks() : props.setIndex(4)
              }}
            >
              {/* {props.quesNum >=2 ? 'previouse Question' : 'previouse Question'} */}
            </Button>
          )}

          <Tooltip title={props.isGPTResponded ? null : 'Loading...'} placement="top">
            <Button
              type="primary"
              size="middle"
              // disabled={props.isGPTResponded? false : true}
              // disabled={props.quesNum < 5 && props.feedback[props.quesNum - 1] === null}
              style={{
                backgroundColor: 'black',
                // marginTop: 30,
                marginLeft: 5,
                paddingLeft: 20,
                paddingRight: 20,
                fontWeight: 600,
                borderRadius: 30
              }}
              onClick={() => {
                if(
                  props.quesNum == 4 && props.feedback[4]==null)
                  {
                    setLoading(true)
                  }
                else
                {
                    setLoading(false)
                    //{highlightInputContent()}
                }
                props.quesNum < 5 ? handleClick() : props.setIndex(4)
              }}
            >
              {props.quesNum < 5 ? 'Next ➔' : 'Exit'}
            </Button>
            {/* <End  loading={true}/>/ */}
          </Tooltip>
        </Row>
      </div>
    )
  }

}

export default End
