import { AUTH, COMMON } from '@/constants'
import { Api, TokenApi } from '@/utils/api'

// Get User with Token
export const getCurrentUser = () => {
  return async (dispatch) => {
    try {
      console.log("getting profile ...")
      const res = await TokenApi.get('profile')
      console.log("the user profile is : ", res)
      window.localStorage.setItem('permissions', JSON.stringify(res.auth?.permissions || []))

      dispatch({
        type: AUTH.SIGNED_IN
      })
      dispatch({
        type: AUTH.USER_INFO,
        payload: res
      })

      return res
    } catch (err) {
      dispatch(logOut())
    }
  }
}

// Login
export const login = (obj) => (dispatch) =>
  Api.post('login/', obj)
    .then((res) => {
      window.localStorage.setItem('accessToken', res.access)
      window.localStorage.setItem('refreshToken', res.refresh)
      return res
    })
    .catch((err) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: err.errors[0]['message'] }
      })
      throw err
    })

// Logout
export const logOut = () => (dispatch) => {
  window.localStorage.removeItem('accessToken')
  window.localStorage.removeItem('refreshToken')
  window.localStorage.removeItem('story_id')

  dispatch({
    type: AUTH.SIGNED_OUT
  })
}

// Signup
export const signup = (obj) => {
  return async (dispatch) => {
    try {
      const res = await Api.post('register', obj)
      return res
    } catch (err) {
      throw err
    }
  }
}

export const updateProfile = async (data, pk) => {
  try {
    const res = await TokenApi.put(`profile/${pk}`, data)
    return res
  } catch (err) {
    throw err
  }
}

export const createOrUpdateWorkExperience = async (data, pk = null) => {
  try {
    let res = null
    if (pk) res = await TokenApi.put(`work-experience/${pk}`, data)
    else res = await TokenApi.post(`work-experience`, data)
    return res
  } catch (err) {
    throw err
  }
}

export const deleteWorkExperience = async (id) => {
  try {
    await TokenApi.delete(`work-experience/${id}`)
  } catch (err) {
    throw err
  }
}

export const createOrUpdateEducation = async (data, pk = null) => {
  try {
    let res = null
    if (pk) res = await TokenApi.put(`education/${pk}`, data)
    else res = await TokenApi.post(`education`, data)
    return res
  } catch (err) {
    throw err
  }
}

export const deleteEducation = async (id) => {
  try {
    await TokenApi.delete(`education/${id}`)
  } catch (err) {
    throw err
  }
}
