import React, { useState } from 'react'
import { List } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { Blog, Expand, Project } from '@/icon'
import {
  CalendarOutlined,
  DoubleLeftOutlined,
  EditOutlined,
  MailOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons'

const TemplateItem = [
  {
    icon: <UserOutlined />,
    name: 'Profile',
    to: '/profile',
    target: '_self'
  },
  {
    icon: <EditOutlined />,
    name: 'Story',
    to: '/story',
    target: '_self'
  },
  {
    icon: <Project />,
    name: 'Practice',
    to: '/interview-type',
    target: '_self'
  },
  {
    icon: <Blog />,
    name: 'Blog',
    to: 'https://applyin.notion.site/ApplyIn-Blog-57900fa258ab40cd8e8fcec3994efc09',
    target: '_blank'
  },
  // {
  //   icon: <MailOutlined />,
  //   name: 'Newsletter',
  //   to: 'https://iquotient.beehiiv.com/',
  //   target: '_blank'
  // },
  {
    icon: <CalendarOutlined />,
    name: 'Book a coaching session',
    to: 'https://topmate.io/nksbits',
    target: '_blank'
  }
]

const Sidebar = ({ open, setOpen }) => {
  const location = useLocation()
  const [selectedLink, setSelectedLink] = useState(null)

  const handleLinkClick = (index) => {
    setSelectedLink(index)
  }

  return (
    <div className={`${open ? 'open' : ''} applyin-sidebar`}>
      <div className="inner-wrapper">
        <div className={`${open ? 'open' : ''} arrow-wrapper common-flex`} onClick={() => setOpen(!open)}>
          <DoubleLeftOutlined />
        </div>
        <List className="items-ul">
          {TemplateItem.map((item, i) => (
            <List.Item
              key={i}
              style={{ backgroundColor: location.pathname === item.to ? '#0000000a' : 'transparent' }}
              className="sidebar-link"
            >
              <Link to={{ pathname: item.to }} target={item.target} onClick={() => handleLinkClick(i)}>
                {item?.icon}
                {item?.name}
              </Link>
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  )
}

export default Sidebar
