import React, { useEffect, useState } from 'react'
import { Button, Row, Input, Space, Typography } from 'antd'
import './End.less'
import { RedoOutlined } from '@ant-design/icons'

const { TextArea } = Input
const { Text } = Typography

const End = (props) => {
  const [ansTrans, setAnsTrans] = useState('')

  const fillerWords = [
    //Need to add more filler words
    'like',
    'Umm',
    'Hmm',
    'aaaa..',
    'okkkkk',
    'aaa',
    'I think',
    'I guess',
    'ahh',
    'you know',
    'literally',
    'I mean',
    'actually',
    'um',
    'uh',
    'umm',
    'uhh',
    'ah',
    'seriously',
    'you see',
    'you know what I mean',
    'at the end of the day',
    'or something',
    'just',
    'really',
    'only',
    'almost',
    'slightly',
    'seemed',
    'perhaps',
    'maybe',
    'simplyabsolutely',
    'sort of',
    'kind of',
    'a little',
    'very',
    'like I said',
    'by the way',
    'I just',
    'basically',
    'so basically',
    'or something like that',
    'as well',
    'as well too',
    'it’s like',
    'so',
    'First of all'
  ]

  useEffect(() => {
    setAnsTrans(props.ansTrans)
  }, [props.ansTrans])

  const handleClick = () => {
    props.setIndex(2)
  }

  const highlightInputContent = () => {
    const phrasesWithPunctuation = ansTrans.split(/([.,;!?]+)/)
    const fillerPhrases = fillerWords.map((phrase) => phrase.toLowerCase())

    const highlightedPhrases = phrasesWithPunctuation.map((phrase, index) => {
      const normalizedPhrase = phrase.trim().toLowerCase()
      const isPunctuation = /[.,;!?]+/.test(phrase)
      const isHighlighted = fillerPhrases.includes(normalizedPhrase)

      if (isPunctuation) {
        return <span key={index}>{phrase}</span>
      }

      const style = isHighlighted ? { color: 'red', backgroundColor: '#FAEBD7' } : {}

      return (
        <span style={style} key={index}>
          {phrase}
          {''}
        </span>
      )
    })

    return highlightedPhrases
  }

  const questionKey = `audioUrls_question_${props.selcQues}`
  const storedAudioUrlsJSON = localStorage.getItem(questionKey)
  const storedAudioUrls = storedAudioUrlsJSON ? JSON.parse(storedAudioUrlsJSON) : []
  const latestAudioUrl = storedAudioUrls.length > 0 ? storedAudioUrls[storedAudioUrls.length - 1] : null

  return (
    <div style={{ width: '100%' }}>
      <Row justify={'start'} style={{ alignSelf: 'center', marginBottom: 30 }}>
        <Text type="primary" className="question">
          {props.selcQues}
        </Text>
      </Row>
      <Space direction="vertical" style={{ alignItems: 'center', width: '100%', fontSize: 28 }}>
        {latestAudioUrl && (
          <div>
            <audio src={latestAudioUrl} controls></audio>
          </div>
        )}
      </Space>
      <Space
        direction="vertical"
        style={{ width: '100%', backgroundColor: '#F6F5F4', marginBottom: 30, marginTop: 30 }}
      >
        <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
          <div
            style={{
              border: '1px solid #000',
              borderRadius: 5,
              padding: '10px',
              backgroundColor: 'white',
              minHeight: '100px',
              width: '100%',
              overflowX: 'scroll'
            }}
          >
            {highlightInputContent()}
          </div>
        </Row>
        <Row justify={'center'} style={{ alignSelf: 'center', marginTop: 30 }}>
          <Text type="primary" className="question">
            📈 Feedback
          </Text>
        </Row>
        <TextArea
          placeholder="<gpt_response>"
          value={props.feedback}
          onChange={(e) => setGptResp(e.target.value)}
          autoSize={{
            minRows: 7
            // maxRows: 9
          }}
        />
      </Space>
      <Row justify={'space-between'} className="nav-btns">
        <Button
          icon={<RedoOutlined /> }
          type="primary"
          size="middle"
          className="exit-btn"
          style={{ backgroundColor: '#dbdbdb', paddingLeft: 30, paddingRight: 30, marginRight: 10, fontWeight: 600, color: 'black', borderRadius: 25, borderWidth: 0 }}
          onClick={() => {
            props.handleTryAgain()
          }}
        >
          Try Again
        </Button>
        <Button
          type="primary"
          size="middle"
          className="exit-btn"
          style={{ backgroundColor: 'black', paddingLeft: 20, paddingRight: 20, fontWeight: 600, borderRadius: 30 }}
          onClick={() => {
            props.setIndex(4)
          }}
        >
          Exit
        </Button>
      </Row>
    </div>
  )
}

export default End
