import React, { useEffect, useState } from 'react'
import { Button, Col, Layout, List, Row, Typography } from 'antd'
import LandingImg1 from '@/assets/images/Landing_page_img1.svg'

const HomeBanner = () => {
  const [index, setIndex] = useState(0)
  const AlternatingText = () => {
    useEffect(() => {
      const timer = setInterval(() => {
        setIndex((index + 1) % textArray.length)
      }, interval)

      return () => {
        clearInterval(timer)
      }
    }, [index, interval, textArray])

    return (
      <div className="sliding-text">
        <div className="text-container">
          {textArray.map((text, i) => (
            <span key={i} className={i === index ? 'visible' : 'hidden'}>
              {text}
            </span>
          ))}
        </div>
      </div>
    )
  }
  const textArray = [
    'consulting interview',
    'product interview',
    'data science interview',
    'engineering interview',
    'MBA admissions interview',
    'marketing interview',
    'project management interview',
    'finance interview',
    'HR interview'
  ]
  const interval = 2000
  return (
    <>
      <Layout.Content>
        <div className="home-banner">
          <Row>
            <Col lg={{ span: 10 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <div className="left-content">
                <Typography.Title level={1} style={{ margin: 0 }} className="quote heading">
                  Interview practice made easy
                </Typography.Title>
                <Typography.Title level={1} className="text home-desc">
                  Prepare for your next
                  <AlternatingText textArray={textArray} interval={interval} />
                </Typography.Title>
                <List className="desc-ul">
                  <List.Item>
                    <Typography className="text">🚀 &nbsp;Unlimited practice sessions</Typography>
                  </List.Item>
                  <List.Item>
                    <Typography className="text">👨‍💻 &nbsp;Get instant feedback</Typography>
                  </List.Item>
                  <List.Item>
                    <Typography className="text">💼 &nbsp;Build your skill and confidence</Typography>
                  </List.Item>
                </List>
                <a href="/profile" style={{ display: 'inline-block' }}>
                  <Button type="primary" className="btn-started">
                    Get started
                  </Button>
                </a>
              </div>
            </Col>
            <Col lg={{ span: 14 }} md={{ span: 24 }} xs={{ span: 24 }}>
              <div className="right-content">
                <img src={LandingImg1} />
              </div>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </>
  )
}

export default HomeBanner
