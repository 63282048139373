import React, { useState } from 'react'
import AuthActions from '@/redux/auth'
import moment from 'moment'
import {
  Button,
  Divider,
  Modal,
  Row,
  Space,
  Typography,
  Form,
  Input,
  Col,
  message,
  DatePicker,
  Popconfirm,
  Checkbox,
  Select
} from 'antd'
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

const Education = ({ data = [], updateBasicInfo, profile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [form] = Form.useForm()
  const [isUpdaing, setIsUpdating] = useState(false)
  const [selectedEdu, setSelectedEdu] = useState(false)
  const [isCSHchecked, setIsCSHchecked] = useState(false)
  const { Option } = Select

  const onSubmit = async (values) => {
    try {
      setIsUpdating(true)
      const res = await AuthActions.createOrUpdateEducation({
        ...values,
        start_date: moment(values.start_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD'),
        profile: profile?.id,
        study_currently: isCSHchecked
      })
      updateBasicInfo([...data, res])
      setIsUpdating(false)
      setIsModalOpen(false)
      setSelectedEdu(null)
      message.success('New education created successfully.')
    } catch (e) {
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  const disabledDate = (current) => {
    if (startDate) return current && current <= startDate.endOf('day')

    return false
  }

  const onUpdate = async (values) => {
    try {
      setIsUpdating(true)
      const res = await AuthActions.createOrUpdateEducation(
        {
          ...values,
          start_date: moment(values.start_date).format('YYYY-MM-DD'),
          end_date: moment(values.end_date).format('YYYY-MM-DD'),
          profile: profile?.id,
          study_currently: isCSHchecked
        },
        selectedEdu.id
      )
      updateBasicInfo(
        data.map((d) => {
          if (d.id === selectedEdu.id) return res
          else return d
        })
      )
      setIsUpdating(false)
      setIsModalOpen(false)
      setSelectedEdu(null)
      message.success('Saved successfully.')
    } catch (e) {
      console.log(e)
      setIsUpdating(false)
      message.error('Something went wrong.')
    }
  }

  const editEducation = (d) => {
    form.setFieldsValue({ ...d, start_date: moment(d?.start_date), end_date: moment(d?.end_date) })
    setSelectedEdu(d)
    setIsCSHchecked(d.study_currently)
  }

  const onDelete = async (id) => {
    try {
      await AuthActions.deleteEducation(id)
      updateBasicInfo(data.filter((d) => d.id !== id))
    } catch (e) {}
  }

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" align={'middle'}>
        <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
          🎓 Education
        </Typography.Title>
        <Space>
          <Button
            icon={<PlusCircleOutlined style={{ fontSize: 30 }} />}
            type="text"
            onClick={() => setIsModalOpen(true)}
          />
        </Space>
      </Row>
      {data?.length === 0 && (
        <Row justify="space-between" align={'middle'}>
          <Typography.Text type="secondary">No education provided</Typography.Text>
        </Row>
      )}
      <Space direction="vertical" style={{ width: '100%' }}>
        {data.map((d, key) => (
          <Row justify="space-between" align={'middle'} key={key}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
              <Row direction="horizantal" justify="space-between" size={0} align="middle">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {d?.school_name}
                </Typography.Title>
                <Space>
                  <Button
                    icon={<EditOutlined style={{ fontSize: 30 }} />}
                    type="text"
                    onClick={() => editEducation(d)}
                  />
                  <Popconfirm
                    title="Are you sure to delete this education?"
                    onConfirm={() => onDelete(d.id)}
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{ style: { height: 35 } }}
                    cancelButtonProps={{ style: { height: 35 } }}
                  >
                    <Button icon={<DeleteOutlined style={{ fontSize: 30 }} />} type="text" />
                  </Popconfirm>
                </Space>
              </Row>
              <Typography.Text type="secondary">
                {d?.degree_type}, {d?.major}
              </Typography.Text>
              <Typography.Text type="secondary">
                {moment(d.start_date).year()} - {d.study_currently ? 'Present' : moment(d.end_date).year()}
              </Typography.Text>
            </Space>
          </Row>
        ))}
      </Space>

      <Divider />

      <Modal
        title={selectedEdu ? 'Edit Education' : 'Add Education'}
        open={isModalOpen || selectedEdu}
        onCancel={() => {
          setIsModalOpen(false)
          setSelectedEdu(null)
          form.resetFields()
        }}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          name="educationForm"
          layout="vertical"
          onFinish={selectedEdu ? onUpdate : onSubmit}
          style={{ padding: 15 }}
        >
          <Form.Item
            name="school_name"
            label={'School Name'}
            required={false}
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>

          <Row gutter={20}>
            <Col span={24} lg={9}>
              <Form.Item
                name="degree_type"
                label={'Degree Type'}
                required={false}
                rules={[{ required: true, message: 'Degree type is required' }]}
              >
                <Select placeholder="Select Degree">
                  <Option value="Bachelors">Bachelors</Option>
                  <Option value="Masters">Masters</Option>
                  <Option value="Doctorate">Doctorate</Option>
                  <Option value="Certificate">Certificate</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} lg={9}>
              <Form.Item
                name="major"
                label={'Major'}
                required={false}
                rules={[{ required: true, message: 'Major is required' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} lg={3}>
              <Form.Item name="gpa" label={'GPA'} required={false}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} lg={3}>
              <Form.Item name="out_of" label={'Out of'} required={false}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={20}>
            <Col span={24} lg={9}>
              <Form.Item name="start_date" label={'Start Date'}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24} lg={9}>
              <Form.Item name="end_date" label={'End Date'}>
                <DatePicker style={{ width: '100%' }} disabled={isCSHchecked} />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ marginLeft: 20, marginBottom: 20 }}>
            <Checkbox
              checked={isCSHchecked}
              onChange={(e) => {
                setIsCSHchecked(e.target.checked)
              }}
            >
              I currently study here
            </Checkbox>
          </Row>

          <Form.Item name="description" label={'Description'}>
            <Input.TextArea rows={5} />
          </Form.Item>

          <Row justify="space-between" style={{ marginTop: 30 }} gutter={40}>
            <Col span={12}>
              <Button htmlType="submit" type="primary" block loading={isUpdaing}>
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button
                htmlType="button"
                type="ghost"
                block
                onClick={() => {
                  setIsModalOpen(false)
                  setSelectedEdu(null)
                  form.resetFields()
                }}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  )
}

export default Education
