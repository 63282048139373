import axios from 'axios'
import { message } from 'antd'

let isRefreshing = false
let subscribers = []

function onRefreshed(token) {
  subscribers.map((cb) => cb(token))
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}

// General API
const Api = axios.create({
  baseURL: process.env.API_URL || '/api',
  // baseURL: 'http://127.0.0.1:8000/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

Api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.message === 'Network Error') message.error('Network Error. ')

    return Promise.reject(error.response.data)
  }
)

function updateRequestConfig(config, accessToken) {
  config.headers.Authorization = `Bearer ${accessToken}`
  config.__isRetry = true
  return config
}

// API with token header
const TokenApi = axios.create({
  // baseURL: 'http://127.0.0.1:8000/api',
  baseURL: process.env.API_URL || '/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

TokenApi.interceptors.request.use(
  (config) => {
    if (!config.withoutAuth) {
      config.headers.Authorization = `Bearer ${window.localStorage.getItem('accessToken')}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error.response.data)
  }
)

TokenApi.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    if (error.response.status === 401 && window.localStorage.getItem('refreshToken')) {
      if (!isRefreshing) {
        isRefreshing = true
        Api.post('/login', {
          refreshToken: window.localStorage.getItem('refreshToken')
        })
          .then((res) => {
            isRefreshing = false

            if (res.accessToken && res.refreshToken && !res.isLoggedOut) {
              window.localStorage.setItem('accessToken', res.accessToken)
              window.localStorage.setItem('refreshToken', res.refreshToken)
              onRefreshed(res.accessToken)
            } else {
              onRefreshed(null)
            }
          })
          .catch((e) => {
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            window.location.replace('/login')
          })
      }

      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          resolve(TokenApi(updateRequestConfig(error.config, token)))
        })
      })
    }

    if (error.message === 'Network Error') message.error('Network Error')
    if (error.response.status === 403) message.error("You don't have permission for this request!")
    // if (error.response.status === 400) message.error(error.response.data || 'Sorry, somthing went wrong with API!')

    return Promise.reject(error.response.data)
  }
)

export { Api, TokenApi }
