import { Layout } from 'antd'
import Brands from '../home/Brands'
import Service from '../home/Service'
import React from 'react'
import Footer from '@/layouts/footer'

const PartnersPage = () => {
  return (
    <div className="inner-wrapper">
      <Service />
      <Brands />
      <Footer />
    </div>
  )
}

export default PartnersPage
