import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'

function AuthGuard({ children }) {
  const { pathname } = useLocation()
  const history = useHistory()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (auth?.userInfo?.data) {
      if (
        !(auth?.userInfo?.data?.interests?.length) &&
        pathname !== '/details'
      ) {
        return history.push('/details')
      }
      if (
        auth?.userInfo?.data?.interests?.length &&
        pathname === '/details'
      ) {
        return history.push('/library')
      }
    }
  }, [auth, pathname])

  if (!localStorage.getItem('accessToken')) {
    return <Redirect to={{ pathname: '/login', state: { prevPath: location.pathname } }} />
  }

  return children
}

AuthGuard.propTypes = {
  children: PropTypes.any
}

export default AuthGuard
