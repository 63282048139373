import axios from 'axios'
import React, { useState, useRef, useEffect } from 'react'
import { Card, Button, Divider, Row, Input, Space, Typography, Pagination, Tooltip, Tree } from 'antd'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  AudioOutlined,
  RedoOutlined,
  AudioMutedOutlined,
  EditOutlined,
  StopFilled
} from '@ant-design/icons'
import Countdown from './Countdown'
import './Questions.less'

const { Text } = Typography
const mimeType = 'audio/webm'
const OPENAI_API_KEY = process.env.OPENAI_API_KEY
const model = 'wishper-1'

const Questions = (props) => {
  const [permission, setPermission] = useState(false)
  const mediaRecorder = useRef(null)
  const [recordingStatus, setRecordingStatus] = useState('inactive')
  const [stream, setStream] = useState(null)
  const [audioChunks, setAudioChunks] = useState([])
  // const [audio, setAudio] = useState(null)
  const [isRunning, setIsRunning] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(0)
  const [showCountdown, setShowCountdown] = useState(false)

  // const questions = {
  //   1: 'Can you please tell me a bit about yourself?',
  //   2: "Imagine you've run into a challenge with an analysis and you aren't sure how to address it. What are some steps you might take to seek help?",
  //   3: 'How can a subquery help aggregate data?',
  //   4: 'It is possible to not have enough data to answer a question. Is it possible to have too much data?',
  //   5: 'Tell me about a time you made a mistake. How did you communicate that mistake?'
  // }

  const handleDec = () => {
    if (props.quesNum !== 1) {
      props.setQuesNum(props.quesNum - 1)
      props.setIsGPTResponded(true)
      // props.setIndex(3)
    }
  }

  useEffect(() => {
    getMicrophonePermission()
  }, [])

  useEffect(() => {
    let intervalId

    if (isRunning) {
      const startTime = Date.now() - elapsedTime
      intervalId = setInterval(() => {
        const currentTime = Date.now()
        const newElapsedTime = currentTime - startTime
        if (newElapsedTime >= 2 * 60 * 1000) {
          // Limit to 3 minutes (3 * 60 * 1000 milliseconds)
          stopRecording()
          setIsRunning(false)
          setElapsedTime(2 * 60 * 1000)
          clearInterval(intervalId)
        } else {
          setElapsedTime(newElapsedTime)
        }
      }, 10)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isRunning, elapsedTime])

  const handleStartStop = () => {
    setIsRunning((prevIsRunning) => !prevIsRunning)
  }

  const handleReset = () => {
    setIsRunning(false)
    setElapsedTime(0)
  }

  const minutes = Math.floor(elapsedTime / 60000)
  const seconds = ((elapsedTime % 60000) / 1000).toFixed(2)

  const startCountdown = () => {
    setShowCountdown(true)
    setTimeout(() => {
      startRecording()
      // setShowCountdown(false)
    }, 3000)
  }

  const handleCountdownComplete = () => {
    setShowCountdown(false)
  }

  // const fetchAnsTrans = async () => {
  //   if (!props.audio[props.quesNum - 1]) {
  //     return
  //   }

  //   const audioUrl = props.audio[props.quesNum - 1]
  //   const formData = new FormData()
  //   formData.append('model', model)
  //   formData.append('file', audioUrl)

  //   axios
  //     .post('https://api.openai.com/v1/audio/transcriptions', formData, {
  //       headers: {
  //         'content-type': 'multipart/form-data',
  //         Authorization: `Bearer ${OPENAI_API_KEY}`
  //       } 
  //     })
  //     .then((res) => {
  //       console.log(res.data)
  //       props.setAnsTrans(res.data)
  //     })
  //     .catch((err) => alert('error :', err))
  // }

  const getMicrophonePermission = async () => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        })
        setPermission(true)
        setStream(streamData)
      } catch (err) {
        alert(err.message)
      }
    } else {
      alert('The MediaRecorder API is not supported in your browser.')
    }
  }

  const startRecording = async () => {
    handleReset()
    handleStartStop()
    setRecordingStatus('recording')
    const media = new MediaRecorder(stream, { type: mimeType })
    mediaRecorder.current = media
    mediaRecorder.current.start()
    let localAudioChunks = []
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return
      if (event.data.size === 0) return
      localAudioChunks.push(event.data)
    }
    setAudioChunks(localAudioChunks)
  }
  const updateAudio = (index, value) => {
    const newArray = props.audio.slice()
    newArray[index] = value
    props.setAudio(newArray)
  }

  const stopRecording = () => {
    setRecordingStatus('inactive')
    //Even when we stop recording, the recording icon remains in our browser tab. We need to stop all available tracks on pressing Stop button.
    // stream.getTracks().forEach((t) => t.stop())
    mediaRecorder.current.stop()
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      const audioUrl = URL.createObjectURL(audioBlob)
      updateAudio(props.quesNum - 1, audioBlob)

      const questionKey = `audioUrls_question_${props.quesNum}`
      const existingAudioUrlsJSON = localStorage.getItem(questionKey)
      const existingAudioUrls = existingAudioUrlsJSON ? JSON.parse(existingAudioUrlsJSON) : []

      if (existingAudioUrls.length > 0) {
        existingAudioUrls[0] = audioUrl
      } else {
        existingAudioUrls.push(audioUrl)
      }
      // const newAudioUrls = [...existingAudioUrls, audioUrl];

      localStorage.setItem(questionKey, JSON.stringify(existingAudioUrls))
      setAudioChunks([])
    }
    handleStartStop()
  }

  return (
    <div style={{ width: '90vw', justifyContent: 'center' }} align="middle">
      {showCountdown && <Countdown onCountdownComplete={handleCountdownComplete} />}
      <div className="questions-card">
        <Card align="left">
          <Space direction="vertical" justify="start" align={'middle'} style={{ width: '100%' }}>
            <Row justify="space-between">
              <Text mark>Question {props.quesNum}</Text>
              <Text> {props.quesNum}/5</Text>
            </Row>
            <Row justify="start">
              <Text className="question">{props.questions[props.quesNum - 1]}</Text>
            </Row>
          </Space>
          <Divider />
          <Row justify="space-between" align={'middle'}>
            <Space>
              {!permission ? (
                <Tooltip title="Please allow microphone access" placement="bottom">
                  <Button
                    type="primary"
                    icon={<AudioOutlined />}
                    size="large"
                    style={{ paddingRight: 30, paddingLeft: 30 }}
                    className="btn_text"
                    onClick={getMicrophonePermission}
                  >
                    Get Permission
                  </Button>
                </Tooltip>
              ) : null}
              {permission && recordingStatus === 'inactive' ? (
                <Button
                  type="primary"
                  icon={props.audio[props.quesNum - 1] ? <RedoOutlined /> : <AudioOutlined />}
                  size="large"
                  style={{ paddingRight: 30, paddingLeft: 30 }}
                  className="btn_text"
                  onClick={() => {
                    startCountdown()
                  }}
                >
                  {props.audio[props.quesNum - 1] ? 'Retake' : 'Answer'}
                </Button>
              ) : null}
              {recordingStatus === 'recording' ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    backgroundColor: 'black',
                    padding: 15,
                    color: 'white',
                    borderRadius: 5,
                    cursor: 'pointer'
                  }}
                  onClick={stopRecording}
                >
                  <Button
                    type="primary"
                    size="small"
                    style={{
                      color: 'red',
                      backgroundColor: 'red',
                      width: 25,
                      height: 25,
                      marginInline: 10
                    }}
                  >
                    .
                  </Button>
                  <div>
                    {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(5, '0')}
                  </div>
                  {/* <Recorder /> */}
                </div>
              ) : null}
            </Space>
            <Space>
              {props.quesNum > 1 && (
                <Button
                  type="secondary"
                  icon={<ArrowLeftOutlined />}
                  size="large"
                  style={{ fontSize: 20 }}
                  onClick={() => handleDec()}
                />
              )}
              {props.quesNum <= 5 && (
                <Tooltip
                  title={
                    props.audio[props.quesNum - 1]
                      ? props.isGPTResponded
                        ? null
                        : 'Loading...'
                      : recordingStatus === 'recording'
                      ? 'Please stop recording'
                      : 'Please record your Answer'
                  }
                  placement="top"
                >
                  <Button
                    type="secondary"
                    disabled={props.audio[props.quesNum - 1] && props.isGPTResponded ? false : true}
                    icon={<ArrowRightOutlined />}
                    size="large"
                    style={{ fontSize: 20 }}
                    onClick={() => props.sendAudio()}
                  />
                </Tooltip>
              )}
            </Space>
          </Row>
        </Card>
        {/* <Row align="center">{audio ? <audio src={audio} controls></audio> : null}</Row>*/} {/* future feature */}
        <Row align="center" style={{ padding: 10 }}>
          <p style={{ color: 'gray', fontSize: 16 }}>
            * Keep your answers to less than <a href="https://applyin.notion.site/2-minutes-b2f362084a69466ebb3b154d896ad8fa?pvs=4" target="_blank" style={{ textDecoration: 'underline', color:"gray" }}>2 minutes</a>.
          </p>
        </Row>
      </div>
    </div>
  )
}

export default Questions
