import Footer from '@/layouts/footer'
import Header from '@/layouts/header'
import { Layout } from 'antd'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import Apply from './Apply'
import Brands from './Brands'
import HomeBanner from './HomeBanner'
import Works from './Works'
import React from 'react'
import './home.less'

const Home = () => {
  const auth = useSelector((state) => state.auth)

  const items = [
    {
      label: (
        <a
          rel="noreferrer"
          style={{ fontSize: 24 }}
          href="https://applyin.notion.site/ApplyIn-Blog-57900fa258ab40cd8e8fcec3994efc09?pvs=4"
          target="_blank"
        >
          Interview tips
        </a>
      ),
      key: '0'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <a
          rel="noreferrer"
          style={{ fontSize: 24 }}
          href="https://applyin.notion.site/Terms-and-Privacy-Policy-cb65ca863e6e418cb2f1f728a852a26c"
          target="_blank"
        >
          Terms & privacy
        </a>
      ),
      key: '1'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <a
          rel="noreferrer"
          style={{ fontSize: 24 }}
          href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
          target="_blank"
        >
          Microphone help
        </a>
      ),
      key: '3'
    }
  ]

  return (
    <Layout className="basic-layout">
      <div className="home-inner-wrapper">
        <Header />
        <Helmet>
          <title>ApplyIn: AI-Powered Behavioral Interview Preparation with ChatGPT</title>
          <meta
            name="description"
            property="og:description"
            content="Welcome to ApplyIn! Enhance your interview skills using ChatGPT's AI technology. Experience unlimited mock interview questions and get personalized feedback. Start your journey today!"
          />
        </Helmet>
        <div className="inner-wrapper">
          <HomeBanner />
          <Brands />
        </div>
        <Works />
        <Apply />
        <Footer />
      </div>
    </Layout>
  )
}

export default Home
