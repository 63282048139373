import React from 'react'
import { Card, Button, Row } from 'antd'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'

const maxScore = (key) => {
  if (key == 'Content and Understanding') return 40
  else if (key == 'Organization and Structure' || key == 'Language and Style') return 20
  else if (key == 'Grammar, Punctuation, and Spelling') return 10
  else if (key == 'Format and Presentation' || key == 'Engagement and Impact') return 5
  else return -1
}

const Score = ({ scoreKey, feedback, discard, index }) => {
  return (
    <>
      <Row justify={'center'} style={{ alignSelf: 'center' }}>
        <div id="question_card" className="question_card">
          <h5 style={{ fontSize: '15px', fontWeight: 'bold' }}>
            {scoreKey}: {feedback['task 2']['score'][scoreKey]}/{maxScore(scoreKey)}
          </h5>
          <p style={{ fontSize: '15px' }}>{feedback['task 2']['score']['Notes'][scoreKey]}</p>
          <div style={{ marginTop: '10px', display: 'flex' }}>
            <Button
              id="question_button"
              type="primary"
              style={{
                height: '35px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '15px'
              }}
              onClick={() => discard()}
            >
              Ignore
            </Button>
          </div>
        </div>
      </Row>
    </>
  )
}

Score.propTypes = {
  scoreKey: PropTypes.string,
  feedback: PropTypes.object,
  discard: PropTypes.func,
  index: PropTypes.number
}

export default Score
