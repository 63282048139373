import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PageStatus from '@/component/PageStatus'
import AuthActions from '@/redux/auth'
import { Button, Row, Input, Space, Typography, Tooltip } from 'antd'
import './Intro.less'
import ProgressBar from '@/pages/profile/sections/progressBar'

const { TextArea } = Input
const { Text } = Typography

const Intro = ({ sendJobDetails }) => {
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [profileName, setProfileName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [jobDesc, setJobDesc] = useState('')

  const dispatch = useDispatch()

  const fetchProfile = async () => {
    setLoading(true)
    try {
      const res = await dispatch(AuthActions.getCurrentUser())
      setUserId(res.data?.profile.id)
      setProfileName(res.data?.profile.first_name + ' ' + res.data?.profile.last_name)
      // console.log(res.data?.profile.first_name + res.data?.profile.last_name, '###')
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  if (loading) return <PageStatus type="loading" />

  return (
    <div style={{ width: '100%' }}>
      <Row justify={'center'} style={{ alignSelf: 'center', marginBottom: 30 }}>
        <ProgressBar />
      </Row>
      <Row justify={'center'} style={{ alignSelf: 'center', marginBottom: 30 }}>
        <Text strong type="primary" className="welcome-name-text">
          Welcome {profileName},
        </Text>
      </Row>
      <Space direction="vertical" style={{ marginLeft: '5%', marginRight: '5%', fontSize: 28 }}>
        <Text align="start" className="welcome-text">
          👋🏾 My name is Tara, and I am here to help you prepare for your upcoming interview.
        </Text>
        <Text className="welcome-text">
          We will work together to ensure that you feel confident and ready to present your best self during the
          interview.
        </Text>
        <Text className="welcome-text">
          When you are ready, click on the button below to start your mock interview! 😊
        </Text>
      </Space>
      <Space
        direction="vertical"
        style={{ width: '90%', backgroundColor: '#F6F5F4', margin: '5%', marginBottom: 20, marginTop: 20 }}
      >
        <Input
          placeholder="Enter title of job you are applying to (optional)"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
        <TextArea
          value={jobDesc}
          onChange={(e) => setJobDesc(e.target.value)}
          placeholder="Paste job description for the role you are applying to (optional)"
          autoSize={{
            minRows: 5,
            maxRows: 20
          }}
        />
      </Space>
      <Space direction="vertical" style={{ marginLeft: '5%', marginRight: '5%', fontSize: 18 }}>
        <Text style={{ color: '#555' }}>{`Character : ${jobDesc.length}/5000`}</Text>
      </Space>

      <Row justify={'center'} style={{ alignSelf: 'center' }}>
        {/* <Button
          type="primary"
          size="middle"
          disabled={jobTitle.length>300 || jobDesc.length>1000 ? true : false}
          style={{ backgroundColor: '#339723', marginTop: 30, paddingLeft: 50, paddingRight: 50, fontWeight: 600 }}
          onClick={() => sendJobDetails(jobTitle, jobDesc, userId)}
        >
          Let's Go
        </Button> */}
        <Tooltip
          title={
            jobDesc.length > 5000
              ? 'job description must be less then 5000 characters.'
              : jobTitle.length > 300
              ? 'job title must be less then 300 characters'
              : null
          }
          placement="top"
        >
          <Button
            type="primary"
            size="middle"
            disabled={jobTitle.length > 300 || jobDesc.length > 5000 ? true : false}
            style={{ marginTop: 30, paddingLeft: 50, paddingRight: 50 }}
            onClick={() => sendJobDetails(jobTitle, jobDesc, userId)}
          >
            Let's Go
          </Button>
        </Tooltip>
      </Row>
    </div>
  )
}

export default Intro
