import { Api, TokenApi } from '@/utils/api'

// POST jobTitle jobDescription
export const sendJobDetails = async (jobTitle, jobDescription, pk) => {
  try {
    // Send jobTitle and jobDescription to the backend to get questions
    const res = await TokenApi.post(`prep/session`, { job_title: jobTitle, job_description: jobDescription, user: pk })
    // console.log(res)
    return res
  } catch (error) {
    console.error('Error:', error)
    alert('Send proper details', error)
  }
}

// export const sendAudio = async (sessionId, qusNum, audioFile) => {
//   try {
//     // Send session ID, question number and audio file to the backend for translation and feedback
//     console.log({audioFile})
//     console.log("que number",qusNum)
//     const res = await TokenApi.post(
//       `prep/user_answers/${sessionId}/`,
//       {
//         param: {
//           qusNum: qusNum
//         }
//       },
//       { audioFile }
//     )
//     return res
//   } catch (error) {
//     console.error(error)
//     alert(error)
//   }
// }
export const sendAudio = async (sessionId, qusNum, audioFile) => {
  try {
    const formData = new FormData()
    // console.log(audioFile)
    const res = await TokenApi.post(
      `prep/user_answers/${sessionId}/`,
      {
        param: {
          qusNum: qusNum
        },
        // audioFile
        formData: {
          audioFile: { audioFile }
        }
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    // console.log('feedback:', res)
    return res
  } catch (error) {
    alert(error)
  }
}

export const sendQuesAudio = async (question, audioFile) => {
  try {
    const formData = new FormData()
    // console.log(audioFile)
    const res = await TokenApi.post(
      `prep/question_answer/`,
      {
        param: {
          qusNum: question
        },
        // audioFile
        formData: {
          audioFile: { audioFile }
        }
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    // console.log('feedback:', res)
    return res
  } catch (error) {
    alert(error)
  }
}

export const fetchQuestionBank = async (payload) => {
  try {
    if (
      payload.questionType === '' &&
      payload.company === '' &&
      payload.difficulty === '' &&
      payload.tags === '' &&
      payload.careerStage === '' &&
      payload.role ===''
    ) {
      const res = await TokenApi.get(`prep/questions/?offset=${payload.offset}`)
      return res
    } else {

      const queryParams = `?qtype=${payload.questionType}&company=${payload.company}&difficulty=${payload.difficulty}&tags=${payload.tags}&careerstage=${payload.careerStage}&role=${payload.role}&offset=${payload.offset}`
      const res = await TokenApi.get(`prep/questions/${queryParams}`)
      return res
    }
  } catch (error) {
    alert('Error fetching Questions: ' + error.message)
    throw error
  }
}

export const fetchOptions = async () => {
  try {
    const res = await TokenApi.get('/prep/tableschema/')
    return res
  } catch (error) {
    alert('Error fetching Options: ' + error.message)
    throw error
  }
}

export const sendFeedback = async (sessionId, session_type, feedback, isThumbsUp) => {
  try {
    const data = {
      session_type: session_type,
      session_id: sessionId,
      feedback,
      thumbsup: isThumbsUp
    }
    // Send jobTitle and jobDescription to the backend to get questions
    const res = await TokenApi.post(`prep/feedback/`, data)
    return res
  } catch (error) {
    alert('Error sending feedback: ' + error.message)
    throw error
  }
}


export const fetchInterviewReadinessScore = async () => {
  try {
    const res = await TokenApi.get(`prep/interview_readiness/`);
    return res;
  } catch (error) {
    alert('Error fetching interview readiness score: ' + error.message);
    throw error;
  }
};
