import React, { useState } from 'react'
import './clap.less'
import { ClapIcon } from '@/icon'
import { useEffect } from 'react'
import { TokenApi } from '@/utils/api'
import { useHistory } from 'react-router-dom'

const ClapButton = ({ applauseCount = 0, storyId, applauseToast }) => {
  const [accCounter, setAccCounter] = useState(0)
  const [totalCount, setTotalCount] = useState(applauseCount)
  const history = useHistory()

  useEffect(() => {
    if (!totalCount) {
      setTotalCount(applauseCount)
    }
  }, [applauseCount])
  const minDeg = 1
  const maxDeg = 72
  const particlesClasses = [
    {
      class: 'pop-top'
    },
    {
      class: 'pop-top-left'
    },
    {
      class: 'pop-top-right'
    },
    {
      class: 'pop-bottom-right'
    },
    {
      class: 'pop-bottom-left'
    }
  ]

  const handleClap = async () => {
    try {
      const res = await TokenApi.post(`story-activity-applause/${storyId}/`, {
        applause_count: 1
      })

      if (res.can_applause) {
        setTotalCount(res.applause_count)
        setAccCounter(accCounter + 1)

        const clickCounter = document.getElementById('clicker')
        const particles = document.getElementById('particles')
        const particles2 = document.getElementById('particles-2')
        const particles3 = document.getElementById('particles-3')

        runAnimationCycle(clickCounter, 'active')
        runAnimationCycle(document.getElementById('totalCounter'), 'fader')

        const clap = document.getElementById('clap')
        clap.classList.add('clicked')
        runAnimationCycle(clap, 'scale')

        if (!particles.classList.contains('animating')) {
          animateParticles(particles, 700)
        } else if (!particles2.classList.contains('animating')) {
          animateParticles(particles2, 700)
        } else if (!particles3.classList.contains('animating')) {
          animateParticles(particles3, 700)
        }
      } else {
        applauseToast(true)

        setTimeout(() => {
          applauseToast(false)
        }, 3000)
      }
    } catch (err) {
      if (err.code === 'token_not_valid') {
        history.push('/login')
      }
    }
  }

  const runAnimationCycle = (el, className) => {
    if (el && !el.classList.contains(className)) {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
      void el.offsetWidth
      el.classList.add(className)
    }
  }

  const runParticleAnimationCycle = (el, className, duration) => {
    if (el && !el.classList.contains(className)) {
      el.classList.add(className)
      setTimeout(() => {
        el.classList.remove(className)
      }, duration)
    }
  }

  const animateParticles = (particles, dur) => {
    addRandomParticlesRotation(particles.id, minDeg, maxDeg)
    for (let i = 0; i < particlesClasses.length; i++) {
      runParticleAnimationCycle(particles.children[i], particlesClasses[i].class, dur)
    }
    particles.classList.add('animating')
    setTimeout(() => {
      particles.classList.remove('animating')
    }, dur)
  }

  const addRandomParticlesRotation = (particlesName, minDeg, maxDeg) => {
    const particles = document.getElementById(particlesName)
    const randomRotationAngle = getRandomInt(minDeg, maxDeg) + 'deg'
    particles.style.transform = `rotate(${randomRotationAngle})`
  }

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
      <div className="canvas">
        <div id="clap" className="clap-container" onClick={handleClap}>
          <ClapIcon />
        </div>
        <div id="clicker" className="click-counter">
          <span>+{accCounter}</span>
        </div>
        <div id="sonar-clap" className="clap-container-sonar"></div>
        <div id="particles" className="particles-container">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="triangle">
              <div className="square"></div>
            </div>
          ))}
        </div>
        <div id="particles-2" className="particles-container">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="triangle">
              <div className="square"></div>
            </div>
          ))}
        </div>
        <div id="particles-3" className="particles-container">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="triangle">
              <div className="square"></div>
            </div>
          ))}
        </div>
      </div>
      <div id="totalCounter" className="total-counter">
        {totalCount}
      </div>
    </div>
  )
}

export default ClapButton
