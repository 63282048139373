import React, { useEffect, useState } from 'react'
import { Button, Divider, Tooltip } from 'antd'
import './prepBtn.less'

const PrepBtn = ({ data }) => {
  const [isBtn, setIsBtn] = useState(true)
  const [emptyField, setEmptyField] = useState('Please fill all the profile details to start interview')

  const validateData = () => {
    if (
      !data.profile ||
      !data.education ||
      !data.work_experience ||
      data.education.length === 0 ||
      data.work_experience.length === 0
    ) {
      // console.log(emptyField)
      setIsBtn(false) // If any condition fails, set isBtn to false
      return
    }

    // Check profile fields for null or empty values (excluding specific fields)
    const excludedFields = [
      'linkedln_url',
      'twitter_url',
      'website_url',
      'github_url',
      'gender',
      'lgbtq',
      'disability',
      'veteran',
      'race'
    ]
    for (const key in data.profile) {
      if (!excludedFields.includes(key) && (data.profile[key] === null || data.profile[key] === '')) {
        setEmptyField('Please fill all the profile details to start interview :' + ' ' + key)
        setIsBtn(false)
        return
      }
    }

    // Check education fields for null or empty values
    for (const educationItem of data.education) {
      for (const key in educationItem) {
        if (educationItem[key] === null || educationItem[key] === '') {
          setEmptyField('Please fill all the education details to start interview :' + ' ' + key)
          setIsBtn(false)
          return
        }
      }
    }

    // Check work_experience fields for null or empty values
    // for (const workItem of data.work_experience) {
    //   for (const key in workItem) {
    //     if (workItem[key] === null || workItem[key] === '') {
    //       setEmptyField('Please fill all the work Experince details to start interview :' + ' ' + key)
    //       setIsBtn(false)
    //       return
    //     }
    //   }
    // }
  }

  useEffect(() => {
    validateData()
  }, [])

  return (
    <div className="prep-div" align={'middle'}>
      {/* {!isBtn ? (
        <Tooltip title={emptyField} placement="top">
          <Button htmlType="submit" type="primary" disabled>
            Interview Prep ➤
          </Button>
        </Tooltip>
      ) : ( */}
      <Button
        href="/interview-type"
        type="primary"
        style={{ backgroundColor: '#339723', fontWeight: 'bold', border: 0 }}
      >
        Interview Prep ➤
      </Button>
      {/* )} */}
      {/* <Divider /> */}
    </div>
  )
}
export default PrepBtn
