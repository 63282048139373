import React, { useState } from 'react'
import AuthActions from '@/redux/auth'
import { Button, Divider, Modal, Row, Space, Typography, Col, Upload, message } from 'antd'
import { EditOutlined, UploadOutlined, FilePdfOutlined } from '@ant-design/icons'
import { TokenApi } from '@/utils/api'

const Uploads = ({ data = null, updateBasicInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fileList, setFileList] = useState([])
  const [url, setUrl] = useState(null)
  const [isUpdaing, setIsUpdating] = useState(false)

  const props = {
    name: 'file',
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])

      return false
    },
    fileList
  }

  const saveResume = async () => {
    const formData = new FormData()
    if (!fileList.length) {
      message.success('No changes made')
      setIsModalOpen(false)
      return
    }

    fileList.forEach((file) => {
      formData.append('file', file)
    })
    formData.append('profile', data.id)
    setIsUpdating(true)
    // You can use any AJAX library you like
    TokenApi.post('upload', formData)
      .then((res) => {
        updateBasicInfo({ resume: res.resume, resume_uploaded: res.resume_uploaded })
        setIsUpdating(false)
        setIsModalOpen(false)
        setFileList([])
        message.success('Resume saved successfully.')
      })
      .catch(() => {
        message.error('Upload failed.')
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" align={'middle'}>
        <Typography.Title level={4} style={{ fontWeight: 'normal' }}>
          📎 Uploads
        </Typography.Title>
      </Row>

      <Row justify="space-between" align={'middle'}>
        <Space direction="vertical" size={0} style={{ width: '100%' }}>
          <Row direction="horizantal" justify="space-between" size={0} align="middle">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              Resume
            </Typography.Title>
            <Button icon={<EditOutlined style={{ fontSize: 30 }} />} type="text" onClick={() => setIsModalOpen(true)} />
          </Row>
          {data?.resume_uploaded ? (
            <Typography.Text type="secondary">
              Last updated {new Date(data.resume_uploaded).toUTCString()}
            </Typography.Text>
          ) : (
            <Typography.Text type="secondary">No education details provided</Typography.Text>
          )}
        </Space>
      </Row>

      <Divider />

      <Modal title="Upload Resume" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={null} width={800}>
        <Upload.Dragger {...props} style={{ paddingTop: 50, paddingBottom: 50 }}>
          {data.resume ? (
            <Row justify={'center'}>
              <div>
                <FilePdfOutlined style={{ fontSize: 100 }} />
                <p>{data.resume.split('/')[2]}</p>
              </div>
            </Row>
          ) : (
            <>
              <Row justify={'center'}>
                <UploadOutlined style={{ fontSize: 50, marginBottom: 20 }} />
              </Row>
              <p>Upload resume in PDF, DOC or DOCX format</p>
            </>
          )}
        </Upload.Dragger>
        <Row justify="space-between" style={{ marginTop: 30 }} gutter={40}>
          <Col span={12}>
            <Button htmlType="submit" type="primary" block onClick={saveResume} loading={isUpdaing}>
              Save
            </Button>
          </Col>
          <Col span={12}>
            <Button htmlType="button" type="ghost" block onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default Uploads
