import React, { useState, useEffect } from 'react';
import { Empty, Spin } from 'antd';

const PageStatus = ({ type, displayMessage }) => {
  const [message, setMessage] = useState('Analysing your response...');

  useEffect(() => {
    const advancedAnalysisTimeout = setTimeout(() => {
      setMessage('Grading your answer...');
    }, 15000);

    const nearCompletionTimeout = setTimeout(() => {
      setMessage('Almost there! Finalizing our insights for you...');
    }, 30000);

    const errorTimeout = setTimeout(() => {
      setMessage("We're experiencing a delay. Please bear with us or try again later.");
    }, 90000);

    return () => {
      clearTimeout(advancedAnalysisTimeout);
      clearTimeout(nearCompletionTimeout);
      clearTimeout(errorTimeout);
    };
  }, []);

  return (
    <>
      {displayMessage ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 190px)',
          }}
        >
          {message && <div style={{ marginBottom: '10px' }}>{message}</div>}
          {type === 'loading' && (
            <div style={{ marginTop: message ? '10px' : '0' }}>
              <Spin tip="Loading..." />
            </div>
          )}
          {type === 'empty' && (
            <div style={{ marginTop: message ? '10px' : '0' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 190px)',
          }}
        >
          {type === 'loading' && <Spin tip="Loading..." />}
          {type === 'empty' && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
      )}
    </>
  );
};

export default PageStatus;
