import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PageStatus from '@/component/PageStatus'
import AuthActions from '@/redux/auth'
import BasicInfo from './sections/basic'
import Education from './sections/education'
import WorkExperience from './sections/workExperience'
import Uploads from './sections/uploads'
import Links from './sections/links'
import Skills from './sections/skills'
import PrepBtn from './sections/prepBtn'
import { Divider, Space, Typography } from 'antd'
import '@/base.less'
import { Helmet } from 'react-helmet'
import ProgressBar from './sections/progressBar'

const Profile = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [data, setData] = useState(null)
  const [profile, setProfile] = useState(null)
  const [education, setEducation] = useState([])
  const [workExperience, setWorkExperience] = useState([])


  const fetchProfile = async () => {
    setLoading(true)
    try {
      const res = await dispatch(AuthActions.getCurrentUser())
      setData(res.data)
      setProfile(res.data?.profile)
      setEducation(res.data?.education)
      setWorkExperience(res.data?.work_experience)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  const updateProfile = async (d) => {
    setProfile({ ...profile, ...d })
    await fetchProfile() //fetch the data, each time the data gets updated
  }

  const updateWorkExperience = async (d) => {
    setWorkExperience(d)
    await fetchProfile() //fetch the data, each time the data gets updated
  }

  const updateEducation = async (d) => {
    setEducation(d)
    await fetchProfile() //fetch the data, each time the data gets updated
  }

  if (loading) return <PageStatus type="loading" />
  if (!loading && !data) return <PageStatus type="empty" />

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ProgressBar />
      <Helmet>
        <title>Your ApplyIn Profile: Personalize Your Interview Practice Experience</title>
        <meta
          name="description"
          content="View and edit your profile on ApplyIn. Tailor your interview preparation journey with ChatGPT to match your career goals and ambitions."
        />
      </Helmet>
      {/* <PrepBtn data={data} /> */}
      <BasicInfo data={profile} updateBasicInfo={(updatedData) => updateProfile(updatedData)} />
      <Education data={education} updateBasicInfo={(updatedData) => updateEducation(updatedData)} profile={profile} />
      <WorkExperience
        data={workExperience}
        updateBasicInfo={(updatedData) => updateWorkExperience(updatedData)}
        profile={profile}
      />
      <Uploads data={profile} updateBasicInfo={(updatedData) => updateProfile(updatedData)} />
      <Links data={profile} updateBasicInfo={(updatedData) => updateProfile(updatedData)} />
      <Skills data={profile} updateBasicInfo={(updatedData) => updateProfile(updatedData)} />
    </div>
  )
}

export default Profile
