import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Row, Typography, Button, Space, Modal, Spin, Tabs, Tooltip, Progress } from 'antd'
import { CloseOutlined, DownOutlined, LoadingOutlined, UpOutlined } from '@ant-design/icons'
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import axios from 'axios';
import { TokenApi } from '@/utils/api'
import Spellcheck from './Spellcheck'
import Questions from './Questions'
import Header from '@/layouts/header'
import { useDispatch, useSelector } from 'react-redux'
import StoryActions from '@/redux/story'
import { useHistory, useLocation } from 'react-router-dom'
import { Carousel } from 'antd'
import ShareToolTip from '../shareTooltip'
import { ShareIcon } from '@/icon'
import Toast from '../components/Toast'
//template component import for author based feedback UI
import Template from '../components/Template';
import { getCurrentUser } from '../../../redux/auth/actions';



import Tips from './Tips'
import { Helmet } from 'react-helmet'
import Score from './Score'
import achievementImage from '../../../assets/icons/achievement.svg'
import Confetti from 'react-confetti'
import './write.less' // Ensure this import is present
import scottFitzgeraldPic from '../../../assets/images/scott-fitzgerald.png'; // Import the image

// import image matching 
import { authorMapper } from './authors';
import AuthActions from '@/redux/auth'

const { Text, Title } = Typography

const { TabPane } = Tabs
let removeClass = true
const Write = () => {

  // for writing assistant
  const authorInformation = useRef({})

  const quillRef = useRef(null)
  const storyId = new URLSearchParams(useLocation().search).get('storyId')


  // for emails
  const location = useLocation();

  const buttonTexts = ['Plot Twists', 'Enhance'];
  const [loading, setLoading] = useState(true);

  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState(null);
  const [plotTwists, setPlotTwists] = useState([]);
  // const [characterDevelopment, setCharacterDevelopment] = useState([]);
  const [storyEnhancements, setStoryEnhancements] = useState([]);
  const [selectedEnhancement, setSelectedEnhancement] = useState(null);
  const [selectedEnhancementType, setSelectedEnhancementType] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isContentSet, setIsContentSet] = useState(false);

  // Function to handle feedback evaluation
  const history = useHistory()
  const editorRef = useRef()
  const [value, setValue] = useState('')
  const [textContent, setTextContent] = useState('')
  const [submitted, setsubmitted] = useState(false)
  const [title, setTitle] = useState('')
  const [customTitle, setCustomTitle] = useState('Untitled');
  const [createOwnTitle, setCreateOwnTitle] = useState(false);
  const [showFirstMessage, setShowFirstMessage] = useState(true);
  const [showSecondMessage, setShowSecondMessage] = useState(false);
  const [userName, setUserName] = useState('');
  const [loadingg, setLoadingg] = useState(true); // To manage loading state
  const [errorr, setErrorr] = useState(null); // To manage errors



  const [spelling, setspelling] = useState([])
  const [questions, setquestions] = useState([])
  const [scores, setScores] = useState([])
  const [feedbacks, setFeedbacks] = useState(null)
  const [airtablePrompt, setAirtablePrompt] = useState('')
  const [placeholder, setPlaceholder] = useState('');



  const [tips, settips] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const [spellingSpin, setSpellingSpin] = useState(false)
  const [feedbackSpin, setFeedbackSpin] = useState(false)

  const [imgGenLoading, setImgGenLoading] = useState(false)
  const [error, seterror] = useState(false)
  const [isChecked, setisChecked] = useState(false)
  const [close, setclose] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const dispatch = useDispatch()

  const [isImageUploading, setIsImageUploading] = useState(false)
  const [imagePrompt, setImagePrompt] = useState('')
  const [isImgDialogOpen, setIsImgDialogOpen] = useState(false)

  const [isToastOn, setIsToastOn] = useState(false)
  const [isGenImgToastOn, setIsGenImgToastOn] = useState(false)
  const [isCopyPasteToastOn, setIsCopyPasteToastOn] = useState(false)
  const [isCutToastOn, setIsCutToastOn] = useState(false)
  const [isSpellToastOn, setIsSpellToastOn] = useState(false)
  const [isTitleSaveOn, setIsTitleSaveOn] = useState(false)

  const [isImageUnlocked, setIsImageUnlocked] = useState(false)

  // feedback text and toast message state management
  //const [isFeedbackToastOn, setIsFeedbackToastOn] = useState(false);
  //const [feedbackText, setFeedbackText] = useState('');

  // state management for the modified UX component
  // feedback text and toast message state management
  const [isFeedbackToastOn, setIsFeedbackToastOn] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');

  // state management for author details, we can use a default author picture if required , 
  // for now I am using None Author and the picture is of Scott only.
  const [authorName, setAuthorName] = useState('None Author');
  const [authorPicture, setAuthorPicture] = useState(scottFitzgeraldPic);

  // State management for toast messages
  const [activeToasts, setActiveToasts] = useState([]);

  // const generateFeedbackDebounced = useRef(debounce(generateFeedback, 3000)).current;
  const timeoutId = useRef(null);

  // ref for checking the words added
  const prevWordCountRef = useRef(0);
  const addedWordsRef = useRef(0);
  const currentWordsRef = useRef(0);
  const assistabcountRef = useRef(0);

  const isMountedRefWords = useRef(true);

  const [activeKey, setActiveKey] = useState(null)
  const [canSubmit, setCanSubmit] = useState(false)
  const [canGenerateImg, setCanGenerateImg] = useState(false)
  const [visible, setVisible] = useState(false)
  const [currentTextSuggestion, setCurrentTextSuggestion] = useState()
  const [totalFeedbackScore, setTotalFeedbackScore] = useState(0)
  const [previousWordCount, setPreviousWordCount] = useState(0)

  // use to show / hide the toolbar
  const [showToolbar, setShowToolbar] = useState(true);


  const icons = ReactQuill.Quill.import('ui/icons')

  icons['undo'] = `<Tooltip title="Undo" className="tooltip-icon"><svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg></Tooltip>`

  icons['redo'] = `<Tooltip title="Redo" className="tooltip-icon"><svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg></Tooltip>`

  icons[
    'header'
  ][0] = `<Tooltip title="Normal Text" className="tooltip-icon"><svg viewBox="0 0 50 18" xmlns="http://www.w3.org/2000/svg">
<text y="16" font-family="Arial" font-size="16" fill="black">Text</text>
</svg></Tooltip>`

  icons['header'][2] = `<Tooltip title="Header" className="tooltip-icon"><svg viewBox="0 0 30 18">
  <text x="2" y="16" font-family="Arial" font-size="16" fill="black">H1</text>
  </svg></Tooltip>`

  const [isActiveMessageOn, setIsActiveMessageOn] = useState(false);


  const buttonRef = useRef(null);


  ///////Create own Title/////////
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const storyId = params.get('storyId');
    const createOwn = params.get('createOwnTitle', 'false');

    console.log("the location params are : ", params)
    console.log(storyId); // 166
    console.log(createOwn); // true

    if (createOwn === 'true') {
      console.log("have to create your own title")
      setCreateOwnTitle(true);
      //console.log("create own title : ", createOwnTitle)

      //setTitle('');
    } else if (storyId && createOwn === 'false') {
      console.log("selected title")
      setCreateOwnTitle(false);


      // Fetch the story details based on the storyId if needed
      // For example:
      // fetchStoryDetails(storyId).then(story => {
      //   setTitle(story.title);
      //   setAirtablePrompt(story.prompt);
      // });
    }
  }, [location.search]);

  useEffect(() => {
    if (createOwnTitle) {
      setCustomTitle('Untitled');
    }
  }, [createOwnTitle]);


  ////////////////////////////////////////////////

  /////////////// email sending /////////////////////

  //////// generate feedback frontend ///////////

  // useEffect to handle keypress event
  useEffect(() => {
    // Set the flag to true when component mounts
    isMountedRefWords.current = true;
    console.log("the states prev , state after additon , current words counted, assist tab counter : ", prevWordCountRef.current,
      " , ", addedWordsRef.current, " , ", currentWordsRef.current, ",", assistabcountRef.current);

    // Function to calculate validity
    const validity = async (e) => {
      let words;

      if (!textContent.trim()) {
        words = 0;
      } else {
        words = textContent.split(/\s+/).length;
      }


      //console.log("added : ", addedWords);
      // console.log("*****************************************");
      addedWordsRef.current = words;
      if (addedWordsRef.current - prevWordCountRef.current < 0 || addedWordsRef.current - prevWordCountRef.current > 25) {
        let newBaseLine = addedWordsRef.current;
        // console.log("adjusting deletion and refresh case : ", newBaseLine);
        addedWordsRef.current = newBaseLine;
        prevWordCountRef.current = newBaseLine;
      }

      // console.log("added words will be : ", addedWordsRef.current);
      // console.log("now the prev words are : ", prevWordCountRef.current);


      currentWordsRef.current += (addedWordsRef.current - prevWordCountRef.current);
      // console.log("current words now are : ", currentWordsRef.current);

      prevWordCountRef.current = addedWordsRef.current;
      // console.log("updated prev words will be : ", prevWordCountRef.current);
      // console.log("*****************************************");

      //console.log("the number of words are : ", words);

      if (currentWordsRef.current === 25 && e.code !== 'Space') {
        // console.log("space pressed and reached 25 added words...");
        currentWordsRef.current = 0;
        assistabcountRef.current += 25;
        if (isMountedRefWords.current) {
          await generateFeedback()

        }
      }

      //console.log("author info : ", authorInformation.current)

    };

    const handleKeyPress = async (e) => {
      await validity(e);
    };

    window.addEventListener('keypress', handleKeyPress);

    // Cleanup function to set the flag to false when component unmounts
    return () => {
      isMountedRefWords.current = false;
      window.removeEventListener('keypress', handleKeyPress);
    };
  }, [textContent, generateFeedback]);

  // const splitMessage = (message) => {
  //   // Use a regular expression with capturing groups to keep punctuation marks
  //   const lines = message.split(/(?<=[.!?])/).filter(line => line.trim() !== '');
  //   const parts = [];

  //   for (let i = 0; i < lines.length; i++) {
  //     parts.push(lines[i].trim());
  //     // Add "..." between lines except after the last line
  //     if (i < lines.length - 1) {
  //       parts.push('...');
  //     }
  //   }

  //   console.log('partz', parts);
  //   return parts;
  // };
  const splitMessage = (message) => {
    // Use a regular expression with capturing groups to keep punctuation marks
    const lines = message.split(/([.!?])\s*/).filter(line => line.trim() !== '');
    const parts = [];
  
    for (let i = 0; i < lines.length; i += 2) {  // Increment by 2 since we're capturing punctuation as well
      let sentence = lines[i].trim();
      
      // Append the punctuation from the next index if it exists
      if (lines[i + 1]) {
        sentence += lines[i + 1];
      }
  
      parts.push(sentence);
  
      // Add "..." between lines except after the last line
      if (i < lines.length - 2) {
        parts.push('...');
      }
    }
  
    return parts;
  };



  const generateFeedback = async () => {
    console.log("new text passed for api call ...");

    const api_call = async () => {
      try {
        const response = await TokenApi.post('/generate-feedback', {
          user_text: textContent,
        });

        console.log('the api response is ... ', response);
        const feedback = response.message;
        const authorName = authorInformation.current.selected_author_name;
        const authorPicture = authorMapper[authorInformation.current.selected_author_name][0];

        // Split the message into parts with 5 words each
        const parts = splitMessage(feedback);

        const toastQueue = parts.map((part, index) => ({
          id: Date.now() + index,
          message: part,
          authorName,
          authorPicture,
          isOn: false,
          isOnTranslate: 320 + ((index - 1) * 130),
          isOffTranslate: -100,
          isOnDisplayAuthor: index === 0,
          widthAdjustment: 0,
        }));

        console.log("queue status currently : ", toastQueue);
        console.log("The messages will start showing now...");

        setShowToolbar(false);
        if (editorRef.current) {
          editorRef.current.getEditor().focus();
        }

        await processQueue(toastQueue);

        console.log("promise resolved and the messages are complete ...");
        console.log("The messages are complete and shown now.");
        setShowToolbar(true);
        if (editorRef.current) {
          console.log(editorRef.current.getEditor());
          editorRef.current.getEditor().focus();
        }
      } catch (error) {
        console.log(error);
      }
    };

    clearTimeout(timeoutId.current);
    console.log("api called and we cleared :  ", timeoutId.current);
    timeoutId.current = setTimeout(async () => {
      await api_call();
    }, 150);
  };


  const processQueue = (toastQueue) => {
    return new Promise((resolve) => {
      const displayDuration = 2000; // Duration to display each line
      const delayBetweenLines = 10000; // Delay between lines
      let activeTimeouts = 0;

      const displayLine = () => {
        if (toastQueue.length === 0 && activeTimeouts === 0) {
          console.log("All lines processed. Resolving.");
          setTimeout(()=> setActiveToasts([]), 5000);
          resolve();
          return;
        }

        // Get the next line from the queue
        const lineToDisplay = toastQueue.shift();

        if (lineToDisplay) {
          // Ensure the container is cleared or updated for the new line
          setActiveToasts([lineToDisplay]);

          // Set active status for the line
          setActiveToasts((prevLines) =>
            prevLines.map((l) =>
              l.id === lineToDisplay.id ? { ...l, isOn: true } : l
            )
          );

          // Increment activeTimeouts counter
          activeTimeouts++;

          // Hide the line after displayDuration
          setTimeout(() => {
            setActiveToasts((prevLines) =>
              prevLines.map((l) =>
                l.id === lineToDisplay.id ? { ...l, isOn: false } : l
              )
            );

            // Decrement activeTimeouts counter
            activeTimeouts--;

            // Check if all lines have been processed
            if (toastQueue.length === 0 && activeTimeouts === 0) {
              console.log("All lines processed. Resolving.");
              setTimeout(()=> setActiveToasts([]), 50000);
              resolve();
            }
          }, displayDuration);

          console.log("Displaying line:", lineToDisplay);

          // Delay before displaying the next line
          setTimeout(displayLine, delayBetweenLines);
        }
      };

      console.log("Starting to display lines...");
      displayLine(); // Start the display process
    });
  };


  useEffect(() => {
    if (isActiveMessageOn) {
      setShowToolbar(false); // Hide toolbar when isActiveMessageOn is true
    } else {
      setShowToolbar(true); // Show toolbar when isActiveMessageOn is false
    }
  }, [isActiveMessageOn]);


  // useEffect(() => {
  //   // Assuming fetchUserName is an action that fetches and returns the user name
  //   dispatch(AuthActions.fetchUserName())
  //     .then(response => {
  //       if (response && response.data) {
  //         setUserName(response.data.userName); // Assuming the API returns the user name in response.data.userName
  //       }
  //     })
  //     .catch(error => {
  //       console.error('Failed to fetch user name:', error);
  //     });
  // }, [dispatch]);



  // useEffect(() => {
  //   const timer1 = setTimeout(() => {
  //     setShowFirstMessage(false);
  //     setShowSecondMessage(true);
  //   }, 3000); // Adjust the duration (in milliseconds) to control how long the first message appears

  //   return () => clearTimeout(timer1);
  // }, []);
  useEffect(() => {
    const screenWidth = window.innerWidth;
  
    if (screenWidth < 768) {
      setShowFirstMessage(false);
      setShowSecondMessage(true); 
    } else {
      // For larger screens, apply the original logic
      const timer1 = setTimeout(() => {
        setShowFirstMessage(false);
        setShowSecondMessage(true);
      }, 3000); // Adjust the duration (in milliseconds) for larger screens
  
      return () => clearTimeout(timer1);
    }
  }, []);
  
  //////// generate feedback frontend ///////////


  const BlockEmbed = Quill.import('blots/block/embed');
  class ReadOnlyBlot extends BlockEmbed {
    static create(value) {
      let node = super.create(value);
      node.setAttribute('contenteditable', 'false');
      node.innerHTML = value;
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }
  }
  // ReadOnlyBlot.blotName = 'readOnly';
  // ReadOnlyBlot.tagName = 'div';
  // Quill.register(ReadOnlyBlot);

  useEffect(() => {
    if (!isLoading && editorRef.current && !isContentSet) {
      const editor = editorRef.current.getEditor();
      console.log("Editor instance on initial load:", editor);

      const delta = [];

      if (createOwnTitle) {
        delta.push({ insert: 'Untitled\n', attributes: { header: 1 } });
      } else if (title) {
        delta.push({
          insert: `${title}\n`,
          attributes: { header: 1 }
        });
      }
      delta.push({ insert: '\n' });

      if (airtablePrompt && !createOwnTitle) {
        delta.push({
          insert: airtablePrompt,
          attributes: {
            italic: true,
            color: 'grey'
          }
        });
        delta.push({ insert: '\n\n' });
      }

      if (textContent) {
        delta.push({ insert: textContent });
      }

      editor.setContents(delta);
      setIsContentSet(true); // Mark content as set
    }
  }, [isLoading, title, airtablePrompt, textContent, createOwnTitle, isContentSet]);



  // console.log(textContent);

  // console.log('isLoading:', isLoading); // Log the current state of isLoading


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const fetchStoryEnhancements = async (
    type,
    setPlotTwists,
    // setCharacterDevelopment,
    setStoryEnhancements,
    setLoadingFeedback,
    setErrorFeedback,
    storyId,
    isMountedRef
  ) => {
    if (!storyId) {
      console.warn('No storyId provided');
      return;
    }

    try {
      setLoadingFeedback(true);
      setIsDataLoading(true); // Show the spinner
      setErrorFeedback(null);

      const res = await TokenApi.get(`/story-builder/${storyId}/?enhancement_type=${type}`);
      console.log(`API response for ${type}:`, res);

      if (res) {
        let data;
        let setFunction;

        switch (type) {
          case 'plot_twist':
            data = res.plot_twists;
            setFunction = setPlotTwists;
            break;
          // case 'character_development':
          //   data = res.character_development;
          //   setFunction = setCharacterDevelopment;
          //   break;
          case 'story_enhancement':
            data = res.story_enhancements;
            setFunction = setStoryEnhancements;
            break;
          default:
            throw new Error('Unknown enhancement type');
        }

        console.log(`Data for ${type}:`, data);

        const formattedData = Object.keys(data).map((key, index) => ({
          heading: key,
          body: data[key],
        }));

        console.log(`Formatted data for ${type}:`, formattedData);

        if (isMountedRef.current) {
          setFunction(formattedData);
        }
      } else {
        console.error(`Invalid response format or missing ${type}:`, res);
        if (isMountedRef.current) setErrorFeedback('Invalid response format');
      }
    } catch (err) {
      console.error(`Error fetching ${type}:`, err);
      if (isMountedRef.current) setErrorFeedback(`Error fetching ${type}`);
    } finally {
      if (isMountedRef.current) setLoadingFeedback(false);
      if (isMountedRef.current) setIsDataLoading(false); // Hide the spinner

    }
  };

  const handleFetchData = (type) => {
    setSelectedEnhancement(type);
    fetchStoryEnhancements(
      type,
      setPlotTwists,
      // setCharacterDevelopment,
      setStoryEnhancements,
      setLoadingFeedback,
      setErrorFeedback,
      storyId,
      isMountedRef
    );
  };

  const renderData = () => {
    let dataToRender = [];
    switch (selectedEnhancement) {
      case 'plot_twist':
        dataToRender = plotTwists.map((item, index) => (
          <div key={index}>
            <Row justify={'center'} style={{ alignSelf: 'center', width: '100%', margin: '0', padding: '0' }}>
              <div id="question_card" className="question_card">
                <h5 style={{ fontSize: '15px', fontWeight: 'bold', margin: '0', padding: '0', textAlign: 'left' }}>{item.heading}</h5>
                <p style={{ fontSize: '15px', margin: '0', padding: '0', textAlign: 'left' }}>{item.body}</p>
              </div>
            </Row>
          </div>
        ));
        break;
      // case 'character_development':
      //   dataToRender = characterDevelopment.map((item, index) => (
      //     <div key={index}>
      //       <Row justify={'center'} style={{ alignSelf: 'center', width: '100%', margin: '0', padding: '0' }}>
      //         <div id="question_card" className="question_card">
      //           <h5 style={{ fontSize: '15px', fontWeight: 'bold', margin: '0', padding: '0', textAlign: 'left' }}>{item.heading}</h5>
      //           <p style={{ fontSize: '15px', margin: '0', padding: '0', textAlign: 'left' }}>{item.body}</p>
      //         </div>
      //       </Row>
      //     </div>
      //   ));
      //   break;
      case 'story_enhancement':
        dataToRender = storyEnhancements.map((item, index) => (
          <div key={index}>
            <Row justify={'center'} style={{ alignSelf: 'center', width: '100%', margin: '0', padding: '0' }}>
              <div id="question_card" className="question_card">
                <h5 style={{ fontSize: '15px', fontWeight: 'bold', margin: '0', padding: '0', textAlign: 'left' }}>{item.heading}</h5>
                <p style={{ fontSize: '15px', margin: '0', padding: '0', textAlign: 'left' }}>{item.body}</p>
              </div>
            </Row>
          </div>
        ));
        break;
      default:
        break;
    }
    return dataToRender;
  };

  const renderFeedbackButtons = () => {
    const titles = [
      'Plot Twists',
      // 'Develop your character',
      'Enhance Your Story',
    ];
    const descriptions = [
      'Give your story the curveball',
      // 'Tips to build your characters',
      'Strengthen your story line',
    ];

    return (
      <div className="feedback-buttons-container">
        <div
          className="feedback-buttons"
          style={{
            display: 'flex',
            flexDirection: selectedEnhancementType ? 'row' : 'column',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          {!selectedEnhancementType && (
            // Render buttons before any enhancement type is selected
            <>
              {['plot_twist', 'story_enhancement'].map((type, index) => (
                <div key={type} className="feedback-section" style={{ margin: '2px' }}>
                  <div id="question_card" className="question_card">
                    <h5 style={{ fontSize: '16px', margin: '5px 0', textAlign: 'left' }}>{titles[index]}</h5>
                    <p style={{ fontSize: '14px', margin: '5px 0', textAlign: 'left' }}>{descriptions[index]}</p>
                    <Button
                      className="feedbackButton"
                      onClick={() => handleEvaluateClick(type)}
                      style={{
                        width: '80px',
                        height: '35px',
                        fontSize: '12px',
                        marginTop: '10px',
                        backgroundColor: 'black',
                        color: 'white',
                      }}
                    >
                      {buttonTexts[index]}
                    </Button>
                  </div>
                </div>
              ))}
            </>
          )}
          {selectedEnhancementType && (
            // Render buttons after an enhancement type is selected
            <>
              {['plot_twist', 'story_enhancement'].map((type, index) => (
                <Button
                  key={type}
                  className="feedbackButton"
                  onClick={() => handleEvaluateClick(type)}
                  style={{
                    width: '75px',
                    height: '35px',
                    fontSize: '12px',
                    marginTop: '10px',
                    backgroundColor: type === selectedEnhancementType ? 'black' : 'white',
                    color: type === selectedEnhancementType ? 'white' : 'black',
                  }}
                >
                  {buttonTexts[index]}
                </Button>
              ))}
            </>
          )}
        </div>
      </div>
    );
  };

  const enhancementTypeLabels = {
    'plot_twist': 'Plot Twists',
    // 'character_development': 'Character',
    'story_enhancement': 'Enhance',
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', () => {
      document.querySelector('.spell-check-body').addEventListener('click', (e) => {
        setVisible(true)
        return
      })
      setVisible(false)
    })
  }, [])

  const changeAccordianPosition = (clientX, clientY) => {
    let accordion = document.querySelector('.spell-check-body')
    let textArea = document.querySelector('.text_area')
    let editor = document.querySelector('.editor')

    let newLeft = (editor.getBoundingClientRect().width - textArea.getBoundingClientRect().width) / 2

    const tempClientY =
      clientY + accordion.getBoundingClientRect().height > window.innerHeight
        ? clientY - accordion.getBoundingClientRect().height
        : clientY

    let leftPixel = 'auto'
    leftPixel =
      clientX - accordion.getBoundingClientRect().width / 2 <= newLeft
        ? newLeft + accordion.getBoundingClientRect().width / 2 + 5
        : clientX
    let rightMax = newLeft + textArea.getBoundingClientRect().width

    if (leftPixel + accordion.getBoundingClientRect().width > rightMax) {
      let count = leftPixel + accordion.getBoundingClientRect().width / 2 - rightMax
      leftPixel -= count
      leftPixel -= 10
    }
    accordion.style.left = leftPixel + 'px'
    accordion.style.top = tempClientY + 'px'
  }

  const handleScroll = (clientX, currentClickedEl) => {
    const elementRect = currentClickedEl.getBoundingClientRect()
    const clientY = elementRect.top + currentClickedEl.scrollTop
    changeAccordianPosition(clientX, clientY)
  }

  const handleTextClick = (e) => {
    console.log("handling text click ...");
    e.stopPropagation();

    // Extract the word JSON object from the data attribute
    const wordData = e.target.getAttribute('data-word');
    console.log("inner text : ", e.target.innerText)
    console.log("inner  : ", e.target)

    const word = JSON.parse(wordData);

    console.log("Clicked word JSON object stored and passed :", word);

    // Find the current text in the spelling array
    /*
    const currentText = spelling?.find(
      (el) => el?.incorrect_usage.includes(e?.target?.innerText) || e?.target?.innerText.includes(el?.incorrect_usage)
    );
    */
    // set currentText to the embedded word clicked
    const currentText = word

    console.log("the selected text indexes:", currentText);

    if (currentText) {
      // here we are setting state , the currentext is the exact object returned from the backend that matches the 
      // the clicked element as well
      setCurrentTextSuggestion(currentText);
      setVisible(true);

      if (editorRef.current) {
        const quillEditor = editorRef.current.getEditor();
        // console.log("Quill Editor instance for event:", quillEditor);

        let currentClickedEl;

        // so now , since we have embedded the exact information about the object in the data-word attribute
        // we only attach listener to that particular word only
        console.log("looking to attach event listeners for scroll and resize ....")
        quillEditor.root.querySelectorAll('u').forEach((el) => {
          const embedded_word = JSON.parse(el.getAttribute('data-word'))
          if (el.innerText === e?.target?.innerText && embedded_word.start_index === word.start_index && embedded_word.end_index === word.end_index) {
            console.log("seen the word ... ", embedded_word)
            currentClickedEl = el;
            return;
          }
        });

        changeAccordianPosition(e.clientX, e.clientY);
      }

      if (currentClickedEl) {
        window.addEventListener('scroll', () => handleScroll(e.clientX, currentClickedEl));
        window.addEventListener('resize', () => handleScroll(e.clientX, currentClickedEl));
      }
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      const quillEditor = editorRef.current.getEditor()
      // console.log("Quill Editor instance for keydown event:", quillEditor);

      if (quillEditor) {
        quillEditor.root.addEventListener('keydown', handleEnterKeyPress)
      }

      return () => {
        if (quillEditor) {
          quillEditor.root.removeEventListener('keydown', handleEnterKeyPress)
        }
      }
    }
  }, [])

  const handleQuillChange = (htmlContent) => {
    console.log("handle quill change ... for value  ", value)
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, 'text/html')
    const textContent = doc.body.textContent
    console.log("parsed to set : ", textContent)
    setTextContent(textContent)
  }

  useEffect(() => {
    handleQuillChange(value)
  }, [value])

  const countImgTags = () => {
    // console.log("counting image tags .")
    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for counting images:", quill);


      const imgTags = quill.root.querySelectorAll('img')

      // console.log("the image tags are : ", imgTags)

      // console.log("the number of image tags counted are : ", imgTags.length)

      return imgTags.length
    }
  }

  useEffect(() => {
    const words = countWords(textContent)

    const validity = (e) => {
      if (words % 150 === 0 && words != 0 && e.code != 'Space') {
        setIsImageUnlocked(true)

        setTimeout(() => {
          setIsImageUnlocked(false)
        }, 3000)
      }
    }

    window.addEventListener('keypress', validity)

    if (Math.floor(words / 150) > countImgTags() && isImgDialogOpen == false) {
      setIsImgDialogOpen(true)
      setIsImageUploading(false)
    } else {
      if (!(Math.floor(words / 150) > countImgTags()) && isImgDialogOpen) {
        setIsImgDialogOpen(false)
      }
    }

    return () => {
      window.removeEventListener('keypress', validity)
    }
  }, [textContent, value])


  useEffect(() => {
    if (assistabcountRef.current >= 50) {
      setActiveKey('2'); // Set the active tab key to '2' (Assist tab)
    } else if (tips.length > 1) {
      setActiveKey('1'); // Default to 'Tips' tab if tips are available and Assist condition is not satisfied
    } else {
      setActiveKey(null); // No tabs should be shown
    }
  }, [assistabcountRef.current, tips.length]);
  console.log('activeKey: ', activeKey)
  // console.log('tips: ', tips)


  const handleEvaluateClick = async (type) => {
    if (countWords(textContent) >= 50) {
      setFeedbackSpin(true);
      setLoadingFeedback(true); // Set loading to true
      setActiveKey('2'); // Set the active tab key to '2' when "Evaluate" button is clicked
      setclose(false); // Toggle collapse arrow (assuming close is the state for the collapse arrow)
      setSelectedEnhancementType(type);

      try {
        await handleFetchData(type); // Fetch only the selected enhancement
      } catch (error) {
        console.error('Error fetching story enhancements:', error);
      } finally {
        setFeedbackSpin(false);
        setLoadingFeedback(false); // Set Help loading to false
      }
    }
  };


  const countWords = (text) => {
    if (!text.trim()) {
      return 0
    }
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word !== '')


    //console.log("count words called ... ", words.length)

    return words.length
  }

  useEffect(() => {
    let typingTimer

    const currentWordCount = countWords(textContent)

    if (currentWordCount - previousWordCount >= 50) {
      saveHandler()

      setPreviousWordCount(currentWordCount)
    }

    typingTimer = setTimeout(async () => {
      await saveHandler()
      setPreviousWordCount(currentWordCount)
    }, 2000)

    return () => {
      clearTimeout(typingTimer)
    }
  }, [value])

  useEffect(() => {
    console.log('Entering useEffect');
    console.log("create own title : ", createOwnTitle)

    const getAuthorInformation = async () => {
      //console.log("initially ... ", authorInformation.current)
      dispatch(AuthActions.getCurrentUser())
        .then((res) => {
          //console.log("got user info ...")
          //console.log("the res is : ", res.data)
          const info = {
            "selected_author_name": res.data.selected_author_name,
            "selected_author_description": res.data.selected_author_description,
          }
          authorInformation.current = info
          console.log("set author info as : ", authorInformation.current)

        })
        .catch((e) => { console.log(e) })
    }


    const loadTitle = async () => {
      console.log('Inside loadTitle');
      console.log('storyId:', storyId);
      console.log('createOwnTitle:', createOwnTitle);

      try {
        let res
        if (createOwnTitle) {
          console.log('Creating own title logic');


        } else if (storyId && createOwnTitle != true) {
          console.log("condition to get the data for an existing story ...")
          console.log('Fetching data for storyId:', storyId);
          res = await dispatch(StoryActions.fetchData(storyId));

          // Debugging: Log the response from the API

          // Debugging: Log the response from the API
          console.log('data in write', res);
          setTitle(res.title);

          if (res.prompt) setAirtablePrompt(res.prompt);
          if (res.story != null) {

            console.log("the story is : ", res.story)

            if (res.title == 'Untitled') {

            }
            else {
              setValue(res.story);
            }

          }

          if (res.tips.tips.length === 1 && res.tip.tips[0] === '') {
            console.log("empty tips ...")
            settips([]);
          }
          else {
            settips(res.tips.tips);
          }


        } else {
          //console.log("condition to fetch titles ...")
          //let title = 'Untitled';
          console.log("condition to create own story ...")
          settips([])

          //setTitle(res.title);
          //setTitle(title);

          //res = await dispatch(StoryActions.fetchTitle());

          /*
          const res = await TokenApi.post('story-activity-save-title/', {
            title
          });
          */

          //console.log("the res is : ", res)

          /*
          if (res.tips.tips.length === 1 && res.tip.tips[0] === '') {
            console.log("empty tips ...")
            settips([]);
          }
          else {
            settips(res.tips.tips);
          }
          */
          //history.replace(`/write?storyId=${res.StoryID}&createOwnTitle=${true}`);
        }
      } catch (error) {
        console.error('Error loading title:', error);
      } finally {
        setIsLoading(false);
        // if (location.pathname.startsWith('/write')) {
        //   console.log("to trigger active message ..");
        
        //   const screenWidth = window.innerWidth;
        
        //   const timer = setTimeout(() => {
        //     setIsActiveMessageOn(true); // Show the message
        
        //     if (screenWidth >= 768) {
        //       // For larger and middle screens, hide the message after 7 seconds
        //       setTimeout(() => {
        //         setIsActiveMessageOn(false); // Hide the message after 7 seconds
        //       }, 20000);

        //     } else if(screenWidth<=768){
        //       setIsActiveMessageOn(true);
        //     }
        //     // For mobile screens (screenWidth < 768), the message remains visible
        //   }, 1000); // Delay of 1 second before showing the message
        // }

        if (location.pathname.startsWith('/write')) {
          console.log("to trigger active message ..");
        
          const screenWidth = window.innerWidth;
        
          const timer = setTimeout(() => {
            setIsActiveMessageOn(true);
        
            setTimeout(() => {
              setIsActiveMessageOn(false); 
            }, 10000);
          }, 1000); 
        }
      }
    };


    loadTitle();

    // getting the current user ..
    getAuthorInformation();

    return () => {
      console.log('Cleaning up useEffect');
    };
  }, [dispatch, history, storyId, createOwnTitle]);




  /*
    const spellCheck = async () => {
      if (countWords(textContent) < 50) {
        return
      }
      try {
        setSpellingSpin(true)
        const response = await TokenApi.post('story-activity-spellcheck/', {
          textContent,
          story_id: storyId,
          title
        })
        console.log("the response is : ", response)
  
        setspelling(response.errors.filter((error) => error.correct_usage !== error.incorrect_usage))
        setSpellingSpin(false)
        setsubmitted(!submitted)
  
        setIsSpellToastOn(true)
  
        setTimeout(() => {
          setIsSpellToastOn(false)
        }, 3000)
  
        await saveHandler()
      } catch (error) {
        console.error('Error:', error)
        setSpellingSpin(false)
        seterror(true)
        setIsModalOpen(true)
      }
    }
  
  */




  const spellCheck = async () => {
    console.log("spellcheck called ...")
    if (countWords(textContent) % 25 != 0) {
      console.log("not enough words or not multiple of 25 ..")
      return;
    }
    try {
      setSpellingSpin(true);
      console.log("enough words ..")
      console.log(textContent)

      const response = await TokenApi.post('story-activity-spellcheck/', {
        textContent,
        story_id: storyId,
        title
      });
      console.log("set spelling ...")
      console.log("the response is : ", response);

      const quill = editorRef.current.getEditor();
      const contents = quill.getContents();

      console.log("the ops .. ", contents.ops)
      console.log("textcontent : ", textContent)

      // Step 1: Capture the positions of all images
      let imagePositions = [];
      let textIndex = 0;

      contents.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {
          textIndex += op.insert.length;
        } else if (op.insert && op.insert.image) {
          imagePositions.push(textIndex);
          console.log("Image found at position: ", textIndex);
        }
      });

      console.log("All image positions: ", imagePositions);

      // Step 2: Adjust indices for errors
      const adjustedErrors = response.errors.map(error => {
        console.log("Original error start index: ", error.start_index);
        let offset = 0;
        for (let pos of imagePositions) {
          if (error.start_index > pos) {
            offset += 1;
          } else {
            break;
          }
        }
        console.log("Breaking out, final offset: ", offset);

        return {
          ...error,
          start_index: error.start_index - offset,
          end_index: error.end_index - offset
        };
      });

      // Log adjusted errors
      adjustedErrors.forEach((error, index) => {
        console.log(`Adjusted error ${index} - Start index: ${error.start_index}, End index: ${error.end_index}`);
      });

      // Step 3: Set adjusted errors
      setspelling(adjustedErrors.filter((error) => error.correct_usage !== error.incorrect_usage));
      setSpellingSpin(false);
      setsubmitted(!submitted);
      // setIsSpellToastOn(true);

      /*
      setTimeout(() => {
        setIsSpellToastOn(false);
      }, 3000);
      */

      await saveHandler();
    } catch (error) {
      console.error('Error:', error);
      setSpellingSpin(false);
      seterror(true);
      setIsModalOpen(true);
    }
  };




  // const feedback = async () => {
  //   try {
  //     setfeebbackSpin(true)
  //     const response = await TokenApi.post(`story-feedback/${storyId}`, {
  //       essay_title: title,
  //       essay_body: textContent,
  //       tips: tips
  //     })

  //     setFeedbacks(response)
  //     setActiveKey('2')

  //     let totalScore = 0

  //     const scoresKeys = Object.keys(response['task 2'].score).filter((value) => {
  //       if (value != 'Notes') {
  //         totalScore += response['task 2'].score[value]
  //         return value
  //       }
  //     })

  //     setTotalFeedbackScore(totalScore)

  //     setScores([...scoresKeys])
  //   } catch (error) {
  //     console.log('error in try catch ', error)
  //   }

  //   setfeebbackSpin(false)
  // }

  const focusInWord = (word) => {
    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for focusing on word:", quill);

      quill.focus()
      const text = quill.getText()
      quill.setSelection(text.indexOf(word), 0)
    }
  }

  const wordChangeHandler = async (wrong, right, index) => {
    console.log("inside the word change handler for : ", word, " , ", right, " , ", index)
    const modifiedString = value.replace(new RegExp(`\\b${wrong}\\b`, 'gi'), right)
    setValue(modifiedString)
    // warning - use correctly when working on feature pass the required args
    discardSpelling(index)
    // warning - use correctly when working on feature pass the required args
    // highlightWord('')
    await new Promise((resolve) => setTimeout(resolve, 400))
    // warning - use correctly when working on feature pass the required args
    removeUnderline(right)
    setVisible(false)
    setCurrentTextSuggestion()
    await saveHandler()
  }

  const doneHandler = async (wrong, s_index, e_index) => {
    console.log("in the done handler ...")
    console.log(wrong)
    if (wrong) {
      removeUnderline(wrong, s_index, e_index)
      setVisible(false)
      setCurrentTextSuggestion()
    }
  }

  const discardSpelling = async (wrong, s_index, e_index) => {


    console.log("in the ignore handler : ", wrong)
    console.log(spelling)

    // Find the index of the mistake object in the spelling array with the same start and end index as the 
    // clicked underlined element
    const index = spelling.findIndex(
      (el) => el?.incorrect_usage === wrong && el?.start_index === s_index && el?.end_index === e_index
    );
    // pickup that specific spelling index
    const mistakeObject = spelling[index]

    // the clicked start and end index
    console.log("props indexes : ", s_index, " , ", e_index)

    console.log("Index:", index);
    console.log("Mistake Object:", mistakeObject);

    console.log("in the ignore handler : ", wrong)
    console.log(spelling)

    if (mistakeObject) {
      removeUnderline(wrong, mistakeObject.start_index, mistakeObject.end_index)
      setVisible(false)
      setCurrentTextSuggestion()
    }

    await new Promise((resolve) => setTimeout(resolve, 400))
    const newSpelling = [...spelling]
    newSpelling.splice(index, 1)
    setspelling(newSpelling)
  }

  const discardScore = async (index) => {
    const newScores = [...scores]
    newScores.splice(index, 1)
    setScores(newScores)
  }

  const discardQuestion = (index) => {
    const newQuestions = [...questions]
    newQuestions.splice(index, 1)
    setquestions(newQuestions)
  }

  const retry = () => {
    seterror(false)
    setIsModalOpen(false)
    spellCheck()
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    seterror(false)
  }

  const saveHandler = async () => {
    if (!isLoading && editorRef.current) {
      try {
        const editor = editorRef.current.getEditor();
        const content = editor.getContents();

        let title = '';
        let isFirstLine = true;
        let savedContent = '';

        console.log('Editor content:', content);

        content.ops.forEach(op => {
          console.log('Operation:', op);

          if (isFirstLine) {
            if (op.attributes && op.attributes.header === 1) {
              title = op.insert.trim();
              isFirstLine = false;
              return;
            } else if (typeof op.insert === 'string') {
              title = op.insert.split('\n')[0].trim();
              isFirstLine = false;
              savedContent += op.insert.split('\n').slice(1).join('\n');
              return;
            }
          }

          // Check if op.insert includes airtablePrompt and skip if true
          if (airtablePrompt && op.insert.includes(airtablePrompt)) {
            console.log("we have airtable prompt ..")
          }

          savedContent += op.insert;
        });

        if (createOwnTitle && !title) {
          title = 'Untitled';
        }

        console.log('Extracted title:', title);
        savedContent = savedContent.trim()
        console.log('Extracted story content:', savedContent);

        const updatedStoryContent = savedContent.replace(/<u>/g, '').replace(/<\/u>/g, '');

        console.log('Saving story with title:', title, " and story id : ", storyId);

        if (title == '' || title == 'Untitled') {
          console.log("saving without title you will loose progress, are you sure ?")

          /*
          setTimeout(() => {
            setIsTitleSaveOn(true)
            setTimeout(() => {
              setIsTitleSaveOn(false)

            }, 2000);
          }, 4000)
          */

        }


        console.log('and updated story content : ', updatedStoryContent);


        const response = await TokenApi.post('story-activity-save/', {
          story_id: storyId,
          title,
          value: `\n${updatedStoryContent}`
        });

        setIsModalOpen(false);
      } catch (error) {
        console.error('Error saving story:', error);
      }
    }
  };





  const undo = useCallback(() => {

    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for undo action:", quill);

      quill.history.undo()
    }
  }, [])

  const redo = useCallback(() => {

    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for redo action:", quill);


      quill.history.redo()
    }
  }, [])

  const imgGen = useCallback(() => {
    setIsImageUploading(true)

    setIsImgDialogOpen((current) => {
      if (!current) {
        setIsGenImgToastOn(true)
        setTimeout(() => {
          setIsGenImgToastOn(false)
        }, 3000)
      }
      return current
    })
  }, [])

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [[{ header: 2 }], [{ header: 0 }], ['undo', 'redo', 'image']],
        handlers: {
          undo: undo,
          redo: redo,
          image: imgGen,
        },
      },
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: false,
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    [undo, redo, imgGen]
  )

  const formats = ['header', 'underline', 'undo', 'redo', 'image', 'bold', 'italic', 'readOnly'];

  const highlightWord = (word) => {

    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for highlighting word:", quill);

      const text = quill.getText()
      const startIndex = text.indexOf(word)

      // console.log("highlight the following word ... ", word)

      if (startIndex !== -1) {
        quill.setSelection(startIndex, word.length, 'user')
      }
    }
  }


  const underline = (words) => {

    console.log("underlining words : ", words)

    // If words is empty or undefined, remove existing underlines and return early
    /*
    if (!words || words.length === 0) {
      console.log("No words to underline. Removing existing underlines.");
      return;
    }
      */

    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for underlining words:", quill);

      const text = quill.getText()

      words.forEach((word) => {
        const range = {
          index: word?.start_index + 1,
          length: word?.end_index - word?.start_index + 1
        }

        console.log("undelining .....")
        quill.formatText(range, 'underline', true, 'user')
        // highlightWord(word)

        // Add data attributes to the underlined text
        // quill.formatText(range.index, range.length, { 'data-word': JSON.stringify(word) }, 'user');
        // Apply the custom underline with the data attribute
        // quill.formatText(range.index, range.length, { customUnderline: word }, 'user');
      })
      if (quill) {
        const editor = quill.root
        let i = 0
        editor.querySelectorAll('u')?.forEach((el) => {
          el.setAttribute('data-word', JSON.stringify(words[i]))
          console.log(el)
          i += 1
          console.log("embedded : ", el.getAttribute('data-word'))
          el.addEventListener('click', handleTextClick)
        })

        return () => {
          editor.querySelectorAll('u')?.forEach((el) => {
            el.removeEventListener('click', handleTextClick)
          })
        }
      }
    }
  }

  const removeUnderline = (word, occur_start_index, occur_end_index) => {
    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for removing underline from word:", quill);

      const text = quill.getText()

      console.log("indexes from backend : ", occur_start_index, " , ", occur_end_index)
      // let index = text.indexOf(word)
      // console.log("calculated indexes : ", index)


      /*
      while (index !== -1) {
        const range = {
          index: index,
          length: word.length
        }
        quill.formatText(range, 'underline', false, 'user')
        index = text.indexOf(word, index + 1)
      }
      */

      const range = {
        index: occur_start_index + 1,
        length: occur_end_index - occur_start_index + 1
      }
      quill.formatText(range, 'underline', false, 'user')
    }
  }

  useEffect(() => {
    underline(spelling)
  }, [submitted])

  const goBack = async () => {
    await saveHandler();

    const storyId = new URLSearchParams(location.search).get('storyId');
    if (storyId) {
      try {
        const response = await TokenApi.post(`/send-email-on-back/${storyId}/`);
        console.log(response.message); // Handle the response data
      } catch (error) {
        console.error('Error sending email:', error);
      }
    }

    localStorage.removeItem('story_id');
    history.push('/library');
  };


  const copyLink = async () => {
    const text = `${title}\n Link: https://applyin.co/read/${storyId}`
    await navigator.clipboard.writeText(text)

    setIsToastOn(true)
    setTimeout(() => {
      setIsToastOn(false)
    }, 1500)

    if (window.innerWidth <= 640 && navigator.share)
      await navigator.share({
        title: 'Applyin.co',
        text: title,
        url: `https://applyin.co/read/${storyId}`
      })
  }

  const generateImage = async () => {
    if (!imagePrompt) return

    try {
      setImgGenLoading(true)
      const res = await TokenApi.post(`image-generation/${storyId}/`, {
        user_prompt: imagePrompt
      })

      if (editorRef.current) {

        const quill = editorRef.current.getEditor()
        const index = quill.selection.savedRange.index
        editorRef.current.getEditor().insertEmbed(index, 'image', res.image_url)
      }
    } catch (err) {
      console.log('There is an error while generating image', err)
    }

    setImgGenLoading(false)
    setIsImageUploading(false)
    setIsImgDialogOpen(false)
    setImagePrompt('')
  }

  // preventing the copy,cut & pasting in the quill editor & showing toast
  useEffect(() => {
    if (editorRef.current) {

      const quill = editorRef.current.getEditor()
      // console.log("Quill Editor instance for preventing copy/paste:", quill);

      const preventCopyPaste = (e) => {
        console.log("copy paste allowed now ..")
        //e.preventDefault()
        console.log("Copy/Paste event prevented.");


        setIsCopyPasteToastOn(true)

        setTimeout(() => {
          setIsCopyPasteToastOn(false)
        }, 3000)
      }

      const preventCut = (e) => {
        e.preventDefault()

        setIsCutToastOn(true)

        setTimeout(() => {
          setIsCutToastOn(false)
        }, 3000)
      }

      quill.root.addEventListener('paste', preventCopyPaste)
      quill.root.addEventListener('copy', preventCopyPaste)
      quill.root.addEventListener('cut', preventCut)

      return () => {
        quill.root.removeEventListener('paste', preventCopyPaste)
        quill.root.removeEventListener('copy', preventCopyPaste)
        quill.root.removeEventListener('cut', preventCut)
      }
    }
  }, [])

  window.addEventListener('beforeunload', async (e) => {
    const perfEntries = performance.getEntriesByType('navigation')

    if (perfEntries.length > 0) {
      const navigationType = perfEntries[0].type

      if (navigationType === 'reload') return
    }

    await saveHandler()
  })

  window.addEventListener('popstate', async (e) => {
    const perfEntries = performance.getEntriesByType('navigation')

    if (perfEntries.length > 0) {
      const navigationType = perfEntries[0].type

      if (navigationType === 'reload') return
    }

    await saveHandler()
  })

  const getProgressText = (percent) => {
    if (percent === 0) return "✍🏽 Let's get started!"
    if (percent > 0 && percent <= 10) return '🚀 You’re on your way!'
    if (percent > 10 && percent <= 20) return '👏🏽 Great first steps!'
    if (percent > 20 && percent <= 50) return '😃 Keep going!'
    if (percent > 50 && percent < 100) return '😎 Looking good!'
    if (percent === 100) return '🤩 Keep it up!'
  }

  const wordCount = countWords(textContent)
  const progressPercent = Math.min(Math.floor((wordCount / 175) * 100), 100)
  const progressText = getProgressText(progressPercent)

  console.log("word count : ", wordCount)

  useEffect(() => {
    if (assistabcountRef.current == 50 && buttonRef.current) {
      buttonRef.current.click();
    }
  }, [assistabcountRef.current]);

 
    useEffect(() => {
      const fetchUserName = async () => {
          try {
              // Dispatch the action to get the current user
              const userProfile = await dispatch(getCurrentUser());

              console.log('userProfile.data.profile.first_name', userProfile.data.profile.first_name)


              // Extract the first name from the user profile
              if (userProfile && userProfile.data && userProfile.data.profile && userProfile.data.profile.first_name) {
                setUserName(userProfile.data.profile.first_name);
              }
          } catch (error) {
              console.error('Error fetching user profile:', error);
          }
      };

      fetchUserName();
    }, [dispatch]);


  return (
    <>
      {isImageUnlocked && <Confetti width={windowWidth} height={innerHeight} />}

      <Helmet>
        <title>applyin.co: write</title>
        <meta name="description" content="Write a story on applyin.co" />
      </Helmet>
      <div className={`spell-check-body ${visible ? 'show' : ''}`}>
        {currentTextSuggestion && (
          <Spellcheck
            wrong={currentTextSuggestion?.incorrect_usage}
            right={currentTextSuggestion?.correct_usage}
            change={wordChangeHandler}
            discard={() => discardSpelling(currentTextSuggestion?.incorrect_usage,
              currentTextSuggestion?.start_index, currentTextSuggestion?.end_index)}
            errorType={currentTextSuggestion?.error_type}
            theory={currentTextSuggestion?.theory}
            done={() => doneHandler(currentTextSuggestion?.incorrect_usage, currentTextSuggestion?.start_index, currentTextSuggestion?.end_index)}
          />
        )}
      </div>
      <div className="layout">
        {isImgDialogOpen && isImageUploading && (
          <div className="image-prompt-shadow">
            <div>
              <h3>Describe the scene you want to create</h3>

              <textarea
                placeholder="A girl riding a bicycle through a sunflower field on a bright summer day"
                value={imagePrompt}
                onChange={(e) => {
                  const words = countWords(e.target.value)
                  if (words < 25) setImagePrompt(e.target.value)

                  if (words < 5) setCanGenerateImg(false)
                  else setCanGenerateImg(true)
                }}
              />

              <p>{countWords(imagePrompt)} / 25</p>

              <div>
                <Tooltip
                  placement="top"
                  title={canGenerateImg ? '' : 'Please enter at least 5 words'}
                  trigger={'click'}
                >
                  <button
                    style={{
                      width: '150px',
                      height: '40px',
                      fontSize: '17px',
                      border: 'none',
                      backgroundColor: 'black',
                      color: 'white',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    onClick={() => {
                      if (canGenerateImg) {
                        generateImage()
                        setCanGenerateImg(false)
                      }
                    }}
                  >
                    {imgGenLoading ? (
                      <Spin
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} />}
                        spinning={imgGenLoading}
                      />
                    ) : (
                      'Generate image'
                    )}
                  </button>
                </Tooltip>
                <button
                  style={{
                    width: '150px',
                    height: '40px',
                    fontSize: '17px',
                    borderRadius: '5px',
                    border: '0.5px solid gray',
                    cursor: 'pointer'
                  }}
                  onClick={() => setIsImageUploading(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="header">
          <Header />
        </div>

        <Toast text={'✅ Link Copied'} isOn={isToastOn} />
        <Toast
          text={`Please write ${150 - (countWords(textContent) % 150)} more words to generate a new image`}
          isOn={isGenImgToastOn}
        />

        <Toast text="🖼️ Image unlocked! Add an image using the toolbar" isOn={isImageUnlocked} />

        <Toast text={'Just a reminder: No copy-pasting please 😊'} isOn={isCopyPasteToastOn} />
        <Toast text={"Reminder: ✂️ No cutting since you won't be able to paste 😊"} isOn={isCutToastOn} />
        <Toast text={'Spellcheck complete!'} isOn={isSpellToastOn} />

        <Toast text={'Please enter title to ensure progress is saved'} isOn={isTitleSaveOn} />


        {/* toast text for the feedback every 25 words ... */}
        {/*<Toast text={feedbackText} isOn={isFeedbackToastOn} />*/}

        {/* Template text for the feedback every 25 words */}
        {/* Display the current toasts */}
        {activeToasts.length > 0 && activeToasts.map((toast) => (
          <Template
            key={toast.id}
            authorMessage={toast.message}
            authorName={(authorInformation.current.selected_author_name.split(' ')[1] || authorInformation.current.selected_author_name.split(' ')[0])}
            authorPicture={authorMapper[authorInformation.current.selected_author_name][0]}
            isOn={toast.isOn}
            isOnTranslate={toast.isOnTranslate}
            isOffTranslate={toast.isOffTranslate}
            isOnDisplayAuthor={toast.isOnDisplayAuthor}
            widthAdjustment={toast.widthAdjustment}
          />
        ))}

      {isActiveMessageOn && (
        <>
          <div>
      {showFirstMessage && (
        <Template
          authorMessage={`is active !!! `}
          authorName={(authorInformation.current.selected_author_name.split(' ')[1] || authorInformation.current.selected_author_name.split(' ')[0])}
          authorPicture={''}
          isOn={isActiveMessageOn}
          isOnTranslate={200}
          isOffTranslate={-100}
          isOnDisplayAuthor={true}
          widthAdjustment={0}
          starterMessage={true}
        />
      )}
      {showSecondMessage && (
        <Template
          authorMessage={`Hi ${userName}, Let’s begin! I’ll help you refine your story with feedback as you go.`}
          authorName={(authorInformation.current?.selected_author_name?.split(' ')[1] || authorInformation.current?.selected_author_name?.split(' ')[0])}
          authorPicture={''}
          isOn={isActiveMessageOn}
          isOnTranslate={200}
          isOffTranslate={-100}
          isOnDisplayAuthor={false}
          widthAdjustment={0}
          starterMessage={false}
        />
      )}
    </div>
</>)}

        <div className="middle">
          <div className="left">
            <div className="body">
              <span id="back" style={{ position: 'absolute', cursor: 'pointer', left: '15px' }} onClick={goBack}>
                ←
              </span>
              {isLoading && <div className="title">

                {isLoading && <div>Loading...</div>}

              </div>}

              {/*!isLoading && (
                <div className="editor">

                  <div className="text_area" spellCheck="false">
                    {
                      showToolbar ? (

                        <ReactQuill
                          theme="snow"
                          value={value}
                          onChange={(value) => setValue(value)}
                          modules={modules}
                          formats={formats}
                          ref={editorRef}
                        />

                      ) : (

                        <div className='hide_toolbar'>
                          <ReactQuill
                            theme="snow"
                            value={value}
                            onChange={(value) => setValue(value)}
                            modules={modules}
                            formats={formats}
                            ref={editorRef}
                          />

                        </div>
                      )

                    }

                  </div>

                </div>
              )*/}

              {!isLoading  && (
                <div className="editor">
                  <div className={`text_area ${showToolbar && activeToasts.length == 0 ? '' : 'hide-toolbar'}`} spellCheck="false">
                    <ReactQuill
                      ref={editorRef}
                      value={value}
                      onChange={(value) => setValue(value)}
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                </div>
              )}
            </div>


            <div className="footer">
              <div className="outer-footer">

                <Space className="responsive-space">
                  <div className="progress-container">
                    <div className="counter">{countWords(textContent)} words</div>
                    <div className="progress-bar">
                      <Progress percent={progressPercent} strokeColor="black" trailColor="#DFDFDB" />
                    </div>
                    <div className="progress-text">
                      {progressText}
                    </div>
                    {windowWidth < 1024 && !close && (
                      <div className="closeButton" onClick={() => setclose(true)}>
                        <DownOutlined />
                      </div>
                    )}
                  </div>
                </Space>


                <Space>
                  {windowWidth < 1024 && !close ? (
                    <div className="footer_carousel">
                      <Tabs centered style={{ backgroundColor: 'white', fontSize: '15px' }} activeKey={activeKey} onChange={setActiveKey}>
                        {tips.length > 1 && (
                          <TabPane tab="Tips" key="1">
                            <Carousel>
                              {tips.map((item, index) => (
                                <div key={index}>
                                  <Tips string={item} />
                                </div>
                              ))}
                            </Carousel>
                          </TabPane>
                        )}

                        {assistabcountRef.current >= 50 && window.innerWidth >= 768 && (
                          <TabPane
                            // ref={buttonRef}
                            // onClick={() => {
                            //   setSelectedEnhancement(null); // Clear selected enhancement
                            //   handleEvaluateClick(); // Call evaluate
                            // }}
                            tab={selectedEnhancementType ? enhancementTypeLabels[selectedEnhancementType] || selectedEnhancementType.replace('_', ' ') : 'Assist'}
                            key="2"
                          >
                            <div className="card" style={{ width: '100%', maxWidth: '600px', margin: '0 auto', boxShadow: 'none', padding: '0' }}>
                              {isDataLoading ? (
                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                  <Spin tip="Loading..." />
                                </div>
                              ) : selectedEnhancementType ? (
                                <Carousel>
                                  {renderData()}
                                </Carousel>
                              ) : (
                                <p style={{ textAlign: 'left', margin: '0', padding: '10px' }}>👆 Click a button to get help</p>
                              )}
                            </div>
                            <div className="feedback-buttons" style={{ marginTop: '20px' }}>
                              <Carousel>
                                {renderFeedbackButtons()}
                              </Carousel>
                            </div>
                          </TabPane>
                        )}


                      </Tabs>
                    </div>
                  ) : (
                    <div className="save">


                      <Modal open={isModalOpen} footer={null} centered closable={false}>
                        <Row
                          justify={'center'}
                          item={'center'}
                          style={{
                            alignSelf: 'center',
                            margin: '15px 0'
                          }}
                        >
                          {!error ? (
                            <>
                              <p>Do you want to save your story?</p>
                              <br />
                              <br />
                              <Space>
                                <Button
                                  className="modal_button"
                                  type="primary"
                                  size="medium"
                                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  onClick={() => saveHandler()}
                                >
                                  Save Story
                                </Button>
                                <Button
                                  className="modal_button"
                                  size="medium"
                                  style={{
                                    border: '1px solid black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </Button>
                              </Space>
                            </>
                          ) : (
                            <>
                              <p>Something went wrong. Please try again.</p>

                              <Space>
                                <Button
                                  type="primary"
                                  size="medium"
                                  style={{
                                    padding: '0px 15px',
                                    height: '40px',
                                    width: '200px',
                                    border: '1px solid black',
                                    fontSize: '17px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={() => retry()}
                                >
                                  Retry
                                </Button>
                                <Button
                                  size="medium"
                                  style={{
                                    padding: '0px 15px',
                                    height: '40px',
                                    width: '200px',
                                    border: '1px solid black',
                                    fontSize: '17px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </Button>
                              </Space>
                            </>
                          )}
                        </Row>
                      </Modal>

                      {/* {assistabcountRef.current >= 50 && (
                        <Button
                        ref={buttonRef}
                          // size="medium"
                          // className="customButton"
                          onClick={() => {
                            setSelectedEnhancement(null); // Clear selected enhancement
                            handleEvaluateClick(); // Call evaluate
                          }}
                          // disabled={feedbackSpin}
                        >
                          {/* {!feedbackSpin ? 'Assist' : <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spinning={feedbackSpin} />} />} */}
                      {/* </Button> */}
                      {/* )}   */}


                      <Button size="medium" className="customButton-share" style={{ border: 'none', background: 'transparent', paddingTop: '10px', boxShadow: 'none'  }} onClick={() => copyLink()}>
                      <ShareIcon/>
                      </Button>
                            {window.innerWidth > 720 ? (
                              <div className="collapse-arrow" onClick={() => setClose(false)}>
                               <UpOutlined style={{ marginTop: '20px' }} />
                              </div>
                            ) : null}
                    </div>
                  )}
                </Space>
              </div>
            </div>
          </div>

          <div className="right">
            <>
              <div className="right_scroll">
                <Tabs centered defaultActiveKey="1" activeKey={activeKey} onChange={setActiveKey}>
                  {tips.length > 1 && (
                    <TabPane tab="Tips" key="1">
                      <div className="tips">
                        {tips.map((item, index) => (
                          <div key={index}>
                            <Tips string={item} />
                          </div>
                        ))}
                      </div>
                    </TabPane>
                  )}

                  {assistabcountRef.current >= 50 && (
                    <Tabs.TabPane
                      // ref={buttonRef}
                      // onClick={() => {
                      //   setSelectedEnhancement(null); // Clear selected enhancement
                      //   handleEvaluateClick(); // Call evaluate
                      // }}
                      tab={selectedEnhancementType ? enhancementTypeLabels[selectedEnhancementType] || selectedEnhancementType.replace('_', ' ') : 'Assist'}
                      key="2"
                    >
                      <div className="card" style={{ width: '100%', maxWidth: '600px', margin: '0 auto', boxShadow: 'none', padding: '0' }}>
                        {isDataLoading ? (
                          <div style={{ textAlign: 'center', padding: '20px' }}>
                            <Spin tip="Loading..." />
                          </div>
                        ) : (
                          <div>
                            {selectedEnhancementType ? renderData() : (
                              <p style={{ textAlign: 'left', margin: '0', padding: '10px' }}>👆 Click a button to get help</p>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="feedback-buttons" style={{ marginTop: '20px' }}>
                        {renderFeedbackButtons()}
                      </div>
                    </Tabs.TabPane>
                  )}
                </Tabs>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default Write;