import React, { useState } from 'react'
import { Button, Dropdown, Layout, List, Row, Space, Tooltip, Typography } from 'antd'
import logo from '../assets/images/writeary.png'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CaretDownOutlined, CloseOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import FlagIcon from '@/assets/icons/redflag.png'
import './header.less'
import { partnersUrl } from '@/constants'
import { logOut } from '@/redux/auth/actions'

const Header = ({ onClick }) => {
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  let history = useHistory()
  const [open, setOpen] = useState(false)

  const handelMenuOpen = () => {
    setOpen(!open)
  }
  const handelMenuClose = () => {
    setOpen(false)
  }

  const [dropdown, setDropdown] = useState(false)

  const items = [
    {
      label: (
        <a
          rel="noreferrer"
          href="https://applyin.notion.site/ApplyIn-Blog-57900fa258ab40cd8e8fcec3994efc09"
          target="_blank"
        >
          Blog
        </a>
      ),
      key: '0'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <a rel="noreferrer" href="https://iquotient.beehiiv.com/" target="_blank">
          Newsletter
        </a>
      ),
      key: '1'
    }
    // {
    //   type: 'divider'
    // },
    // {
    //   label: (
    //     <a rel="noreferrer" href="https://topmate.io/nksbits" target="_blank">
    //       Book a coaching session
    //     </a>
    //   ),
    //   key: '3'
    // }
    // {
    //   type: 'divider'
    // },
    // {
    //   label: auth.is_authed && (
    //     <div>
    //       <a style={{ fontSize: 24 }} onClick={() => dispatch(logOut())}>
    //         Log Out <Space />
    //         <LogoutOutlined />
    //       </a>
    //     </div>
    //   ),
    //   key: '4'
    // }
  ]

  return (
    <Layout.Header className="header-wrapper">
      <Row justify="center" className="header-row">
        <Link to="/" style={{ lineHeight: 0, display: 'inline-block' }}>
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Row className="inner-row">
          {/* {!auth.is_authed && (
            <div onClick={handelMenuOpen} className="menu-icon">
              <Space className="menu-wrapper">
                <MenuOutlined />
              </Space>
            </div>
          )} */}
          {!auth.is_authed && (
            <div className={`menu-ul ${open ? 'show' : ''}`}>
              <div className="menu-inner">
                <div className="header-side">
                  <img src={logo} alt="" className="side-logo" />
                  <CloseOutlined onClick={handelMenuClose} />
                </div>
                {/* <List>
                  <List.Item>
                    <Link to="/story">Story</Link>
                  </List.Item>
                  <List.Item>
                    <Link to="/questions">Questions</Link>
                  </List.Item>
                  <List.Item>
                    <a rel="noreferrer" href="https://topmate.io/nksbits" target="_blank">
                      Coaching
                    </a>
                  </List.Item>
                  <List.Item>
                    <Link to={partnersUrl}>Partners</Link>
                  </List.Item>
                  <List.Item>
                    <div className="custom-dropdown">
                      <div className="custom-dropdown-head" onClick={() => setDropdown(!dropdown)}>
                        <Typography>Resources</Typography>
                        <CaretDownOutlined className={`${dropdown ? 'open' : ' '} down-arrow`} />
                      </div>
                      <div className={`${dropdown ? 'open' : ' '} dropdown-list`}>
                        <a
                          rel="noreferrer"
                          href="https://applyin.notion.site/ApplyIn-Blog-57900fa258ab40cd8e8fcec3994efc09"
                          target="_blank"
                        >
                          Blog
                        </a>
                        <a rel="noreferrer" href="https://iquotient.beehiiv.com/" target="_blank">
                          Newsletter
                        </a>
                      </div>
                    </div>
                  </List.Item>
                </List> */}
              </div>
            </div>
          )}
          {!auth.is_authed && (
            <Button type="primary" className="primary-btn">
              <Link to="/login">✍️ Start writing</Link>
            </Button>
          )}
          {auth.is_authed && (
            <div className="flag-wrapper">
              {/* <div className={`${open ? 'open' : ''} arrow-wrapper menu common-flex`} onClick={onClick}>
                <MenuOutlined />
              </div> */}
              <div className="flag-icon">
                <Tooltip title="Report an issue" placement="left">
                  <a
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/1HYyFibLZxPZuiY_kIvvKpRIehy74TEt1BPSkchbERL8/edit"
                    target="_blank"
                  >
                    <img src={FlagIcon} width="100%" />
                  </a>
                </Tooltip>
              </div>
              <div className="logout-icon">
                <Tooltip title="Sign out" placement="left">
                  <a onClick={() => dispatch(logOut())}>
                    <LogoutOutlined />
                  </a>
                </Tooltip>
              </div>
            </div>
          )}
        </Row>
      </Row>
    </Layout.Header>
  )
}

export default Header
