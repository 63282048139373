import React, { useState, useEffect } from "react";
import { fetchInterviewReadinessScore } from "@/redux/prep/actions";

const ProgressBar = () => {

  const [score, setScore] = useState(0)
  const [questionAttempted, setQuestionAttempted] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const containerStyle = {
    width: "100%",
    height: "10px",
    backgroundColor: "#eee",
    borderRadius: "0",
    overflow: "hidden",
    position: "relative",
    margin: "auto",
  };

  const barStyle = {
    width: `${score}%`,
    height: "100%",
    backgroundColor: "black",
    borderRadius: "0",
    position: "absolute",
    top: "0",
    left: "0",
  };

  const milestonesPc = [
    "Beginner",
    "Average",
    "Average+",
    "Tier 2 Level",
    "Tier 1 Level",
    "FAANG Level",
  ];

  const milestonesMobile = [
    "Beginner",
    "Intermediate",
    "FAANG Level",
  ];


  const milestoneStyling = {
    margin: windowWidth > 1010 ? "10px 18px" : "10px 16px",
    fontSize: windowWidth > 1010 ? "17px" : "15px"
  }

  const milestones = windowWidth > 1010 ? milestonesPc : milestonesMobile;

  const milestoneText = milestones.map((milestone, index) => (
    <span key={index} style={milestoneStyling}>
      {milestone}
    </span>
  ));

  useEffect(() => {
    const fetchScore = async () => {
      try {
        const res = await fetchInterviewReadinessScore();
        setScore(res.score);
        setQuestionAttempted(res.questions_left);
      } catch (error) {
        console.error("Error fetching interview readiness score:", error);
      }
    };

    fetchScore();
  }, []);

  const isLocked = score === 0;

  const remainingQuestion = 12 - questionAttempted;

  return (
    <div>
      <h1 style={{ textAlign: "center", fontSize: "20px", margin: "3%" }}>
        ✅ Your Interview Readiness
      </h1>
      <div style={{ opacity: isLocked ? 0.4 : 1, pointerEvents: isLocked ? 'none' : 'auto' }}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {milestoneText}
        </div>
        <div style={containerStyle}>
          <div style={barStyle}></div>
        </div>
      </div>

      {questionAttempted !== 12 &&
        <h1 style={{ textAlign: "center", fontSize: "15px", margin: "3%" }}>
          🔒 Unlock By answering {remainingQuestion} more question
        </h1>
      }

    </div>
  );
};

export default ProgressBar;
