import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

function GuestGuard({ children }) {
  const auth = useSelector((state) => state.auth)

  if (auth.is_authed) {
    if (window?.history?.state?.state?.prevPath === '/write') {
      return <Redirect to="/write" />
    }
    return <Redirect to="/interview-type" />
  }

  return children
}

GuestGuard.propTypes = {
  children: PropTypes.any
}

export default GuestGuard
