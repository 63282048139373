import React from 'react'
import { Button, Col, Layout, List, Row, Typography } from 'antd'
import LandingImg3 from '@/assets/images/partner-page.png'

function Service() {
  return (
    <>
      <Layout.Content>
        <div className="service-out">
          <Row gutter={16} style={{ alignItems: 'center' }}>
            <Col lg={{ span: 10 }} md={{ span: 12 }} xs={{ span: 24 }}>
              <div className="service-inner">
                <Typography.Title level={1} className="heading">
                  Supercharge your career services team
                </Typography.Title>
                <Typography className="text">
                  Start applyin.co for universities and businesses helps to increase job placement rates for your students
                  with our coaching offering
                </Typography>
                <Typography className="text">
                  Boost the reputation of your program and amplify student success with our interview prep platform
                </Typography>
                <Button type="primary" className="btn-started">
                  <a
                    rel="noreferrer"
                    href="https://calendly.com/start-applyin/applyin-demo-and-enquiry?month=2023-11"
                    target="_blank"
                  >
                    Book demo
                  </a>
                </Button>
              </div>
            </Col>
            <Col lg={{ span: 14 }} md={{ span: 12 }} xs={{ span: 24 }}>
              <div className="right-content">
                <img src={LandingImg3} alt="service" />
              </div>
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </>
  )
}

export default Service
