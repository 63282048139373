import { Button, Space, Tooltip, Typography } from 'antd'
import React from 'react'

const InterviewType = () => {
  return (
    <div className="welcome-interview-wrapper">
      <Space direction="vertical" align="center" className="profile-space" style={{ marginBottom: 20 }}>
        <Typography.Title level={2}>Welcome to Your Interview Playground</Typography.Title>
        <Typography.Text>
          Dive into our interactive platform and experience a fresh approach to interview preparation
        </Typography.Text>
      </Space>
      <Tooltip title={'Practice individual questions from our question bank.'} placement="right">
        <Button
          href="/questions"
          style={{
            margin: 10,
            width: 250,
            transition: 'background-color 0.3s'
          }}
          className="custom-button"
        >
          Practice a Question
        </Button>
      </Tooltip>
      <p style={{ margin: 5 }}>or</p>
      <Tooltip title={"Simulate an interview tailored to a role you're targeting"} placement="right">
        <Button
          href="/prep"
          style={{
            margin: 10,
            width: 250,
            transition: 'background-color 0.3s'
          }}
          className="custom-button"
        >
          Mock Interview
        </Button>
      </Tooltip>
      <style>
        {`
          .custom-button:hover {
            background-color: black !important;
            color: white;
          }
        `}
      </style>
    </div>
  )
}

export default InterviewType
