import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles/authorcard.less'; // Ensure the CSS file is correctly named and located

const AuthorCard = ({ authorPicture, authorName, authorDescription, selectAuthor, currentlySelected }) => {

    const handleSelect = () => {
        console.log("handling the selection ...")
        selectAuthor();
    };

    return (
        <div
            onClick={handleSelect}
            className={`author-card-container ${currentlySelected ? 'selected' : ''}`}
        >
            <div className="author-pic-container">
                <img src={authorPicture} alt={`${authorName}'s picture`} className="author-pic" />
            </div>
            <div>
                <div className="author-name">{authorName}</div>
                <div className="author-description">{authorDescription}</div>
            </div>
        </div>
    );
};

AuthorCard.propTypes = {
    authorPicture: PropTypes.string,
    authorName: PropTypes.string,
    authorDescription: PropTypes.string,
    selectAuthor: PropTypes.func,
    currentlySelected: PropTypes.bool
};

export default AuthorCard;
