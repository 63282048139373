import React from 'react'
import { Card, Button, Row } from 'antd'
import PropTypes from 'prop-types'
import './write.less'

const Spellcheck = ({ wrong, right, change, discard, errorType, theory, onMouseOver, onMouseOut, done }) => {
  return (
    <>
      {/* <div className="main" style={{ border: '1px solid black' /}}> */}
      {/* <Row justify={'center'} style={{ alignSelf: 'center' }}> */}
      <div id="spellcheck_card">
        <h5>{errorType}</h5>
        <p style={{ fontSize: '15px', textWrap: 'wrap' }}>{theory}</p>
        <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
              margin: '5px 0px',
              backgroundColor: 'black',
              color: 'white',
              padding: '4px 12px',
              borderRadius: '7px'
            }}
          // onClick={() => change(wrong, right, index)}
          // onMouseOver={() => onMouseOver(wrong)}
          // onMouseOut={() => onMouseOut('')}
          >
            {right}
          </div>

          <Button
            style={{
              marginLeft: '1.5em',
              height: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
              margin: '5px 0px'
            }}
            onClick={() => discard(wrong)}
          >
            Ignore
          </Button>

          <Button
            style={{
              marginLeft: '1.5em',
              height: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
              margin: '5px 0px'
            }}
            onClick={() => done(wrong)}
          >
            Done
          </Button>
        </div>
      </div>
      {/* </Row> */}
      {/* </div> */}
    </>
  )
}

Spellcheck.propTypes = {
  questions: PropTypes.string,
  wrong: PropTypes.string,
  right: PropTypes.string,
  change: PropTypes.func,
  discard: PropTypes.func,
  errorType: PropTypes.string,
  theory: PropTypes.string,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired,
  done: PropTypes.func

}

export default Spellcheck
