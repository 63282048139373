import scottFitzgeraldPic from '../../../assets/images/scott-fitzgerald.png'; // Import the image
import colletePic from '../../../assets/images/collete.png'; // Import the image
import ernestPic from '../../../assets/images/ernest.png'; // Import the image
import janePic from '../../../assets/images/jane_austen.png'; // Import the image
import tagorePic from '../../../assets/images/tagore.png'; // Import the image
import tolstoyPic from '../../../assets/images/tolstoy.png'; // Import the image
import scottPic from '../../../assets/images/scott.png'; // Import the image


export const authors = [
    {
        id: 1,
        authorPicture: scottPic, // Update with actual paths
        authorName: "Scott Fitzgerald",
        authorDescription: "Perfect for those who want to dive into intricate storytelling and vivid character creation."
    },
    {
        id: 2,
        authorPicture: tagorePic, // Update with actual paths
        authorName: "Rabindranath Tagore",
        authorDescription: "A great choice for those who love poetic prose and lyrical beauty."
    },
    {
        id: 3,
        authorPicture: tolstoyPic, // Update with actual paths
        authorName: "Leo Tolstoy",
        authorDescription: "Ideal for writers looking to explore complex narratives and deep philosophical themes."
    },
    {
        id: 4,
        authorPicture: janePic, // Update with actual paths
        authorName: "Jane Austin",
        authorDescription: "Suited for those who enjoy wit, romance, and sharp social commentary."
    },
    {
        id: 5,
        authorPicture: ernestPic, // Update with actual paths
        authorName: "Ernest Hemingway",
        authorDescription: "Best for aspiring writers who appreciate minimalistic and impactful writing."
    },
    {
        id: 6,
        authorPicture: colletePic, // Update with actual paths
        authorName: "Colette",
        authorDescription: "Perfect for bold, expressive, and evocative writing."
    }
];


// Mapper dictionary , we will use this in the frontend for displaying the image ...
export const authorMapper = authors.reduce((acc, author) => {
    acc[author.authorName] = [author.authorPicture, author.authorDescription];
    return acc;
}, {});